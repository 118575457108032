import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { FreyaCommonModule } from 'src/app/freya-common/freya-common.module';
import { LibModule } from 'src/app/lib.ts/lib.module';

import { FilterRemovedStepsPipe } from 'src/app/shared/filter-removed-steps.pipe';

import { RemoveDisabledStepsPipe } from 'src/app/shared/remove-disabled-steps.pipe';
import { SharedModule } from 'src/app/shared/shared.module';

// Represents a single input element
export interface MutateObjectElement {
  // Optional Secondary Name that Will be displayed
  name?: string;
  // NgTemplate Ref for the Element, If not provided this Element is considered disabled. REQUIRED FOR mutateType 'create'.
  ref?: TemplateRef<any>;
  // Name of the form control associated with this item.
  control: string;
  // The type of the Form Input
  type: 'text' | 'array' | 'color' | 'complex' | 'currency' | 'boolean' | 'password' | 'phone' | 'title' | 'func';

  // The ref to be displayed in the review if type = 'complex', MUTUALLY EXCLUSIVE w/ reviewProperty
  reviewRef?: TemplateRef<any>;
  // The property to display if type complex, MUTUALLY EXCLUSIVE w/ reviewRef
  reviewProperty?: string;

  // Function that returns the result of the mutate object element
  reviewFunc?: (val?: any, step?: MutateObjectElement) => any;

  /**
   * Whether this step is disabled
   * It is not stepped to and is readonly
   * on the mutator
   */
  disabled?: boolean;

  /**
   * Set this to true to only show this property in review and not as an actual step
   * Good for when the default value is what is going to be used at least 90% of the time
   */
  reviewOnly?: boolean;

  // Function that performs custom validation for the step, ideally only used in complex steps.
  // All other validation should be done with validators
  // Returns True if the step is invalid
  invalidFunc?: () => boolean;

  // This step has been removed due to another steps value or a variable condition. (Not Always Present)
  removed?: boolean;

  // Do not go to next step if the user presses the enter key
  disableEnterKey?: boolean;
}

@Component({
  selector: 'app-mutate-object-v2',
  standalone: true,
  imports: [
    SharedModule,
    LibModule,
    ReactiveFormsModule,
    FreyaCommonModule,
],
  templateUrl: './mutate-object-v2.component.html',
  styleUrls: ['./mutate-object-v2.component.scss'],
  providers: [FilterRemovedStepsPipe, ConfirmationService]
})
export class MutateObjectV2Component implements OnInit, AfterViewInit {

  // Required Variables
  @Input() steps: MutateObjectElement[];
  @Input() fg: UntypedFormGroup;
  @Input() mutateType: 'create' | 'update';
  @Input() objectType: string;

  // Visual Modifiers
  @Input() currency: string; // Required if you pass a currency element
  @Input() reviewButtonText: string;
  @Input() titleText: string; // What you want it to say instead of the Default, **overrrides the defaultTitle**
  @Input() disclaimer: string; // Text that appears in the review section above the button
  // 200px from PrimeNg - https://primeng.org/listbox#api.listbox.props.scrollHeight
  @Input() scrollHeight = '200px'; // Deafult Height of the Dialog 

  // Core Variables
  @Input() showDialog = false; // Whether or not the dialog is open

  @Output() objectMutated = new EventEmitter<boolean>(); // Event that tells the parent to mutate

  @Output() changesDiscarded = new EventEmitter<boolean>(); // Event that tells the parent to discard changes

  @Output() activeStepIndexChange = new EventEmitter<{ prev: number; curr: number }>();

  // Header
  @ViewChild('defaultTitle') defaultTitleRef: TemplateRef<any>;

  // Main Body
  @ViewChild('review') reviewRef: TemplateRef<any>;
  @ViewChild('section') sectionRef: TemplateRef<any>;

  // Footer
  @ViewChild('reviewButtons') reviewButtonsRef: TemplateRef<any>;
  @ViewChild('navigationButtons') navigateButtonsRef: TemplateRef<any>;

  // Content Variables
  activeContent: TemplateRef<any>; // The Active Parent Template, Menu or Section
  sectionContent: TemplateRef<any>; // The Content in the section template
  footerContent: TemplateRef<any>; // The Content in the footer
  titleContent: TemplateRef<any>; // Content that appears in the Header

  // Variables used for Functionality
  activeStep: string = undefined; // Name of the active step
  activeStepIndex = 0; // Index of the active step
  underReview = false; // True if the component is in review mode
  onlyShowEditableItems = false; // When True any items that cannot be edited are filtered out
  sectionInvalid = false; // If the content of a section is invalid
  loading = false; // True while saving a mutate event

  removeListeners: () => void;

  // Used to set focus on first input
  inputSelectors = {
    inputText: 'input.p-inputtext',
    dropdown: 'p-dropdown',
    checkbox: 'p-checkbox',
    multiSelect: 'p-multiselect',
    chips: 'p-chips',
    colorPicker: 'p-colorpicker',
    textarea: 'textarea',
    calendar: 'p-calendar',
    autocomplete: 'p-autocomplete',
    inputNumber: 'p-inputnumber',
    inputSwitch: 'p-inputswitch'
  };

  constructor(
    private cd: ChangeDetectorRef,
    private removeDisabledStepsPipe: RemoveDisabledStepsPipe,
    private filterRemovedStepsPipe: FilterRemovedStepsPipe,
    private renderer: Renderer2,
    private confirmService: ConfirmationService,
    private viewContainerRef: ViewContainerRef
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.cd.detectChanges();

    this.titleContent = this.defaultTitleRef; // Sets the Default Title in the Header, Can be Overriden By external Component
  }

  setValues() {
    if (this.mutateType === 'create') {
      this.steps = this.removeDisabledStepsPipe.transform(this.steps);
      this.viewSectionByIndex(0);
    } else if (this.mutateType === 'update') {
      this.viewReview();
    }
  }

  addListeners() {
    this.removeListeners = this.renderer
    .listen(document, 'keyup', (event: any) => {
      switch(event.key) {
        case 'Enter':
          this.handleEnter();
          break;
        case 'Esc':
        case 'Escape':
          this.confirmClose();
          break;
        default:
          return;
      }
    });
  }

  handleEnter() {
    if (this.disableEnterKey()) { return; }

    // Check if a dropdown is focused or open
    if (this.isDropdownActive()) { return; }

    if (this.inSectionOtherThanLast()) {
      this.viewNextSection();
      return;
    };
    if (this.inLastSection()) {
      this.viewReview();
      return;
    };

    // If we have made this far
    // it means we are in the review section
    if (this.fg.valid) {
      this.mutateObject();
    };
  }

/**
 * Checks if the currently focused element is a dropdown option.
 * This is used to determine if the user selected an option using the keyboard.
 * If true, the process should not move to the next step.
 * 
 * TODO: Make this generic for all overlay panels (autocomplete, dropdown, etc.)
 * 
 * @returns {boolean} - True if the active element is a dropdown option, otherwise false.
 */
isDropdownActive(): boolean {
  const activeElement = document.activeElement;
  return activeElement && activeElement.classList.contains('p-dropdown-label');
}

  viewNextSection() {
    this.viewSectionByIndex(this.activeStepIndex + 1);
  }

  viewPreviousSection() {
    this.viewSectionByIndex(this.activeStepIndex - 1);
  }

  inLastSection() {
    return this.activeStepIndex === this.filteredSteps().length - 1;
  }

  inSectionOtherThanLast() {
    return this.activeStepIndex > -1 && this.activeStepIndex < this.filteredSteps().length - 1;
  }

  disableEnterKey() {
    return this.steps[this.activeStepIndex]?.disableEnterKey;
  }

  // Called By Parent to Get the Show on the Road
  openDialog() {
    this.setValues();
    this.addListeners();
    this.showDialog = true;
    this.setFocusOnFirstInput();
  }

  /**
   * Closes dialog if form is pristine.
   * Otherwise, opens confirm close dialog.
   */
  confirmClose() {
    if (this.fg.pristine) {
      this.closeDialog();
      return;
    };
    this.confirmService.confirm({
      message: 'By selecting "Yes", changes will be discarded. Are you sure you want to discard these changes?',
      acceptLabel: 'No',
      rejectLabel: 'Yes',
      header: 'Discard Changes?',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: 'pi pi-replay',
      rejectIcon: 'pi pi-trash',
      rejectButtonStyleClass: 'p-button-danger',
      dismissableMask: true,
      defaultFocus: 'reject',
      reject: () => this.closeDialog()
    });
  }

  closeDialog() {
    this.showDialog = false;
    this.changesDiscarded.emit(true);
    this.underReview = false;
    this.sectionInvalid = false;
    this.loading = false;
    this.removeListeners();
  }

  viewSectionByIndex(index: number) {
    const step = this.filteredSteps()[index];
    this.viewSection(step.name);
  }

  // Sets the content to be displayed based on the menu item clicked
  viewSection(name: string) {
    this.sectionContent = this.steps.find((t) => t.name === name).ref;
    if (!this.sectionContent) { return; }

    this.activeStep = name;
    const newIndex = this.filteredSteps().findIndex((t) => t.name === name);
    this.setActiveStepIndex(newIndex);

    this.activeContent = this.sectionRef;
    if (!this.underReview && this.mutateType === 'create') {
      this.footerContent = this.navigateButtonsRef;
    } else {
      this.footerContent = this.reviewButtonsRef;
    }

    this.setFocusOnFirstInput();
  }

  // "Removes" existing steps from the list without deleteing them from the list
  removeStep(name: string, controlValue = undefined) {
    const step = this.steps.find((s) => s.name === name);
    if (controlValue) {
      this.fg.controls[step.control].setValue(controlValue);
    }

    this.fg.controls[step.control].setErrors(null);
    this.fg.updateValueAndValidity();

    step.removed = true;

    this.steps = [...this.steps];

    this.recalculateActiveStep();
  }

  // Can add steps marked as removed, can NOT add new steps into the field
  addStep(name: string, resetValue = false) {
    const step = this.steps.find((s) => s.name === name);

    if (resetValue) {
      this.fg.controls[step.control].setValue(undefined);
    }

    step.removed = false;

    this.steps = [...this.steps];

    this.recalculateActiveStep();
  }

  // After Changing the steps, reclaculate the active step to make it match
  recalculateActiveStep(){
    const newIndex = this.filterRemovedStepsPipe
    .transform(this.removeDisabledStepsPipe.transform(this.steps)).findIndex((t) => t.name === this.activeStep);
    this.setActiveStepIndex(newIndex);
  }

  // The Steps after filtering out removed and disabled steps
  filteredSteps(): MutateObjectElement[]{
    return this.filterRemovedStepsPipe.transform(
      this.removeDisabledStepsPipe.transform(this.steps)
    );
  }

  // Switches the active content to the review panel
  viewReview() {
    this.activeContent = this.reviewRef;
    this.footerContent = this.reviewButtonsRef;
    this.underReview = true;
    this.setActiveStepIndex(-1);
  }
  mutateObject() {
    this.loading = true;
    this.objectMutated.emit(true);
  }

  toggleBooleanField(event, step) {
    this.fg.controls[step.control].setValue(event.checked);
  }

  /**
   * Applies focus to the first input (or input-like element) in a mutate dialog's active step.
   */
  setFocusOnFirstInput() {
    this.cd.detectChanges();

    const parentElement: Element = this.viewContainerRef.element.nativeElement;
    const firstInput = parentElement.querySelector(`:is(${Object.values(this.inputSelectors).join(', ')})`);

    if (!firstInput) { return; };

    this.applyFocus(firstInput);
  }

  /**
   * Applies focus to the rendered HTML of a primeNG input-like component,
   * including all component types in the `Focusable` union type
   * as well as `InputText` and `InputTextarea`.
   */
  applyFocus(input: ElementRef['nativeElement']) {

    if (input.hasAttribute('pinputtext') || input.hasAttribute('pinputtextarea')) {
      input.focus();
      return;
    }

    switch(input.tagName) {
      case 'P-AUTOCOMPLETE':

        const inputButtons = input.getElementsByTagName('button');

        if (inputButtons.length > 0) {
          inputButtons[0].click();
          return;
        }

        const innerAutocompleteInputs = input.getElementsByTagName('input');

        if (!innerAutocompleteInputs.length) { return; }

        innerAutocompleteInputs[0].focus();

        return;
      case 'P-MULTISELECT':
        const trigger = input.querySelector('.p-multiselect-trigger');
        if (!trigger) { return; }
        setTimeout(()=>{
          trigger.click();
        },150);
        return;
      case 'P-COLORPICKER':
        const clickableInputs = input.getElementsByTagName('input');
        if (!clickableInputs.length) { return; };
        clickableInputs[0].click();
        return;
      default:
        const innerInputs = input.getElementsByTagName('input');
        if (!innerInputs.length) { return; };
        innerInputs[0].focus();
    }
  }

  setActiveStepIndex(index: number) {

    const change = {
      prev: this.activeStepIndex,
      curr: index,
    };

    this.activeStepIndex = index;

    this.activeStepIndexChange.emit(change);
  }

}
