import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { capitalize, cloneDeep } from 'lodash';
import { MenuItem } from 'primeng/api';

import { EventTypeInfo, eventTypeInfoMap } from '../../global.constants';
import { EstimateHelperService } from '../../services/estimate-helper.service';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { FreyaCommonModule } from 'src/app/freya-common/freya-common.module';
import { SubSink } from 'subsink';
import { Store } from '@ngrx/store';
import { workOrdersSelectors } from '../job-state/workorders-state/workorders.selectors';
import { jobToolFeature } from '../job-tool.reducer';
import { EstimatesJobFragment } from 'graphql.generated';
import { WorkOrdersActions } from '../job-state/workorders-state/workorders.actions';

@Component({
    selector: 'app-add-event-button-v2',
    standalone: true,
    imports: [
        SharedModule,
        FreyaCommonModule,
    ],
    templateUrl: './add-event-button-v2.component.html',
    styleUrls: ['./add-event-button-v2.component.scss']
})
export class AddEventButtonV2Component implements OnInit, OnDestroy {

    addableEventTypes: MenuItem[] = [];
    job: EstimatesJobFragment;

    constructor(
        private store: Store,
        private localNotify: FreyaNotificationsService,
    ) { }

    private subs = new SubSink();

    job$ = this.store.select(workOrdersSelectors.selectJobWithPendingChargesUpdates);
    jobLoading$ = this.store.select(jobToolFeature.isJobLoading);

    ngOnInit(): void {
        this.subs.sink = this.job$.subscribe((job) => {
            if (job) {
                this.job = cloneDeep(job);
                this.setAddableEventTypes();
            }
        });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    /**
     * Set which events can be added
     */
    setAddableEventTypes() {
        this.addableEventTypes = [];
        for (const [key, value] of Object.entries(eventTypeInfoMap)) {
            const eventWithSequentialOrder = {
                ...value,
                sequentialOrder: (this.job?.events?.length + 1) || 1,
            };
            this.addableEventTypes.push({
                label: capitalize(eventWithSequentialOrder.value),
                command: () => {
                    const eventInput = {
                        jobId: this.job?.id,
                        sequentialOrder: eventWithSequentialOrder?.sequentialOrder,
                        status: 'required',
                        title: eventWithSequentialOrder?.name,
                        type: eventWithSequentialOrder?.value,
                    }
                    this.store.dispatch(WorkOrdersActions.createEvent({ eventInput } ));

                }
            } as MenuItem);
        }
    }

}
