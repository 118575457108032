import { createFeature, createReducer, on } from '@ngrx/store';
import { BaseZoneFragment } from 'graphql.generated';
import { ZonesActions } from './zones.actions';

export interface ZonesState {
	currentAppZone: BaseZoneFragment | undefined;
};

export const zonesInitialState = {
	currentAppZone: undefined,
};

export const zonesFeature = createFeature({
	name: 'zones',
	reducer: createReducer(
		zonesInitialState,
		on(ZonesActions.zoneChanged, (state, res): ZonesState => {
			return {
				...state,
				currentAppZone: res.currentAppZone,
			}
		}),
	)
});
