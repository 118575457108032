import { Pipe, PipeTransform } from '@angular/core';
import { User } from '@karve.it/interfaces/auth';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  transform(user: User, showCountry: boolean): string {
    if (!user || !user.phone) { return 'No Phone'; }

    const tel = user.phone;

    const value = tel.toString().trim().replace(/^\+/, '');

    if (value.match(/[^0-9]/)) {
      return tel;
    }

    let country; let city; let base;

    switch (value.length) {
      case 10:
        country = 1;
        city = value.slice(0, 3);
        base = value.slice(3);
        break;

      case 11:
        country = value[0];
        city = value.slice(1, 4);
        base = value.slice(4);
        break;

      case 12:
        country = value.slice(0, 3);
        city = value.slice(3, 5);
        base = value.slice(5);
        break;

      default:
        return tel;
    }

    if (!showCountry && country === 1) {
      country = '';
    }

    base = `${base.slice(0, 3)}-${base.slice(3)}`;

    return `${country ? '+' + country : country} ${city} ${base}`.trim();
  }

}
