<!-- PRODUCTS -->
<app-mutate-product #mutateProduct></app-mutate-product>

<app-mutate-price #mutatePrice></app-mutate-price>

<!-- EVENTS -->
<app-mutate-calendar-event #mutateCalendarEvent></app-mutate-calendar-event>

<!-- CHARGES/DAMAGES -->
<app-mutate-charges #mutateCharges></app-mutate-charges>

<!-- TRANSACTIONS -->
<app-mutate-transaction #mutateTransaction></app-mutate-transaction>

<!-- USER -->
<app-mutate-user #mutateUser></app-mutate-user>
<!-- COMMENTS -->
<app-mutate-comment #mutateComment></app-mutate-comment>
<!-- JOB -->
<app-mutate-job #mutateJob></app-mutate-job>

<app-mutate-area #mutateArea></app-mutate-area>
<!-- AVAILABILTY -->
<app-mutate-availability-template
  #mutateAvailabilityTemplate
></app-mutate-availability-template>

<app-mutate-report #mutateReport></app-mutate-report>
<app-mutate-scheduled-report
  #mutateScheduledReport
></app-mutate-scheduled-report>

<app-mutate-filter #mutateFilter></app-mutate-filter>

<app-mutate-dynamic-report #mutateDynamicReport></app-mutate-dynamic-report>

<app-mutate-business-unit #mutateBusinessUnit></app-mutate-business-unit>

<app-mutate-rolling-lock-date
  #mutateRollingLockDate
></app-mutate-rolling-lock-date>

<app-mutate-invoice #mutateInvoice></app-mutate-invoice>

<app-mutate-ical-token mutateType="create" #mutateIcal></app-mutate-ical-token>

<app-mutate-book-off></app-mutate-book-off>

<p-dialog
  [modal]="true"
  class="wide-dialog"
  [closable]="false"
  [(visible)]="deleteDialogVisible"
>
  <ng-template pTemplate="header">
    <h4 *ngIf="deleteInput.customTitle; else defaultTitle">
      {{ deleteInput.customTitle }}
    </h4>
    <ng-template #defaultTitle>
      <h4>
        {{
          deleteInput?.showInsteadOfDelete
            ? deleteInput?.showInsteadOfDelete
            : 'Delete'
        }}
        {{ deleteInput?.objectType | prettyProperty }}
      </h4>
    </ng-template>
  </ng-template>
  <p style="max-width: 40ch">
    Are you sure you want to
    {{
      deleteInput?.showInsteadOfDelete
        ? deleteInput?.showInsteadOfDelete
        : 'delete'
    }}
    <strong>{{ deleteInput?.objectName }}</strong
    >?
  </p>
  <p-footer>
    <button
      autofocus
      pButton
      (click)="onDeleteCancelled()"
      icon="pi pi-times"
      label="{{
        deleteInput?.showInsteadOfCancel
          ? deleteInput?.showInsteadOfCancel
          : 'Cancel'
      }}"
    ></button>
    <button
      pButton
      (click)="deleteObject()"
      [disabled]="deletingObject"
      icon="pi pi-trash"
      label="{{
        deleteInput?.showInsteadOfDelete
          ? deleteInput?.showInsteadOfDelete
          : 'Delete'
      }}"
      class="p-button-danger"
    ></button>
  </p-footer>
</p-dialog>
