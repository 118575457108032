import { AsyncPipe, CommonModule, TitleCasePipe } from '@angular/common';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Menu, MenuModule } from 'primeng/menu';

import { map, switchMap, take, tap, withLatestFrom } from 'rxjs';
import { SubSink } from 'subsink';

import { UpdateJobInput } from '../../../../generated/graphql.generated';
import { JOB_STAGES } from '../../../global.constants';
import { strToTitleCase } from '../../../js';
import { FreyaHelperService } from '../../../services/freya-helper.service';
import { brandingFeature } from '../../../state/branding.store';
import { JobToolActions } from '../../job-tool.actions';
import { FullJobFragmentWithFields, jobToolFeature } from '../../job-tool.reducer';

import { CloseJobV2Component } from '../../jobv2-create/close-job-componentV2/close-job-componentV2.component';

@Component({
  selector: 'app-job-status-dropdown',
  standalone: true,
  imports: [MenuModule, TitleCasePipe, AsyncPipe, CommonModule],
  templateUrl: './job-status-dropdown.component.html',
  styleUrl: './job-status-dropdown.component.scss',
})
export class JobStatusDropdownComponent implements OnInit {
  @ViewChild('menu') jobStageMenu: Menu;

  private wasClickedInside = false;
  @HostListener('click')
  clickInside() {
    this.wasClickedInside = true;
  }

  @HostListener('document:click', ['$event.target'])
  clickedOut(targetElement: HTMLElement) {
    if (this.isJobStatusMenuVisible) {
      // Only run when the menu is visible
      const menuElement = document.querySelector('.p-menu-overlay');

      const isInsideMenu = menuElement?.contains(targetElement);
      if (!this.wasClickedInside && !isInsideMenu) {
        this.isJobStatusMenuVisible = false;
      }
    }
    this.wasClickedInside = false;
  }

  subs = new SubSink();

  public job$ = this.store.select(jobToolFeature.selectJob);
  public isJobStatusMenuVisible = false;
  public jobStatusItems: MenuItem[];

  closedReason$ = this.job$.pipe(
    // eslint-disable-next-line @ngrx/avoid-mapping-selectors
    withLatestFrom(this.store.select(brandingFeature.selectConfigs).pipe(map((configs) => configs['jobs.closedReasons']))),
    map(([ job, closedReasons ]) => {
      if (!job || !job.closedAt) { return; }
      const closedReason = closedReasons.find((cr) => cr.id === job.closedReason);
      return closedReason?.title;
    }),
  );

  constructor(
    public store: Store,
    private dialogService: DialogService,
    private freyaHelper: FreyaHelperService,
  ) {}

  ngOnInit(): void {
    this.subs.sink = this.job$.subscribe((job) => {

      this.jobStatusItems = [
        job.stage === 'lead' && {
          label: 'Review Lead',
          icon: 'pi pi-eye',
          routerLink: ['/jobs', job.id, 'edit'],
        },
        this.getMoveAction(job),
        this.getSetOpenCloseAction(job),
      ].filter(Boolean);
    });
  }

  getMoveAction(job: FullJobFragmentWithFields) {

    if (job.closedAt) { return; }

    const currentStage = JOB_STAGES[job.stage];

    if (!currentStage) { return; }

    const nextStage = Object.values(JOB_STAGES).find((s) => s.order === currentStage.order + 1);

    if (!nextStage) { return; }

    return {
      label: `Move to ${strToTitleCase(nextStage.name)}`,
      icon: 'pi pi-receipt',
      command: () => this.moveToEstimate(nextStage.name),
    };
  }

  getSetOpenCloseAction(job: FullJobFragmentWithFields) {

    const isClosed = Boolean(job.closedAt);

    if (isClosed) {
      return {
        label: 'Reopen Job',
        icon: 'pi pi-folder',
        command: () => this.store.dispatch(JobToolActions.reopenExistingJob({ jobId: job.id })),
      }
    }

    const { disableClose, reason } = this.freyaHelper.getDisableClose(job);

    return {
      label: 'Close Job',
      icon: 'pi pi-folder',
      disabled: disableClose,
      tooltip: reason,
      command: () => this.dialogService.open(CloseJobV2Component, {
        header: 'Close job',
        width: '42rem',
      }),
    };
  }

  public toggleMenu($event: Event): void {
    this.isJobStatusMenuVisible = !this.isJobStatusMenuVisible;
    this.jobStageMenu.toggle($event);
  }

  public moveToEstimate(stage: string) {

    const updateSuccess$ = this.store.select(jobToolFeature.updateJobLoaded);

    this.job$.pipe(
      take(1),
      // Save any changes before moving to estimate
      tap(() => this.store.dispatch(JobToolActions.jobUpdateRequested())),
      switchMap((job) => {

        const updateJobInput: UpdateJobInput = {
          jobId: job.id,
          stage,
        }

        // If previous update was successful, move to estimate
        return updateSuccess$.pipe(tap(() => this.store.dispatch(JobToolActions.updateJobStage({ updateJobInput }))));
      }),
    ).subscribe();
  }
}
