<button
  (click)="store.dispatch(QuickAccessActions.toogleQuickAccess())"
  class="expand-toggle quick-access-btn"
>
  <span>
    <i class="pi pi-star"></i>
    Quick Access
  </span>
  <i
    [ngClass]="
      (expanded$ | async) ? 'pi pi-chevron-up' : 'pi pi-chevron-down'
    "
  ></i>
</button>

<ng-template #jobSectionTemplate let-jobs="jobs" let-title="title" let-icon="icon">
  <section *ngIf="(jobs | async)?.length">
    <h4>{{ title }}&nbsp;</h4>
    <a
      class="access-item"
      pContextMenu="contextMenu"
      [contextMenu]="menuItems"
      *ngFor="let job of jobs | async"
      [routerLink]="['/jobs', job.id, 'overview']"
      [queryParams]="{ zone: job.contextZone?.id }"
    >
      <i ngClass="pi {{ icon }}"></i>
      <div class="access-info">
        <div>
          <span class="name">{{ job?.users | jobCustomer }}</span>
          <i *ngIf="(jobsWithUnsavedChanges$ | async)?.includes(job.id)"
            class="pi pi-circle-on unsaved-icon"
            pTooltip="Unsaved Changes"
            ></i>
        </div>
        <span class="details">{{ job.stage | titlecase }} | {{ job.code }}</span>
      </div>
    </a>
  </section>
</ng-template>

<div *ngIf="expanded$ | async" class="quick-access-content">
  <h6 class="no-jobs"
    *ngIf="!((pinnedJobs$ | async).length + (recentJobs$ | async).length + (todaysJobs$ | async).length)"
  >
    No recent jobs.<br />
    Open a job to get started!
  </h6>

  <ng-container *ngTemplateOutlet="jobSectionTemplate; context: {
    jobs: pinnedJobs$,
    title: 'Pinned',
    icon: 'pi-bookmark'
  }"></ng-container>

  <ng-container *ngTemplateOutlet="jobSectionTemplate; context: {
    jobs: recentJobs$,
    title: 'Recents',
    icon: 'pi-clock'
  }"></ng-container>

  <ng-container *ngTemplateOutlet="jobSectionTemplate; context: {
    jobs: todaysJobs$,
    title: 'Today',
    icon: 'pi-calendar'
  }"></ng-container>
</div>

<!-- PrimeNG ContextMenu -->
<!-- <p-contextMenu #contextMenu [model]="menuItems"></p-contextMenu> -->
