import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export type MutateType = 'create' | 'update';
export const mutateObjectTypes = [
  'product',
  'price',
  'calendarEvent',
  'charge',
  'area',
  'transaction',
  'comment',
  'user',
  'job',
  'availabilityTemplate',
  'report',
  'ScheduledReport',
  'filter',
  'asset',
  'businessUnit',
  'rollingLockDate',
  'invoice',
  'bookOff',
  'artifact',
  'ical',
  'dynamicReport',
] as const;

export type ObjectType = typeof mutateObjectTypes[number];

export interface MutateInput{
  mutateType: MutateType;
  object?: any;
  objectType: ObjectType;
  additionalValues?: AdditionalMutateValue[]; // Properties on the mutate component you want to set values for
  openToStep?: string; // The name of the step you want to open to
  removeSteps?: string[]; // The name of the steps you want to remove
}

export interface AdditionalMutateValue{
  property: string;
  value: any;
}

export interface MutateObjectOutput{
  object: any;
  objectType: ObjectType;
  // mutateType: 'create' | 'update';
}

export interface DeleteInput {
  objectName: string;
  objectType: ObjectType;
  objectId: string;
  showInsteadOfType?: string; // If we want to call the type something else eg. 'Note' instead of 'Comment'
  showInsteadOfCancel?: string; // If you want to give Cancel another name eg. 'Abort' or 'Don't Cancel'
  showInsteadOfDelete?: string; // If you want to give delete another name. eg. 'Cancel', 'Remove' etc.
  customTitle?: string; // Custom title for the delete dialog
  afterDelete?(): void; // function to run after delete
  afterCancel?(): void;
}

export interface DeleteObjectOutput {
  objectName: string;
  objectType: ObjectType;
  objectId: string;
}

@Injectable({
  providedIn: 'root'
})
export class FreyaMutateService {

  mutateInput: Subject<MutateInput> = new Subject();
  deleteInput: Subject<DeleteInput> = new Subject();

  objectMutated: Subject<MutateObjectOutput> = new Subject();
  objectDeleted: Subject<DeleteObjectOutput> = new Subject();

  destroyContainer: Subject<boolean> = new Subject();

  constructor() { }

  // Wrapper for the mutateInput subject
  openMutateObject(input: MutateInput){
    this.mutateInput.next(input);
  }

  // Wrapper for the deleteInput subject
  openDeleteObject(input: DeleteInput){
    this.deleteInput.next(input);
  }
}
