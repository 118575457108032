import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { QuickAccessJob } from './quick-access.util';

export const QuickAccessActions = createActionGroup({
	source: 'Quick Access',
	events: {
		'Initialized': emptyProps(),
		'Loaded': props<{
			pinnedJobs: QuickAccessJob[];
			recentJobs: QuickAccessJob[];
			todaysJobs: QuickAccessJob[];
			jobsWithUnsavedChanges: string[];
		}>(),
		'Job Clicked ': props<{
			job: QuickAccessJob;
			method: 'details' | 'current tab' | 'new tab',
		}>(),

		'Job Pinned ': props<{
			job: QuickAccessJob;
		}>(),

		'Toogle Quick Access ': emptyProps(),
	},
});
