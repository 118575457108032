<div class="leave-jobsv2-dialog">
    <div> <i class="pi pi-exclamation-triangle"></i></div>
    <h4>You have unsaved changes!</h4>
    <p>Save or discard your changes, or continue and come back to them later.</p>
    <div class="buttons-row">
        <button
            pButton
            class="p-button-text p-m-1 discard-btn"
            label="Discard changes"
            icon="pi pi-trash"
            (click)="discardChanges()">
        </button>
        <button
            pButton
            class="p-m-1"
            label="Save changes"
            icon="pi pi-check"
            (click)="saveChanges()"
        ></button>
    </div>
    <button
        pButton
        class="p-button-text p-m-1 leave-btn-text"
        icon="pi pi-sign-out"
        label="Leave and save later"
        (click)="leaveAndSaveLater()">
    </button>
</div>