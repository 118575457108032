<div class="page-header-container">
    <div>
        <div class="header-container">
            <p-breadcrumb
                [home]="home"
                [model]="breadcrumb"
                styleClass="p-mb-3"
            >
            </p-breadcrumb>
        </div>
        <div class="buttons-container">
            <app-form-action-button
                *ngIf="!editMode && !responsiveHelper.isSmallScreen"
                action="create-close"
            ></app-form-action-button>
            <app-form-action-button
                *ngIf="!editMode"
                action="create"
            ></app-form-action-button>
            <p-button
                *ngIf="editMode"
                [outlined]="true"
                [style]="{color: 'var(--danger)'}"
                (click)="cancelUpdating()"
                label="Cancel"
                [icon]="'pi pi-times'"
                [disabled]="jobUpdating"
            ></p-button>
            <app-form-action-button
                *ngIf="editMode"
                action="update"
            ></app-form-action-button>
        </div>
    </div>
    <div
        class="errors-container"
        *ngIf="errorMessages.length > 1"
    >
        <div
            class="errors-container"
            *ngFor="let errorMessage of errorMessages"
        >
            <p-messages severity="error">
                <ng-template pTemplate>
                    <div class="error-message">
                        <i class="pi pi-exclamation-triangle"></i>
                        <p>{{errorMessage}}</p>
                    </div>
                </ng-template>
            </p-messages>
        </div>
    </div>
    <div
        class="errors-container"
        *ngIf="errorUpdateMessages.length > 1"
    >
        <div
            class="errors-container"
            *ngFor="let errorMessage of errorUpdateMessages"
        >
            <p-messages severity="error">
                <ng-template pTemplate>
                    <div class="error-message">
                        <i class="pi pi-exclamation-triangle"></i>
                        <p>{{errorMessage}}</p>
                    </div>
                </ng-template>
            </p-messages>
        </div>
    </div>
</div>
