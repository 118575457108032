import { inject } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { CreateLocationsGQL, ResolveServiceAreaGQL, UpdateJobGQL } from "graphql.generated";
import { map, exhaustMap, catchError, of, withLatestFrom, filter, switchMap, tap, from } from 'rxjs';
import { FreyaHelperService } from "src/app/services/freya-helper.service";

import { PlusAuthenticationService } from "../../../core/public-api";
import { FreyaNotificationsService } from "../../../services/freya-notifications.service";
import { JobToolActions } from "../../job-tool.actions";
import { jobToolFeature } from "../../job-tool.reducer";

import { JobCreateLocationsActions } from "./jobv2-create-locations-state.actions";

export const serviceAreaResolved = createEffect((
	actions$ = inject(Actions),
	resolveServiceAreaGQL = inject(ResolveServiceAreaGQL),
	store = inject(Store),
) => {
	return actions$.pipe(
		ofType(JobCreateLocationsActions.locationResolveServiceArea),
		withLatestFrom(
			store.select(jobToolFeature.selectJobFormMode),
		),
		exhaustMap(([action, mode]) => {
			return resolveServiceAreaGQL.fetch({
				areaCode: action.areaCode,
				query: action.query,
			}).pipe(
				map((result) => {
					return JobCreateLocationsActions.locationResolveServiceAreaSuccess({
						resolveServiceArea: result?.data?.resolveServiceArea
					});
				}),
				catchError((error) => of(JobCreateLocationsActions.locationResolveServiceAreaError({
					error
				})))
			)
		}),
	);
}, { functional: true, dispatch: true });

export const locationsCreated = createEffect(() => {
	const actions$ = inject(Actions);
	const store = inject(Store);
	const createLocationsGQL = inject(CreateLocationsGQL);

	return actions$.pipe(
		ofType(
			JobCreateLocationsActions.locationSetAutocomplete,
			JobCreateLocationsActions.locationSetManually,
		),
		withLatestFrom(
			store.select(jobToolFeature.selectCustomerInput),
			store.select(jobToolFeature.selectJobFormMode),
		),
		exhaustMap(([action, customer, mode]) => {
			const input = {
				locations: [action.location],
				...(customer && customer.id ? { owner: customer.id } : {})
			};

			return createLocationsGQL.mutate({ input }).pipe(
				map(result => {

					return JobCreateLocationsActions.locationsCreateSuccess({
						location: result.data.createLocations.locations[0],
						locationType: action.locationType
					});
				}),
				catchError(error =>
					of(JobCreateLocationsActions.locationsCreateError({
						error,
						locationType: action.locationType
					}))
				)
			);
		})
	);
}, { functional: true, dispatch: true });

export const loadDockLocations = createEffect(() => {
    const actions$ = inject(Actions);
    const freyaHelper = inject(FreyaHelperService);
    const store = inject(Store);

    return actions$.pipe(
        ofType(JobCreateLocationsActions.loadDockLocation),
        withLatestFrom(store.select(jobToolFeature.selectJobFormMode)),
        exhaustMap(async ([action, mode]) => {
            try {
                const dock = await freyaHelper.getDockLocation(true);
                return JobCreateLocationsActions.loadDockLocationSuccess({
					locationType: 'dock',
					location: dock
				});
            } catch (error) {
                return JobCreateLocationsActions.loadDockLocationError({ error, locationType: 'dock' });
            }
        })
    );
}, { functional: true, dispatch: true });


export const changeAreaOnLocationSelectAreaManually = createEffect(() => {
	const actions$ = inject(Actions);
	const store = inject(Store);
	const updateJobGQL = inject(UpdateJobGQL);
	const notify = inject(FreyaNotificationsService);

	return actions$.pipe(
		ofType(JobCreateLocationsActions.locationSelectAreaManually),
		filter((action) => action.requestUpdate),
		withLatestFrom(store.select(jobToolFeature.selectJob)),
		switchMap(([ { zone }, { id: jobId } ]) => {

			if (zone?.type === 'area') {
				zone = zone.parent;
			}

			return updateJobGQL.mutate({
				updateJobs: {
					jobId,
					setZone: zone.id,
				},
			}).pipe(
				map((res) => {

					const { isSuccess } = res.data.updateJobs;

					if (isSuccess) {
						notify.success('Job area changed successfully');
						return JobToolActions.updateJobAreaSuccess({ isSuccess, jobId });
					}

					notify.error('Error changing area');
					return JobToolActions.updateJobAreaError({ error: new Error('Failed to change area') });
				}),
				catchError((error) => {
					notify.apolloError('Error changing area', error);
					console.error('Error changing area:', error);
					return of(JobToolActions.updateJobAreaError({ error }));
				}),
			);
		}),
	)
}, { functional: true, dispatch: true });

