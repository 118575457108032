<div *ngFor="let location of locationsToShow">
	<div class="location-container">
		<b>{{location.locationType | titlecase}}</b>
		<br />
		<div *ngIf="location.location; else noLocationSet">
			<a
				[class.disabled]="!(job | jobLocation : location.locationType)"
				target="_blank"
				[href]="viewLocationOnGoogleMaps(location.location)"
			>
			{{ (location.location | location)?.text }}
			</a>
		</div>
	</div>
</div>

<div *ngIf="showRoute">
	<a
		pButton
		target="_blank"
		[href]="showGoogleMapsRoute()"
		label="View Route"
	></a>
</div>

<ng-template #noLocationSet>No Location Set</ng-template>
