<table>
  <colgroup>
    <col style="width: 40%;" />
    <col style="width: 30%;" />
    <col style="width: 30%;" />
  </colgroup>
  <tr>
    <th></th>
    <th>Event</th>
    <th>Job</th>
  </tr>
  <tr>
    <td>Charges</td>
    <td>{{ event.subTotal / 100 | currency : job?.currency || 'USD' }}</td>
    <td>{{ job.subTotal / 100 | currency : job?.currency || 'USD' }}</td>
  </tr>
  <tr>
    <td>Discounts</td>
    <td>{{ event.discountTotal / 100 | currency : job?.currency || 'USD' }}</td>
    <td>{{ job.discountTotal / 100 | currency : job?.currency || 'USD' }}</td>
  </tr>
  <tr class="total-row">
    <td>Total</td>
    <td>{{ event.discountedSubTotal / 100 | currency : job?.currency || 'USD' }}</td>
    <td>{{ job.discountedSubTotal / 100 | currency : job?.currency || 'USD' }}</td>
  </tr>
  <tr class="toggle-row" (click)="showConfidentialInfo = !showConfidentialInfo">
    <td colspan="3">
      {{ showConfidentialInfo ? 'Hide' : 'Show' }} office information
      <span>
        <i [class]="showConfidentialInfo ? 'pi pi-chevron-up' : 'pi pi-chevron-down'"></i>
      </span>
    </td>
  </tr>

  <ng-container *ngIf="showConfidentialInfo">
    <tr>
      <td>Taxes</td>
      <td>{{ event.taxTotal / 100 | currency : job?.currency || 'USD' }}</td>
      <td>{{ job.taxTotal / 100 | currency : job?.currency || 'USD' }}</td>
    </tr>
    <tr>
      <td>Damages</td>
      <td>{{ event.damageTotal / 100 | currency : job?.currency || 'USD' }}</td>
      <td>{{ job.damageTotal / 100 | currency : job?.currency || 'USD' }}</td>
    </tr>
    <ng-container *hasPermission="['expenses.view']">
      <ng-container *ngIf="job?.total !== 0">
        <tr pTooltip="The expenses directly associated with this job" tooltipPosition="left">
          <td>Cost</td>
          <td>{{ event?.expenseTotal | freyaCurrency : event?.currency }}</td>
          <td>{{ job?.expenseTotal | freyaCurrency : job?.currency }}</td>
        </tr>
        <tr pTooltip="Gross Margin is your sub total minus your costs of goods sold (expenses)" tooltipPosition="left">
          <td>Gross Margin</td>
          <td>{{ (event?.discountedSubTotal - event?.expenseTotal) || 0 | freyaCurrency : event?.currency }}</td>
          <td>{{ (job?.discountedSubTotal - job?.expenseTotal) || 0 | freyaCurrency : job?.currency }}</td>
        </tr>
        <tr
          pTooltip="Gross profit margin is the difference between revenue and cost of goods sold, divided by revenue."
          tooltipPosition="left">
          <td>GPM</td>
          <td>{{ getJobGrossProfitMargin(event) | number: '1.2-2' | formatAmount: 'percentage' }}</td>
          <td>{{ getJobGrossProfitMargin(job) | number: '1.2-2' | formatAmount: 'percentage' }}</td>
        </tr>
      </ng-container>
    </ng-container>
  </ng-container>
</table>
