import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ZoneService } from '@karve.it/core';
import { AssetService } from '@karve.it/features';
import { Asset, ListAssetsOutput } from '@karve.it/interfaces/assets';
import { stringifyLocation } from '@karve.it/interfaces/locations';
import { Zone } from '@karve.it/interfaces/zones';
import { Store } from '@ngrx/store';
import { QueryRef } from 'apollo-angular';

import { clone } from 'lodash';
import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { withLatestFrom } from 'rxjs';
import { OBJECT_ICON_MAP } from 'src/app/global.constants';
import { PermissionService } from 'src/app/services/permission.service';
import { RecentItemsService } from 'src/app/services/recent-items.service';
import { MutateAssetComponent } from 'src/app/shared/mutate-asset/mutate-asset.component';
import { DisabledWhen, parseMenuItemCategoriesVisible, setMenuItemDisabled } from 'src/app/utilities/menu-item.util';
import { SubSink } from 'subsink';

import { CalendarEvent_LocationsWithServiceAreaFragment, DeleteAssetsGQL, UpcomingEventsGQL, UpcomingEventsQueryVariables } from '../../../generated/graphql.generated';


import { ValidationItem } from '../../app.component';
import { BOOK_OFF_EVENT_TYPE } from '../../global.constants';
import { HistoryService } from '../../history/history.service';
import { DetailsHelperService } from '../../services/details-helper.service';
import { FreyaHelperService } from '../../services/freya-helper.service';
import { FreyaMutateService } from '../../services/freya-mutate.service';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';
import { YemboHelperService } from '../../services/yembo-helper.service';
import { ApplyTemplateComponent } from '../../shared/apply-template/apply-template.component';
import { assetTypesDropdown } from '../../shared/assets/assets';
import { BookOffComponent } from '../../shared/book-off/book-off.component';
import { genFeatureSelector } from '../../state/featureFlags.store';
import { KarveMenuItem } from '../../utilities/menu-item.util';
import { WatchQueryHelper } from '../../utilities/watchQueryHelper';

export interface EditAssetValidation {
  name: ValidationItem;
}

@Component({
  selector: 'app-asset-details',
  templateUrl: './asset-details.component.html',
  styleUrls: ['./asset-details.component.scss']
})
export class AssetDetailsComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild('mutate') mutateRef: MutateAssetComponent;
  @ViewChild('applyTemplate') applyTemplateComponent: ApplyTemplateComponent;

  @Input() asset: Asset;

  assetIcon = `${OBJECT_ICON_MAP.asset} large-icon`;

  updateAssetAction = {
    id: 'edit',
    label: 'Edit',
    icon: 'pi pi-pencil',
    disabled: false,
    visible: false,
    disabledWhen: {
      objectDeleted: true,
    },
    command: () => {
      this.openEditAssetDialog();
    },
  };

  deleteAssetAction: KarveMenuItem = {
    id: 'delete',
    label: 'Delete',
    icon: 'pi pi-trash',
    disabled: false,
    visible: false,
    disabledWhen: {
      objectDeleted: true,
    },
    command: () => {
      this.freyaMutate.openDeleteObject({
        objectId: this.asset.id,
        objectName: this.asset.name,
        objectType: 'asset',
      });
    },
  };

  undeleteAssetAction = {
    id: 'undelete',
    label: 'Restore',
    icon: 'pi pi-undo',
    disabled: false,
    visible: false,
    command: () => {
      this.confirmationService.confirm({
        header: `Restore Asset?`,
        message: `Are you sure you want to restore: ${this.asset.name}`,
        acceptLabel: 'Restore Asset',
        acceptIcon: 'pi pi-undo',
        rejectLabel: `Don't Restore`,
        rejectIcon: 'pi pi-times',
        accept: () => {
          this.subs.sink = this.deleteAssetsGQL.mutate({ids: [this.asset.id], restore: true }).subscribe((res) => {
            this.localNotify.success(`Asset restored`);
            this.detailsHelper.pushUpdate({
              action: 'update',
              id: this.asset.id,
              type: 'Assets'
            });
          }, (err) => {
            this.localNotify.apolloError(`Failed to restore asset`, err);
            console.log(err);
          });
        }
      });
    },
  };

  bookOffAction = {
    id: 'bookOff',
    label: 'Book Off',
    icon: 'pi pi-calendar-times',
    disabledWhen: {
      objectDeleted: true,
      inRootOrCorporateZone: true,
    },
    visible: true,
    disabled: false,
    command: () => {
      this.freyaMutate.openMutateObject({
        mutateType: 'create',
        objectType: 'bookOff',
        additionalValues: [{
          property: 'assetId',
          value: this.asset.id,
        }],
      });
    }
  };

  quickBookOff = {
    id: 'sameDayBookOff',
    label: 'Book Off (Quick)',
    icon: 'pi pi-calendar-times',
    disabledWhen: this.bookOffAction.disabledWhen,
    visible: true,
    disabled: false,
    command: () => {
      this.dialogService.open(BookOffComponent, {
        header: `Book off ${this.asset.name}?`,
        contentStyle: this.freyaHelper.getDialogContentStyle('0.5rem'),
        data: {
          input: {
            asset: this.asset,
          },
        },
      });
    },
  };

  applyTemplateAction: KarveMenuItem = {
    id: 'applyTemplate',
    label: 'Apply Template',
    icon: 'pi pi-arrow-right',
    disabledWhen: {
      objectDeleted: true,
      inRootOrCorporateZone: true,
    },
    command: () => {
      this.applyTemplateComponent.preselectedAssets = [this.asset];
      this.applyTemplateComponent.openDialog();
    },
    disabled: true,
  };

  exportToCalendar: KarveMenuItem = {
    id: 'exportToCalendar',
    label: 'Export to Calendar',
    icon: 'pi pi-calendar-plus',
    disabledWhen: {
      objectDeleted: true,
    },
    command: () => {
      this.freyaMutate.openMutateObject({
        mutateType: 'create',
        objectType: 'ical',
        additionalValues: [{
          property: 'assetId',
          value: this.asset.id,
        }],
      });
    },
    disabled: true,
  };

  viewHistoryAction: KarveMenuItem = {
    id: 'viewHistory',
    label: 'View History',
    icon: 'pi pi-book',
    command: () => {
      if (!this?.asset?.id) { return; }
      this.historyService.openHistory('Asset', [this.asset.id]);
    }
  };

  assetActions: KarveMenuItem[] = [{
    label: 'Asset Actions',
    items: [
      this.updateAssetAction,
      this.deleteAssetAction,
      this.undeleteAssetAction,
      // this.bookOffAction,
      this.quickBookOff,
      this.applyTemplateAction,
      this.exportToCalendar,
      this.viewHistoryAction,
    ]
  }];

  subs = new SubSink();

  assetsQueryRef: QueryRef<ListAssetsOutput>;
  assetsQH: WatchQueryHelper = {
    loading: true,
  };

  assetTypes = assetTypesDropdown;

  // Edit Asset Variables
  showDeleteAssetDialog = false;

  zones: Zone[];

  stringifyLocation = stringifyLocation;

  // EVENTS
  upcomingEvents: CalendarEvent_LocationsWithServiceAreaFragment[] = [];
  eventsLoading = true;

  icalExportFeatureEnabled$ = this.store.select(genFeatureSelector('ical-export'));

  constructor(
    private assetService: AssetService,
    private store: Store,
    public detailsHelper: DetailsHelperService,
    private localNotify: FreyaNotificationsService,
    private zoneService: ZoneService,
    private upcomingEventsQuery: UpcomingEventsGQL,
    private permissionHandler: PermissionService,
    private recentItems: RecentItemsService,
    private dialogService: DialogService,
    private freyaHelper: FreyaHelperService,
    public yemboHelper: YemboHelperService,
    private freyaMutate: FreyaMutateService,
    private confirmationService: ConfirmationService,
    private deleteAssetsGQL: DeleteAssetsGQL,
    private historyService: HistoryService,
  ) { }

  ngOnInit(): void {
    if (!this.asset.name){
      this.fetchAsset();
    }

    this.loadZones();
    this.initializePermissions();

    this.subs.sink = this.detailsHelper.getObjectUpdates('Assets')
    .subscribe(() => this.fetchAssetsAndMore());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.asset.id && !this.asset.type) {
      this.fetchAssetsAndMore();
      return;
    };
    this.loadZones();
    this.fetchUpcomingEvents();
    this.recentItems.setPinAction(this.assetActions, this.asset, 'asset');
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  fetchAsset() {
    const assetQueryInput = {
      filter: {
        ids: [this.asset.id],
        showDeleted: true,
      }
    };

    if (this.assetsQueryRef) {
      this.assetsQueryRef.refetch(assetQueryInput);
      return;
    };

    this.assetsQueryRef = this.assetService.watchAssets(assetQueryInput, {
      location: true,
      owner: true,
      metadata: true,
      zones: true,
    }, 'cache-and-network');

    this.subs.sink = this.assetsQueryRef.valueChanges
    .subscribe((res) => {
      this.assetsQH.loading = res.loading;
      if (!res.data?.assets.assets.length) { return; };
      this.asset = res.data.assets.assets[0];
      console.log(this.asset);
      this.zones = this.asset.zones;

      this.setActionStates();
    });
  }

  async fetchAssetsAndMore() {
    console.log('fetching asset');
    await this.fetchAsset();
    this.fetchUpcomingEvents();
    this.recentItems.setPinAction(this.assetActions, this.asset, 'asset');
  }

  initializePermissions() {
    this.subs.sink = this.permissionHandler.watchPermissions(
      [
        'assets.update',
        'assets.delete',
        'calendarEvents.create',
        'tokens.createIcalToken',
      ])
      .pipe(withLatestFrom(this.icalExportFeatureEnabled$))
      .subscribe(([res, icalExportFeatureEnabled]) => {
        this.applyTemplateAction.visible = res[0];
        this.updateAssetAction.visible = res[0];
        this.deleteAssetAction.visible = res[1];
        this.bookOffAction.visible = res[2];
        this.quickBookOff.visible = res[2];
        this.exportToCalendar.visible = res[3] && icalExportFeatureEnabled;

        parseMenuItemCategoriesVisible(this.assetActions);
        this.assetActions = clone(this.assetActions);
      });
  }

  /**
   * Set the action states based on the assets state
   */
  setActionStates(){
    const disabledWhen: DisabledWhen = {
      objectDeleted: Boolean(this.asset.deletedAt),
      inRootOrCorporateZone: this.freyaHelper.inRootOrCorporateZone,
    };

    for (const action of this.assetActions[0].items){
      setMenuItemDisabled(action, disabledWhen);
    }

    this.undeleteAssetAction.visible = disabledWhen.objectDeleted;

    this.assetActions = clone(this.assetActions);
  }

  openEditAssetDialog() {
    this.mutateRef.mutateType = 'update';
    this.mutateRef.openDialog();
  }

  loadZones() {
    this.zones = undefined;

    this.subs.sink = this.zoneService.listZones({
      filter: {
        objects: [this.asset.id],
        objectLabel: 'Asset',
      },
    }, {
      areas: true,
    }).subscribe((res) => {
      this.zones = res.data?.zones.nodes;
    });
  }

  fetchUpcomingEvents() {
    this.eventsLoading = true;
    const eventFilter = {
      filter: {
        usesAssets: [this.asset.id],
        min: Math.floor(Date.now() / 1000),
      },
      limit: 5
    } as UpcomingEventsQueryVariables;

    this.subs.sink = this.upcomingEventsQuery.fetch(eventFilter).subscribe((res) => {
      this.eventsLoading = false;
      this.upcomingEvents = res.data.calendarEvents.events.filter((ev) => ev.type !== BOOK_OFF_EVENT_TYPE);
    });
  }

}
