<div
    class="card"
    *ngIf="showHeaderCard"
>
    <div class="p-grid">
        <div class="p-col-6 p-md-9">
            <h4 class="p-d-inline">Jobs 
                <ng-container *ngIf="jobsQH.total !== undefined">
                    ({{ jobsQH.total }})
                </ng-container>
            </h4>
            <span *ngIf="jobsQH.total === undefined && !jobCountLoading" class="freya-link-button" (click)="getJobCount()">Show count</span>
            <p-skeleton
                styleClass="p-d-inline-block"
                *ngIf="jobCountLoading"
                height="1.5rem"
                width="2.5rem"
                ></p-skeleton>
        </div>
        <div class="p-col-6 p-md-3">
            <label>Filters</label>
            <div class="p-100 views">
                <app-filter-select #filterSelect
                    filterType="job"
                    [filter]="paramsFilter"
                    [presets]="presetFilters"
                    (filterChange)="updateFilter($event)"
                    (newFilter)="isFilterConfigurationNew = $event"
                    [style]="{ 'border-radius': '6px 0 0 6px' }"
                ></app-filter-select>
                <button
                    pButton
                    icon="pi pi-save"
                    pTooltip="Save Filter"
                    tooltipPosition="left"
                    [disabled]="!filterSelect.isNewFilterConfig"
                    data-cy="save-current-config-button"
                    (click)="openSaveFilter()"
                ></button>
            </div>
        </div>
        <div class="p-col" [formGroup]="jobSearchForm">
            <div class="p-grid">
                <div class="p-col-12 p-md-3">
                    <label>Search</label>
                    <br />
                    <input
                        type="text"
                        #searchInput
                        pInputText
                        formControlName="search"
                        placeholder="Enter Code, Customer, Email ..."
                        class="full-width"
                        data-cy="search-text-field"
                    >
                </div>
                    <div class="p-col-6 p-md-3">
                        <label>Stage</label>
                        <br />
                        <p-dropdown
                            [options]="stages"
                            formControlName="stage"
                            optionlabel="label"
                            optionValue="option"
                            data-cy="stage-drop-down-field"
                        ></p-dropdown>
                    </div>
                    <div class="p-col-6 p-md-3">
                        <label>State</label>
                        <p-dropdown
                            [options]="states"
                            formControlName="state"
                            optionlabel="label"
                            optionValue="option"
                        ></p-dropdown>
                    </div>
                    <div class="p-col-12 p-md-3 expand-filters-wrapper">
                        <div class="dropdown-wrapper">
                            <label>Tags</label>
                            <br />
                            <p-multiSelect
                                styleClass="fw-multiselect"
                                [options]="tags"
                                formControlName="tagIds"
                                placeholder="Select a Country"
                                optionLabel="name"
                                optionValue="id"
                                [autofocusFilter]="!responsiveHelper.isSmallScreen"
                                [appendTo]="responsiveHelper.isSmallScreen? 'body' : null"
                                data-cy="status-multi-select-field"
                                >
                                <ng-template
                                    let-value
                                    pTemplate="selectedItems"
                                    >
                                    <div
                                        *ngFor="let id of jobSearchForm.controls.tagIds.value"
                                        class="tag-container"
                                        >
                                        <p-tag
                                            *appVar="(tags | getObjectById : id) as tag"
                                            [style]="{'background-color': tag.color}"
                                            [colorForBackground]="{elementSelector: 'span', backgroundColor: tag.color}"
                                            >
                                            {{tag | formatTag}}
                                        </p-tag>
                                    </div>
                                        <div *ngIf="!jobSearchForm.controls.tagIds.value?.length">
                                            No Filter
                                        </div>
                                </ng-template>
                                <ng-template
                                    let-tag
                                    pTemplate="item"
                                    >
                                    <p-tag 
                                        [style]="{'background-color': tag.color}"
                                        [colorForBackground]="{elementSelector: 'span', backgroundColor: tag.color}">
                                        {{tag | formatTag}}
                                    </p-tag>
                                </ng-template>
                            </p-multiSelect>
                        </div>
                        <div>
                            <br/>
                            <button
                                pButton
                                [pTooltip]="filtersCollapsed ? 'Show All Filters' : 'Hide Additional Filters'"
                                tooltipPosition="bottom"
                                class="p-button-secondary"
                                [icon]="filtersCollapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up'"
                                iconPos="right"
                                (click)="filtersCollapsed = !filtersCollapsed"
                                ></button>
                        </div>
                    </div>

            </div>
            <div class='collapsable' [class.collapsed]="filtersCollapsed">
                <div class="p-grid">
                    <div class="p-col-6 p-md-4">
                        <app-info-circle
                            tooltip="Shows jobs created between provided start and end dates."
                            >
                            <label>Created </label> 
                        </app-info-circle>
                        <br>
                        <p-calendar
                            selectionMode="range"
                            formControlName="created"
                            placeholder="Enter Start and End Dates"
                            [showIcon]="true"
                            styleClass="full-width"
                            data-cy="created-date-calendar-field"
                            ></p-calendar>
                    </div>
                    <div class="p-col-6 p-md-4">
                        <app-info-circle
                            tooltip="Shows jobs last updated between provided start and end dates."
                            >
                            <label>Last Updated </label>
                        </app-info-circle>
                        <br>
                        <p-calendar
                            selectionMode="range"
                            formControlName="lastUpdated"
                            placeholder="Enter Start and End Dates"
                            [showIcon]="true"
                            styleClass="full-width"
                            data-cy="last-updated-date-calendar-field"
                            ></p-calendar>
                    </div>
                    <div class="p-col-12 p-md-4">
                        <app-info-circle
                            tooltip="Shows jobs in which at least one selected event was booked within the specified range."
                            >
                            <label>Event Booking Dates </label>
                        </app-info-circle>
                        <br>
                        <div class="p-inputgroup padded">
                            <p-multiSelect
                                [options]="eventTypes"
                                formControlName="bookedEvents"
                                [maxSelectedLabels]="2"
                                [selectedItemsLabel]="'{0} items selected'"
                                placeholder="Select Events"
                                [autofocusFilter]="!responsiveHelper.isSmallScreen"
                                [appendTo]="responsiveHelper.isSmallScreen? 'body' : null"
                                data-cy="event-type-multi-select-field"
                                ></p-multiSelect>
                            <p-calendar
                                selectionMode="range"
                                formControlName="bookingDates"
                                placeholder="Enter Start and End Dates"
                                [showIcon]="true"
                                styleClass="full-width"
                                appendTo="body"
                                data-cy="event-date-range-calendar-field"
                                ></p-calendar>
                        </div>
                    </div>
                </div>
                <div class="p-grid">
                    <div class="p-col-12 p-md-4">
                        <app-info-circle
                            tooltip="Shows jobs with at least one transaction within the specified range."
                        >
                            <label>Transaction Amount </label>
                        </app-info-circle>
                        <br>
                        <div class="p-inputgroup padded">
                            <p-inputNumber
                                mode="currency"
                                [currency]="currencyFilters.currency"
                                [locale]="currencyFilters.locale"
                                placeholder="Min"
                                formControlName="transactionMin"
                                data-cy="txn-min-number-field"
                            ></p-inputNumber>
                            <p-inputNumber
                                mode="currency"
                                [currency]="currencyFilters.currency"
                                [locale]="currencyFilters.locale"
                                placeholder="Max"
                                formControlName="transactionMax"
                                data-cy="txn-max-number-field"
                            ></p-inputNumber>
                        </div>
                    </div>
                    <div class="p-col-6 p-md-4">
                        <app-info-circle
                            tooltip="Shows jobs closed between provided start and end dates."
                        >
                            <label>Closed At </label> 
                        </app-info-circle>
                        <br>
                        <p-calendar
                            selectionMode="range"
                            formControlName="closedAt"
                            placeholder="Enter Start and End Dates"
                            [showIcon]="true"
                            styleClass="full-width"
                        ></p-calendar>
                    </div>
                    <div class="p-col-6 p-md-4">
                        <label>Closed Reason</label>
                        <br />
                        <p-dropdown
                            [options]="closedReasons$ | async"
                            formControlName="closedReason"
                            placeholder="Select Reason for Closing the Job"
                            optionlabel="label"
                            optionValue="option"
                        ></p-dropdown>
                    </div>
                    <div class="p-col-6 p-md-4">
                        <app-info-circle
                            tooltip="Shows jobs archived between provided start and end dates."
                        >
                            <label>Archived At </label> 
                        </app-info-circle>
                        <br>
                        <p-calendar
                            selectionMode="range"
                            formControlName="archivedAt"
                            placeholder="Enter Start and End Dates"
                            [showIcon]="true"
                            styleClass="full-width"
                        ></p-calendar>
                    </div>
                    <div class="p-col-6 p-md-4">
                        <label>Sources</label> 
                        <br>
                        <p-chips
                            formControlName="sources"
                            class="full-width-chips"
                            styleClass="full-width"
                            class="full-width-chips"
                            ></p-chips>
                    </div>
                    <div class="p-col-6 p-md-4 button-wrapper">
                        <div>
                            <br>
                            <button
                                pButton
                                icon="pi pi-times"
                                label="Clear Filters"
                                [disabled]="jobSearchForm.pristine"
                                (click)="resetFilters()"
                                data-cy="clear-all-button"
                            ></button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<br *ngIf="showHeaderCard" class="p-d-none p-d-md-inline">

<div class="card">
    <h5 *ngIf="tableTitle">{{tableTitle}}</h5>
    <p-table
        [value]="jobs"
        [loading]="jobsQH.loading"
        [showLoader]="!jobsQH.mergeNextResults && !jobsQH.isFirstLoad"
        breakpoint="620px"
    >
        <ng-template pTemplate="header">
            <tr>
                <th *ngIf="activeData.stage" style="width: 1rem">Stage</th>
                <th *ngIf="activeData.tags">Tags</th>
                <th *ngIf="activeData.state" class="p-text-center">State</th>
                <th *ngIf="activeData.code">Code</th>
                <th *ngIf="activeData.status">Status</th>
                <th *ngIf="activeData.customer">Customer</th>
                <th *ngIf="activeData.total">Total</th>
                <th *ngIf="activeData.amountDue">Amount Due</th>
                <th *ngIf="activeData.date">Created</th>
                <th *ngIf="activeData.firstBookedRevenueGeneratingEvent">Booking Date</th>
                <th *ngIf="activeData.timeline">Timeline</th>
                <th
                    *ngIf="activeData.link"
                    style="width: 3rem"
                ></th>
            </tr>
        </ng-template>
        <ng-template
            pTemplate="body"
            let-job
            let-index="rowIndex"
        >
            <tr
                class="selectable-row"
                *ngIf="!job.error"
                (appDoubleClick)="freyaHelper.openItem('job', job?.id)"
                (singleClick)="selectJob(job)"
                (auxclick)="freyaHelper.openItem('job', job?.id, true)"
            >
                <td *ngIf="activeData.stage">
                    <span class="p-column-title">Stage</span>
                    {{job.stage | titlecase}}
                </td>
                <td *ngIf="activeData.tags">
                    <span class="p-column-title">Tags</span>
                    <span>
                        <p-tag
                            *ngFor="let tag of job?.tags"
                            [style]="{'background-color': tag.color}"
                            [colorForBackground]="{elementSelector: 'span', backgroundColor: tag.color}"
                        >
                            {{tag | formatTag}}
                        </p-tag>
                    </span>
                </td>
                <td *ngIf="activeData.state" class="p-text-center">
                    <span class="p-column-title">State</span>
                    <app-object-state
                        [object]="job"
                        objectType="job"
                        [colorful]="true"
                        ></app-object-state>
                </td>
                <td *ngIf="activeData.code">
                    <span class="p-column-title">Code</span>
                    {{job.code}}
                </td>
                <td *ngIf="activeData.status">
                    <span class="p-column-title">Status</span>
                    <div class="tag-container">
                        <p-tag
                            *ngIf="job?.tags.length > 0"
                            [style]="{'background-color': job.tags[0].color}"
                        >
                            {{job.tags[0] | formatTag}}
                        </p-tag>
                        <p-tag *ngIf="job.tags.length > 1">
                            +{{job.tags.length - 1}}
                        </p-tag>
                        <span *ngIf="!job.tags || job.tags.length === 0">
                            None
                        </span>
                    </div>
                </td>
                <td *ngIf="activeData.customer">
                    <span class="p-column-title">Customer</span>
                    {{job.users | jobCustomer}}
                </td>
                <td *ngIf="activeData.total">
                    <span class="p-column-title">Total</span>
                    {{job.total / 100 | currency : job.currency}}
                </td>
                <td *ngIf="activeData.amountDue">
                    <span class="p-column-title">Amount Due</span>
                    {{job | jobAmountDue}}
                </td>
                <td *ngIf="activeData.date">
                    <span class="p-column-title">Date</span>
                    {{job.createdAt * 1000 | date : 'mediumDate'}}
                </td>
                <td *ngIf="activeData.firstBookedRevenueGeneratingEvent">
                    <span class="p-column-title">Booking Date</span>
                    <span *ngIf="job.firstBookedRevenueGeneratingEvent">
                        {{job.firstBookedRevenueGeneratingEvent * 1000 | date : 'mediumDate'}}
                    </span>
                </td>
                <td *ngIf="activeData.timeline">
                    <span class="p-column-title">Timeline</span>
                    <span *ngIf="!job.timeline"
                        [pTooltip]="formatTimelineTooltip(job.createdAt, job.timeline)"
                    ></span>
                    <span *ngIf="job.timeline"
                        class="timeline"
                        [pTooltip]="formatTimelineTooltip(job.createdAt, job.timeline)"
                    >
                        {{ formatTimeline(job.timeline) | date : 'mediumDate' }}
                        <br />
                        <small>{{ formatTimelineTooltip(job.createdAt, job.timeline) }}</small>
                    </span>
                </td>
                <td *ngIf="activeData.link" >
                    <span></span>
                    <app-open-link
                        [link]="generateLink(job.id)">
                    </app-open-link>
                </td>
            </tr>
            <tr *ngIf="job.error">
                <td colspan="6">Error Loading Job : {{job.error.message}}</td>
            </tr>
        </ng-template>
        <ng-template pTemplate="loadingbody">
            <ng-container *ngFor="let placeholder of jobPlaceholderCount">
                <tr>
                    <td *ngFor="let col of jobPlaceholderColumnCount">
                        <p-skeleton
                            height="2rem"
                            width="100%"
                            ></p-skeleton>
                    </td>
                    <td
                        *ngIf="activeData.link"
                        style="width: 3rem"
                        >
                    <app-open-link [disabled]="true"></app-open-link>
                    </td>
                </tr>
            </ng-container>
        </ng-template>
        <ng-template
            pTemplate="emptymessage"
            let-car
            let-columns="columns"
        >
            <tr>
                <td [attr.colspan]="'6'">
                    {{pagination.emptyMessage}}
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="footer">
            <tr>
            </tr>
        </ng-template>
    </p-table>
    <div
        *ngIf="!jobsQH.loading && jobsQH.hasMore"
        class="p-text-center"
        (appLazyLoad)="retrieveMoreJobs()"
        >
        <span class="freya-link-button" (click)="retrieveMoreJobs()">Load more</span>
    </div>
    <div
        *ngIf="!jobsQH.loading && !jobsQH.hasMore"
        class="p-text-center"
    >
        No more jobs to show.
    </div>
</div>
