import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { Observable, of, Subject } from 'rxjs';

import { Jobsv2Component } from './jobsv2.component';
import { DialogService } from 'primeng/dynamicdialog';
import { LeaveJobsv2Component } from './leave-jobsv2-confirm/leave-jobsv2-confirm.component';


export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable({
  providedIn: 'root'
})
export class LeaveJobsV2Guard {

  public constructor(
    private confirmService: ConfirmationService,
    public dialogService: DialogService
  ) {}

  canDeactivate(
    component: Jobsv2Component,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const hasUnsavedChanges = component.chargesUpdates.length;

    if (!hasUnsavedChanges) {
      return of(true);
    }

    const sub = new Subject<boolean>();

    this.dialogService.open(LeaveJobsv2Component, {
      width: '46rem',
      data: { sub },
    });

    /*this.confirmService.confirm({
      message: 'You have unsaved changes. Are you sure you want to continue?',
      acceptLabel: 'Save & Continue',
      rejectLabel: 'Discard Changes',
      header: 'Save changes?',
      icon: 'pi pi-exclamation-triangle',
      closeOnEscape: true,
      dismissableMask: true,
      rejectIcon: 'pi pi-trash',
      acceptIcon: 'pi pi-save',
      // rejectIcon: ''
      accept: async () => {
        sub.next(true);
      },
      reject: (eventCode) => {
        console.log('eventCode', eventCode)
        if (eventCode === 1){
          sub.next(true);
        } else {
          sub.next(false);
        }
      },
    });*/

    return sub.asObservable();
  }
}
