import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { SubSink } from 'subsink';

import { AppComponent } from './app.component';
import { MenuService } from './base/menu/app.menu.service';
import { ColorSchemeService } from './color-scheme.service';
import { hash_cyrb53, safeParseJSON } from './js';
import { BrandingService } from './services/branding.service';
import { DetailsHelperService } from './services/details-helper.service';
import { FreyaHelperService } from './services/freya-helper.service';
import { ResponsiveHelperService } from './services/responsive-helper.service';
import { currentTimeSeconds } from './time';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Component({
    selector: 'app-main',
    templateUrl: './app.main.component.html',
    styleUrls: ['./app.main.scss'],
})
export class AppMainComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('layoutMain') layoutMain: ElementRef;

    subs = new SubSink();

    sidebarStatic: boolean;

    sidebarActive = false;

    staticMenuMobileActive: boolean;

    menuClick: boolean;

    topbarItemClick: boolean;

    activeTopbarItem: any;

    topbarMenuActive: boolean;

    searchClick = false;

    search = false;

    rightPanelClick: boolean;

    rightPanelActive: boolean;

    configActive = true;

    configClick: boolean;

    menuHoverActive = false;

    estimatingPanelActive = false; // Set this with Set Estimating Panel

    /**
     * Examples:
{
   "id":"mtnt-2023-05-11",
   "summary":"Scheduled Maintenance Tonight",
   "detail":"There will be scheduled maintenance May 11 between **22:30 - 24:00 CST** to improve application performance and reliability.",
   "severity":"warn",
   "notBefore":1683844065,
   "notAfter":1683844095
}
     */
    notice?: {
        severity: string;
        summary: string;
        detail?: string;
        notBefore?: number;
        notAfter?: number;
        icon?: string;
        id?: string;
    };

    constructor(
        private menuService: MenuService,
        private primengConfig: PrimeNGConfig,
        public app: AppComponent,
        private detailHelperService: DetailsHelperService,
        public cd: ChangeDetectorRef,
        private brandingSvc: BrandingService,
        public freyaHelper: FreyaHelperService,
        public colorSchemeService: ColorSchemeService,
        public responsiveHelper: ResponsiveHelperService,
        private router: Router,
    ) {}

    ngOnInit() {
        this.subs.sink = this.detailHelperService.detailsItem.subscribe((res) => {
            this.rightPanelActive = Boolean(res);
            this.detailHelperService.rightPanelOpen = this.rightPanelActive;
            this.freyaHelper.containerSizeChanged.next();
        });

        if (localStorage.getItem('sidebarStatic') === 'true') {
            this.sidebarStatic = true;
            this.sidebarActive = true;
        }

        if (localStorage.getItem('estimatingPanelActive')) { // Restore the state of the estimating panel from before reloading
            this.setEstimatingPanel(localStorage.getItem('estimatingPanelActive') === 'true');
        }

        this.checkBranding();
    }

    ngAfterViewInit(): void {
        this.router.events
            .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
            .subscribe(this.scrollToTop.bind(this));
    }

    ngOnDestroy(){
        this.subs.unsubscribe();
    }

    checkBranding() {

        this.subs.sink = this.brandingSvc.currentBranding.subscribe((b) => {
            const notice = b.notice;
            if (notice) {
                this.notice = safeParseJSON(notice, undefined);
            } else {
                this.notice = undefined;
            }

            if (this.notice?.notBefore > currentTimeSeconds()) {
                delete this.notice;
            }

            if (this.notice?.notAfter < currentTimeSeconds()) {
                delete this.notice;
            }

            if (notice) {
                const noticeID = this.notice?.id || hash_cyrb53(this.brandingSvc.currentBranding.value.notice);
                const isClosed = localStorage.getItem(`${noticeID}_closed`) === 'true';
                if (isClosed) {
                    delete this.notice;
                }
            }

            // if notice is closed, delete notice
        });
    }

    closeNotice() {

        const noticeID = this.notice.id || hash_cyrb53(this.brandingSvc.currentBranding.value.notice);
        localStorage.setItem(`${noticeID}_closed`, `true`);

        delete this.notice;

    }

    get editBarVisible() {
        return this.freyaHelper.onTheSchedulePage && this.freyaHelper.scheduleEditModeEnabled.value;
    }

    setEstimatingPanel(value: boolean) {
        this.estimatingPanelActive = value;

        if (value) {
            this.rightPanelActive = !value;
        }

        localStorage.setItem('estimatingPanelActive', `${value}`);
        this.freyaHelper.containerSizeChanged.next();
    }

    checkIfScrolled(event) {
        if (event.target.scrollTop > 1) {
            this.detailHelperService.hasScrolled = true;
        } else {
            this.detailHelperService.hasScrolled = false;
        }

        this.freyaHelper.layoutScrolled.next();
    }

    onLayoutClick() {
        if (!this.topbarItemClick) {
            this.activeTopbarItem = null;
            this.topbarMenuActive = false;
        }

        if (!this.menuClick && (this.isHorizontal() || this.isSlim())) {
            this.menuService.reset();
        }

        // if (this.configActive && !this.configClick) {
        //     this.configActive = false;
        // }

        // if (!this.rightPanelClick) {
        //     this.rightPanelActive = false;
        // }

        if (!this.menuClick) {
            if (this.staticMenuMobileActive) {
                this.staticMenuMobileActive = false;
            }

            this.menuHoverActive = false;
            this.unblockBodyScroll();
        }

        if (!this.searchClick) {
            this.search = false;
        }

        this.searchClick = false;
        this.configClick = false;
        this.topbarItemClick = false;
        this.menuClick = false;
        this.rightPanelClick = false;
    }

    onMenuButtonClick(event) {
        this.menuClick = true;
        this.topbarMenuActive = false;
        this.rightPanelActive = false;

        if (!this.responsiveHelper.isLargeScreen) {
            this.staticMenuMobileActive = !this.staticMenuMobileActive;
            if (this.staticMenuMobileActive) {
                this.blockBodyScroll();
            } else {
                this.unblockBodyScroll();
            }
        }

        this.setEstimatingPanel(false);

        event.preventDefault();
    }

    onTopbarItemClick(event, item) {
        this.topbarItemClick = true;

        if (this.activeTopbarItem === item) {
            this.activeTopbarItem = null;
        } else {
            this.activeTopbarItem = item;
        }

        if (item.className === 'topbar-item search-item') {
            this.search = !this.search;
            this.searchClick = !this.searchClick;
        }

        event.preventDefault();
    }

    onRightPanelClick(event) {
        event.preventDefault();

        this.rightPanelClick = true;
        this.rightPanelActive = !this.rightPanelActive;
        this.freyaHelper.containerSizeChanged.next();

        this.detailHelperService.rightPanelOpen = this.rightPanelActive;

        this.staticMenuMobileActive = false;

        if (this.rightPanelActive) {
            this.setEstimatingPanel(false);
        }

    }

    onRippleChange(event) {
        this.app.ripple = event.checked;
        this.primengConfig.ripple = event.checked;
    }

    onConfigClick(event) {
        this.configClick = true;
    }

    onSidebarClick($event) {
        this.menuClick = true;
    }

    onToggleMenu(event) {
        this.menuClick = true;
        this.sidebarStatic = !this.sidebarStatic;
        localStorage.setItem('sidebarStatic', this.sidebarStatic.toString());

        event.preventDefault();
    }

    onSidebarMouseOver(event) {
        if (this.app.menuMode === 'sidebar' && !this.sidebarStatic) {
            this.sidebarActive = this.responsiveHelper.isLargeScreen;
        }
    }

    onSidebarMouseLeave($event) {
        if (this.app.menuMode === 'sidebar' && !this.sidebarStatic) {
            setTimeout(() => {
                this.sidebarActive = false;
            }, 250);
        }
    }

    dismissRightPanels() {
        this.setEstimatingPanel(false);
        if (this.rightPanelActive) {
            this.rightPanelActive = false;
            this.freyaHelper.containerSizeChanged.next();
        }
    }

    isSlim() {
        return this.app.menuMode === 'slim';
    }

    isHorizontal() {
        return this.app.menuMode === 'horizontal';
    }

    blockBodyScroll(): void {
        if (document.body.classList) {
            document.body.classList.add('blocked-scroll');
        } else {
            document.body.className += ' blocked-scroll';
        }
    }

    unblockBodyScroll(): void {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
                'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    }

    scrollToTop() {
        if (!this.layoutMain?.nativeElement) { return; };
        this.layoutMain.nativeElement.scrollTop = 0;
    }
}
