import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RawUser } from '@karve.it/interfaces/auth';
import { Field } from '@karve.it/interfaces/fields';
import pluralize from 'pluralize';
import { CHARGE_EVENT_TYPE_FILTER, eventTypeInfoMap, pagination } from 'src/app/global.constants';
import { ChargeHelperService } from 'src/app/services/charge-helper.service';
import { DetailsHelperService } from 'src/app/services/details-helper.service';
import { EstimateHelperService } from 'src/app/services/estimate-helper.service';
import { FreyaMutateService } from 'src/app/services/freya-mutate.service';
import { MutateCalendarEventComponent } from 'src/app/shared/mutate-calendar-event/mutate-calendar-event.component';
import { CalendarEvent, Charge, EstimatesJobFragment, SetFieldValuesGQL, FullJobFragment, BaseLocationFragment, Discount } from 'src/generated/graphql.generated';
import { SubSink } from 'subsink';

import { getFieldValue, splitName } from '../../fields/fields.utils';
import { HistoryService } from '../../history/history.service';
import { formatLocationToDisplay, getJobLocation, isJobSharedToZone } from '../../jobs/jobs.util';
import { BrandingService } from '../../services/branding.service';
import { DocumentHelperService } from '../../services/document-helper.service';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';
import { GoogleHelperService } from '../../services/google-helper.service';
import { YemboHelperService } from '../../services/yembo-helper.service';

import { showGoogleMapsRoute, viewLocationOnGoogleMaps } from '../../utilities/locations.util';
import { DistanceService } from '../distance.service';

import { EstimateUpdated, EstimateUpdatedEvent } from '../estimate.interfaces';
import { EstimatingInventoryComponent } from '../estimating-inventory/estimating-inventory.component';

export interface EventWithCharges{
  id: string;
  // type: string;
  charges: Charge[];
  event?: CalendarEvent;
  discountTotal?: number;
  subtotal?: number;
  taxTotal?: number;
  total?: number;
  unsavedChanges?: boolean;
}

const eventDefaults = [
  { type: 'estimating', event: undefined, crewSize: 0 },
  { type: 'virtualEstimate', event: undefined, crewSize: 0 },
  { type: 'moving', event: undefined, crewSize: 0 },
  { type: 'packing', event: undefined, crewSize: 0 },
  { type: 'delivery', event: undefined, crewSize: 0 },
  { type: 'unpacking', event: undefined, crewSize: 0 }
];

@Component({
  selector: 'app-estimate-confirmation',
  templateUrl: './estimate-confirmation.component.html',
  styleUrls: ['./estimate-confirmation.component.scss']
})
export class EstimateConfirmationComponent implements OnInit, OnDestroy, EstimateUpdated, OnChanges {

  @ViewChild('mutateEvent') mutateEventRef: MutateCalendarEventComponent;

  @ViewChild('print') printRef: ElementRef;
  @ViewChild('estimateConfirmationInventory') inventoryRef: EstimatingInventoryComponent;

  // @Output() fieldUpdated = new EventEmitter<ModifiedField>();
  @Output() estimateUpdated = new EventEmitter<EstimateUpdatedEvent>();

  @Input() job: EstimatesJobFragment;
  @Input() showLoading = false;

  @Input() onEstimator = false;

  // FIELDS
  @Input() inventory: string;
  @Input() needsFinancing: boolean;

  @Input() printSectionId: string;

  displayFields = {
    dwellingType: undefined,
    bedrooms: undefined,
    sqft: undefined,
    howDidTheyHearAboutUs: undefined,
  };

  subs = new SubSink();

  // EVENTS
  activeEventId: string;

  pagination = pagination;

  showCancelDialog = false;

  signatureRequired: boolean;
  signedAt: number; // unix seconds

  // LINE ITEM VARIABLES
  eventAttributesFilter = [];
  eventFilterOptions = CHARGE_EVENT_TYPE_FILTER;

  eventsWithCharges: EventWithCharges[];

  // Events that should be opened by default, any with actual events or charges
  eventsOpenByDefault = {
    estimating: false,
    moving: false,
    delivery: false,
    packing: false,
    unpacking: false
  };

  eventTypeInfoMap = eventTypeInfoMap;

  // This broke in primeng 17.13.0
  expandedRowKeys = {};

  yemboEnabled = false;

  readonly: boolean;
  readonlyTooltip: string;

  sharedFromAnotherZone: boolean;

  jobLocations = {
    start: {
      title: 'Starting Location',
      locationType: 'start',
      addressString: '',
      details: '',
      parkingInformation: '',
      location: undefined,
    },
    end: {
      title: 'Ending Location',
      locationType: 'end',
      addressString: '',
      details: '',
      parkingInformation: '',
      location: undefined,
    },
    dock: {
      title: 'Dock Location',
      locationType: 'dock',
      addressString: '',
      location: undefined,
    },
    otherStops: [],
  };
  
  // Initialize location fields objects
  locationFields = {
    startLocation: {} as any,
    endLocation: {} as any
  };

  constructor(
    public estimateHelper: EstimateHelperService,
    private freyaMutate: FreyaMutateService,
    public detailsHelper: DetailsHelperService,
    private distanceService: DistanceService,
    private chargeHelper: ChargeHelperService,
    public yemboHelper: YemboHelperService,
    public googleHelper: GoogleHelperService,
    private historyService: HistoryService,
    private brandingService: BrandingService,
    private setFieldValuesGQL: SetFieldValuesGQL,
    private localNotify: FreyaNotificationsService,
    private route: ActivatedRoute,
    private router: Router,
    public documentHelper: DocumentHelperService,
  ) { }

  ngOnInit(): void {
    this.subs.sink = this.route.queryParamMap.subscribe(paramMap => {
      if (paramMap.get('activeEventId')) {
        this.activeEventId = paramMap.get('activeEventId');
      }
    });
    if(!this.job){ return; }
    // this.setEvents(this.job);
    this.updateDistances();
    this.setReadonly();
    this.expandAllEvents();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    let newJobLoaded = false;

    if (changes.hasOwnProperty('job')) {
      newJobLoaded = changes.job?.currentValue?.id !== changes.job?.previousValue?.id;
    }

    if (!this.job) { return; }

    if (!this.activeEventId){
      this.setActiveEventId(0);
    }
    else {
      this.setActiveEventId(this.job?.events?.findIndex(event => event.id === this.activeEventId));
    }

    this.setCalendarEventCharges();
    // this.setEvents(this.job, newJobLoaded);
    this.setReadonly();
    this.getStartAndEndLocations();
    // if (this.standalone) {
    // this.updateDistances();
    // }
  }

  reset() {
    this.job = undefined;
    this.readonly = false;
    this.readonlyTooltip = undefined;
    this.showLoading = false;
    this.inventory = undefined;
    this.needsFinancing = undefined;
    this.showCancelDialog = false;
    this.signatureRequired = undefined;
    this.signedAt = undefined;
    this.activeEventId = undefined;
    // this.events = cloneDeep(eventDefaults);
  }

  async updateDistances() {
    this.distanceService.setLocations({
      start: this.distanceService.convertLocationToDistanceLocation(getJobLocation(this.job, 'start')),
      end: this.distanceService.convertLocationToDistanceLocation(getJobLocation(this.job, 'end')),
    });

    this.subs.sink = await this.distanceService.calculateDistances();
  }

  updateSignedAt(){
    this.signedAt = Math.floor(new Date().getTime() / 1000);
    this.setFieldValues('misc.signedAt', this.signedAt);
  }

  async setFieldValues(name, value) {
    this.setFieldValuesGQL.mutate(
      {
        fields: [{
          fieldName: name,
          value,
        }],
        objectLabel: 'Job',
        objects: [this.job.id]
      }
    ).subscribe((res) => {
      this.detailsHelper.pushUpdate({
        id: this.job.id,
        type: 'Fields',
        action: 'update',
      });
    }, (err) => {
      console.error(err);
      this.localNotify.apolloError(`Error updating field ${name}`, err);
    });
  }

  // Function to process location fields and build details string
  buildLocationDetails(locationFields) {
    const getElevatorDescription = (elevators) => {
      if (!elevators || elevators === 'None') return 'No Elevator';
      return `Elevator (${elevators})`;
    };
  
    const getStairsDescription = (stairs) => {
      if (!stairs) return undefined;
      if (stairs === 0) return 'No Stairs';
      if (stairs === 0.5) return 'Half Flight of Stairs (Up)';
      if (stairs === -0.5) return 'Half Flight of Stairs (Down)';
      return pluralize('stairs', stairs, true);
    };
  
    const getDwellingDescription = (dwellingType, bedrooms) => {
      if (!dwellingType && !bedrooms) return undefined;
      if(!dwellingType) return `${bedrooms} BR`;
      if(!bedrooms) return dwellingType;

      const beautifiedDwellingType = dwellingType === 'Commercial' ? 'Commercial Space' : dwellingType;
      return `${bedrooms} BR ${beautifiedDwellingType}`;
    };
  
    return [
      getDwellingDescription(locationFields.dwellingType, locationFields.bedrooms),
      locationFields.sqft && `${locationFields.sqft} sqft`,
      getElevatorDescription(locationFields.elevators),
      getStairsDescription(locationFields.stairs),
    ].filter(Boolean).join(', ');
  }

  /**
   * Sets the values for any fields that are used in this component
   *
   * @param fields List of fields to set the values with
   */
  setDisplayValues(fields: Field[]) {

    // Process each field and assign to respective location object
    fields.forEach(field => {
      if (field.name.startsWith('startLocation.') || field.name.startsWith('endLocation.')) {

        const { namespace, key } = splitName(field);
        this.locationFields[namespace][key] = getFieldValue(field);
      
      } else if (field.name === 'customer.howDidTheyHearAboutUs'){
        this.displayFields.howDidTheyHearAboutUs = getFieldValue(field);
      }
    });
    
    this.jobLocations.start.details = this.buildLocationDetails(this.locationFields.startLocation);
    this.jobLocations.start.parkingInformation = this.locationFields.startLocation?.parkingInformation;

    this.jobLocations.end.details = this.buildLocationDetails(this.locationFields.endLocation);
    this.jobLocations.end.parkingInformation = this.locationFields.endLocation?.parkingInformation;

    if (!this.signedAt) {
      this.signedAt = fields.find((f) => f.name === 'misc.signedAt')?.values[0]?.value || undefined;
    }

    if (!this.signatureRequired) {
      this.signatureRequired = fields.find((f) => f.name === 'misc.signatureRequired')?.values[0]?.value || undefined;
    }

    if (!this.needsFinancing) {
      this.needsFinancing = fields.find((f) => f.name === 'misc.needsFinance')?.values[0]?.value || undefined;
    }
  }

  getRuleData() {
    const signedDuration = this.signedAt ? Math.round(Date.now() / 1000) - this.signedAt : undefined;

    return {
      signature: {
        signed: Boolean(this.signedAt) || false,
        signedDuration,
        signedAt: this.signedAt,
        required: this.signatureRequired,
      },
      financing: {
        required: this.needsFinancing || false,
      }

    };
  }

  setActiveEventId(index: number){
    if (!this.job?.events?.length) { return; }

    this.activeEventId = this.job.events[index]?.id;

    this.router.navigate([], {
      queryParams: {
        activeEventId: this.activeEventId,
      },
      queryParamsHandling: 'merge',
    });
  }

  openEventDialog() {
    this.estimateHelper.openConfirmationDialog.next(true);
  }

  openMutateJob(openToStep?: string) {
    this.freyaMutate.openMutateObject({
      mutateType: 'update',
      objectType: 'job',
      object: this.job,
      openToStep,
    });
  }

  openUserDetails(user: RawUser) {
    this.detailsHelper.open('users', { id: user.id });
  }

  setCalendarEventCharges() {
    if (!this.job?.charges) { return; }

    this.eventsWithCharges = this.chargeHelper.calculateEventsWithCharges(this.job);
  }

  openEvent(eventId?: string) {
    if (!eventId) { return; }

    this.detailsHelper.open('calendar-event', { id: eventId });
  }

  openDocuments(openToStep: number) {
    this.documentHelper.openDocumentsDialog({ jobId: this.job.id, jobCode: this.job.code }, openToStep);
  }

  openSendDocuments() {
    this.documentHelper.openDocumentsDialog({ jobId: this.job.id, jobCode: this.job.code });
  }

  openHistory() {
    if (!this?.job?.id) { return; }
    this.historyService.openHistory('Job', [this.job.id]);
  }

  expandAllEvents() {
    this.expandedRowKeys = {};
    for (const event of this.job?.events || []) {
      this.expandedRowKeys[event.id] = true;
    }
    console.log(this.expandedRowKeys);
  }

  printConfirmationPage() {
    this.expandAllEvents();
    setTimeout(() => this.printRef.nativeElement.click(), 50);
  }

  setReadonly() {
    const isJobDeleted = Boolean(this.job?.deletedAt);
    const isJobArchived = Boolean(this.job?.archivedAt);
    const isJobShared = isJobSharedToZone(this.job, this.brandingService.currentZone().value.id);

    this.sharedFromAnotherZone = isJobShared;

    this.readonly = isJobDeleted || isJobArchived || isJobShared;
    if (isJobDeleted) {
      this.readonlyTooltip = 'This job has been deleted';
    } else if (isJobArchived) {
      this.readonlyTooltip = 'This job has been archived';
    } else if (isJobShared) {
      this.readonlyTooltip = `This job is shared, it can only be edited from it's orginal zone`;
    }
  }

  getActiveIndex(){
    return this.job?.events?.findIndex((e) => e.id === this.activeEventId);
  }

  refreshInventories() {
    this.inventoryRef.fetchInventory();
  }

  getStartAndEndLocations() {
    if (!this.job?.locations.length) return;

    this.jobLocations.otherStops = [];  
  
    // Use a single iteration to categorize and format locations
    this.job.locations.forEach(locationObject => {
      const { locationType, location } = locationObject;
      if (['start', 'end', 'dock'].includes(locationType)) {
        const addressString = formatLocationToDisplay(locationObject.location);
        this.jobLocations[locationType].location = location;
        this.jobLocations[locationType].addressString = addressString;

      } else {
        this.jobLocations.otherStops.push({
          ...locationObject,
          addressString: formatLocationToDisplay(locationObject.location)
        });
      }
    });

  }

  // TODO: Move these utility functions to a service to avoid the need for wrapper functions to call them in a template.
  showGoogleMapsRoute() {
    return window.open(showGoogleMapsRoute(this.job as FullJobFragment), '_blank');
  }

  viewLocationOnGoogleMaps(location: BaseLocationFragment) {
    return viewLocationOnGoogleMaps(location);
  }
}
