import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';

import { FreyaCommonModule } from '../../../freya-common/freya-common.module';


import { SharedModule } from '../../../shared/shared.module';

import { quickAccessFeature } from './quick-access-reducers';
import { QuickAccessActions } from './quick-access.actions';

@Component({
  selector: 'app-quick-access',
  standalone: true,
  imports: [
    FreyaCommonModule,
    CommonModule,
    SharedModule,
  ],
  templateUrl: './quick-access.component.html',
  styleUrl: './quick-access.component.scss'
})
export class QuickAccessComponent implements OnInit {

  public QuickAccessActions = QuickAccessActions;


  pinnedJobs$ = this.store.select(quickAccessFeature.selectPinnedJobs);
  recentJobs$ = this.store.select(quickAccessFeature.selectRecentJobs);
  todaysJobs$ = this.store.select(quickAccessFeature.selectTodaysJobs);
  loading$ = this.store.select(quickAccessFeature.selectLoading);
  expanded$ = this.store.select(quickAccessFeature.selectExpanded);
  jobsWithUnsavedChanges$ = this.store.select(quickAccessFeature.selectJobsWithUnsavedChanges);


  constructor(
    public store: Store,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(QuickAccessActions.initialized());
  }

}
