import { CDK_DRAG_CONFIG, CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { RoleService, dayjs } from '@karve.it/core';
import { AssetService, CalendarEventService, LocationService } from '@karve.it/features';
import { Asset, ListAssetsOutput } from '@karve.it/interfaces/assets';
import { Attendee, CalendarEvent, EditCalendarEventInput, EditCalendarEventLocationInput } from '@karve.it/interfaces/calendarEvents';
import { ZoneDir } from '@karve.it/interfaces/common.gql';
import { CalendarEventLocationInput } from '@karve.it/interfaces/find';
import { QueryRef } from 'apollo-angular';

import { cloneDeep, isEqual } from 'lodash';
import { filter, map } from 'rxjs/operators';
import { ATTRIBUTES, EventAttendeeRoles, JOB_EVENT_STATUSES, JOB_EVENT_TYPES, LOCATION_TYPES, eventAttendeeRolesArray, eventTypeTagStatuses } from 'src/app/global.constants';
import { DetailsHelperService } from 'src/app/services/details-helper.service';
import { EstimateHelperService } from 'src/app/services/estimate-helper.service';
import { FreyaHelperService } from 'src/app/services/freya-helper.service';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { GoogleHelperService } from 'src/app/services/google-helper.service';
import { SubSink } from 'subsink';

import { JobLocation } from '../../estimates/job-info/job-info.component';
import { TimezoneHelperService } from '../../services/timezone-helper.service';
import { DateFormat, TimeFormat } from '../../time';

import { MutateObjectComponent, MutateObjectElement } from '../mutate-object/mutate-object.component';

// This allows the cdk-drag-preview to appear on top of the modal instead of being hidden
// eslint-disable-next-line @typescript-eslint/naming-convention
const DRAG_CONFIG = {
  dragStartThreshold: 0,
  pointerDirectionChangeThreshold: 5,
  zIndex: 10000
};

@Component({
  selector: 'app-mutate-calendar-event',
  templateUrl: './mutate-calendar-event.component.html',
  styleUrls: ['./mutate-calendar-event.component.scss'],
  providers: [{ provide: CDK_DRAG_CONFIG, useValue: DRAG_CONFIG }]
})
export class MutateCalendarEventComponent implements OnInit, OnDestroy {

  @ViewChild('mutate') mutateRef: MutateObjectComponent;

  @ViewChild('title') titleRef: TemplateRef<any>;
  @ViewChild('start') startRef: TemplateRef<any>;
  @ViewChild('duration') durationRef: TemplateRef<any>;
  @ViewChild('status') statusRef: TemplateRef<any>;
  @ViewChild('type') typeRef: TemplateRef<any>;
  @ViewChild('assets') assetsRef: TemplateRef<any>;
  @ViewChild('locations') locationsRef: TemplateRef<any>;
  @ViewChild('attendees') attendeesRef: TemplateRef<any>;

  @ViewChild('startReview') startReviewRef: TemplateRef<any>;
  @ViewChild('durationReview') durationReviewRef: TemplateRef<any>;

  @Output() eventMutated = new EventEmitter();

  @Input() mutateType: 'update' | 'create';
  @Input() event: CalendarEvent;

  steps: MutateObjectElement[];

  subs = new SubSink();

  // Create User Variables
  showDialog = false;

  eventForm = new UntypedFormGroup({
    title: new UntypedFormControl(undefined, [Validators.required]),
    start: new UntypedFormControl(undefined, [Validators.required]),
    duration: new UntypedFormControl(undefined, [Validators.required]),
    status: new UntypedFormControl(undefined, [ Validators.required ]),
    type: new UntypedFormControl(undefined, [Validators.required]),
    assets: new UntypedFormControl([], []),
    locations: new UntypedFormControl([], []),
    attendees: new UntypedFormControl([], []),
  });

  attendeeRoles = new Set(eventAttendeeRolesArray);
  attendeeRolesArray = eventAttendeeRolesArray;

  addAttendeeForm = new UntypedFormGroup({
    user: new UntypedFormControl(undefined, [Validators.required]),
    role: new UntypedFormControl(undefined, [Validators.required]),
  });

  userSearchOptions = {};

  eventFormValues = this.eventForm.value;

  jobEventTypes = JOB_EVENT_TYPES;

  locationTypes = LOCATION_TYPES;

  statuses = JOB_EVENT_STATUSES.map((s) => ({
    label: eventTypeTagStatuses[s],
    value: s,
  }));

  dateFormat: DateFormat = 'mm/dd/yy';
  timeFormat: TimeFormat = 12;

  // ADDING LOCATIONS
  locationsToAdd: JobLocation[] = [];
  unaddableLocationIds: string[] = [];

  formattedLockDate = 'No Lock Date';

  get dayJsDateFormat() {
    switch (this.dateFormat) {
      case 'dd/mm/yy':
        return 'DD/MM/YYYY';
      case 'mm/dd/yy':
      default:
        return 'MM/DD/YYYY';
    }
  }

  get dayJsTimeFormat() {
    switch (this.timeFormat) {
      case 24:
        return `HH:mm`;
      case 12:
      default:
        return `hh:mm A`;
    }
  }

  get dayJsFormat() {
    return `${ this.dayJsDateFormat } ${ this.dayJsTimeFormat }`;
  }

  newLocationForm = new UntypedFormGroup({
    address: new UntypedFormControl(undefined, [Validators.required]),
    type: new UntypedFormControl(undefined, [Validators.required]),
    estimatedTimeAtLocation: new UntypedFormControl({value: 0, disabled: true}),
    travelTimeToNextLocationOffset: new UntypedFormControl(undefined),

    // Set Based on Google
    areaCode: new UntypedFormControl(undefined, [Validators.required]),
    country: new UntypedFormControl('', []),
    city: new UntypedFormControl(undefined, []),
    jurisdiction: new UntypedFormControl(undefined, []),
  });

  showNewLocation = false; // Whether the user can see the input for a new location

  // Asset Variables
  assetQueryRef: QueryRef<ListAssetsOutput>;
  assetOptions: Asset[] = [];

  googleOptions = {
    componentRestrictions: {
      country: ['CA', 'US']
    }
  };

  constructor(
    private assetService: AssetService,
    private eventService: CalendarEventService,
    public freyaHelperService: FreyaHelperService,
    private localNotify: FreyaNotificationsService,
    private googleHelper: GoogleHelperService,
    private locationService: LocationService,
    private detailsHelper: DetailsHelperService,
    private estimateHelper: EstimateHelperService,
    private timeZoneHelper: TimezoneHelperService,
    private freyaHelper: FreyaHelperService,
    private roleService: RoleService,
  ) { }

  ngOnInit(): void {
    this.roleService
      .listRoles({ zoneDir: ZoneDir.any, limit: -1 })
      .pipe(
        filter((res) => !res.loading),
        map((res) => res?.data?.roles?.reduce((ids, role) => {
          if (!role.name.includes('Customer')) {
            ids.push(role.id);
          }
          return ids;
        }, [])),
      )
      .subscribe(
        (res) => {
          this.userSearchOptions = { roles: res };
        },
        (err) => {
          console.error(err);
        }
      );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  mutateObject() {
    if (this.mutateType === 'create') {
      // Create
    } else if (this.mutateType === 'update') {
      this.updateCalendarEvent();
    }
  }

  openDialog() {
    this.findAssets();

    this.formattedLockDate = this.freyaHelperService.getFormattedLockDate();

    this.steps = [
      { name: 'Title', ref: this.titleRef, control: 'title', type: 'text', },
      { name: 'Start', ref: this.startRef, control: 'start', type: 'complex', reviewRef: this.startReviewRef },
      { name: 'Duration', ref: this.durationRef, control: 'duration', type: 'complex', reviewRef: this.durationReviewRef },
      { name: 'Status', ref: this.statusRef, control: 'status', type: 'func',
        reviewFunc: () => eventTypeTagStatuses[this.eventForm.value.status]},
      { name: 'Assets', ref: this.assetsRef, control: 'assets', type: 'array' },
      { name: 'Locations', ref: this.locationsRef, control: 'locations', type: 'array' },
      { name: 'Attendees', ref: this.attendeesRef, control: 'attendees', type: 'array' },
    ];

    if (this.mutateType === 'create') {
      // Assign Steps
    } else if (this.mutateType === 'update') {
      this.setFormValues();
      if (!this.event?.start){
        this.steps[1].removed = true;
        this.steps[2].removed = true;
      }
    }

    this.setUnaddableLocations();

    this.mutateRef.steps = this.steps;
    this.mutateRef.openDialog();
  }

  setFormValues() {
    /**
     * Parse the current time into a date string for use by
     * the primeng calendar component. We are trying to avoid timezones here because
     * the calendar is technically timezone agnostic. This is also why we aren't using the "Date"
     * object, because it can lead to strange bugs when trying to convert timezones and so it's
     * simplist to avoid using it altogether and not have to think about implementation.
     */

    const timezone = this.timeZoneHelper.getCurrentTimezone();
    const dayJsStart = dayjs.tz(this.event.start * 1000, timezone);
    const start = dayJsStart.format(this.dayJsFormat);
    const duration = this.event.end - this.event.start;

    this.eventForm.patchValue({
      title: this.event.title,
      start,
      duration: (duration / 3600).toFixed(2),
      status: this.event.status,
      type: this.event.type,
      assets: this.event.assets,
      locations: this.event.locations,
      attendees: this.event?.attendees || [],
    });

    this.eventForm.markAsPristine();

    this.eventFormValues = cloneDeep(this.eventForm.value);

    this.removeAssignedAttendeeRoles(this.event?.attendees?.map((u) => u.role));
  }


  /**
   * Checks whether the user has changed the start time or duration,
   * and returns the updated times if they have.
   */
  getUpdatedTimes() {
    if (this.eventForm.controls.start.pristine && this.eventForm.controls.duration.pristine) {
      return {};
    }
    const { unixStart: start, unixEnd: end } = this.getUnixTimes();
    return { start, end };
  }

  /**
   * Parse the eventForm to retrieve unix start and end times
   */
  getUnixTimes() {
    const val = this.eventForm.value;

    if (!val.start) {
      return {};
    }

    /**
     * Parse the "start" property in the "dayJsFormat" taken from the primeng calendar component
     * into a dayJs object with the correct timezone.
     * The start can then be easily converted into unix seconds.
     */
    const dayJsStart = dayjs(val.start, this.dayJsFormat).tz(
      this.timeZoneHelper.getCurrentTimezone(),
      true,
    );
    const unixStart = dayJsStart.unix();

    const duration = val.duration || 0;
    const dayJsEnd = dayjs
      .tz(unixStart * 1000, this.timeZoneHelper.getCurrentTimezone())
      .add(duration, 'hours');

    const unixEnd = dayJsEnd.unix();

    return {
      unixStart,
      unixEnd,
      sameDay: dayJsStart.isSame(dayJsEnd, 'day'),
    };
  }

  async updateCalendarEvent() {
    const val = this.eventForm.value;
    const { start, end } = this.getUpdatedTimes();

    const { added: addAssets, removed: removeAssets } = this.freyaHelperService.getAddedAndRemoved(
      this.event.assets, val.assets, true
    );

    // eslint-disable-next-line max-len
    const { added: addLocations, removed: removeLocations } = this.freyaHelperService.getAddedAndRemoved(
      this.event.locations, val.locations, false
    );

    // Calculate Event Attendees
    let { added: attendeesToAdd, removed: attendeesToRemove }
      = this.freyaHelper.getAddedAndRemoved(this.event.attendees, this.eventForm.value.attendees, false);

    attendeesToAdd = attendeesToAdd.map((u) => ({ role: u.role, userId: u.user.id }));

    attendeesToRemove = attendeesToRemove.map((u) => ({ role: u.role, userId: u.user.id }));

    // if (addLocations?.length){
    //   const input = {
    //     locations: addLocations.map((l) => ({
    //       addressLineOne: l.location.addressLineOne,
    //       areaCode: l.location.areaCode,
    //       city: l.location.city,
    //       country: l.location.country,
    //       countryJurisdiction: l.location.jurisdiction,
    //     }))
    //   } as CreateLocationsInput;
    //   await new Promise ((resolve, reject) => {
    //     this.locationService.CreateLocations({input}).subscribe((res) => {
    //       for(const local of addLocations){ // Map the ids of the created locations to the inputs
    //         local.location.id = res.data.createLocations.locations.find((l) => local.location.addressLineOne === l.addressLineOne).id;
    //       }
    //       resolve(true);
    //     }, (err) => {
    //       reject(err);
    //     });
    //   });
    //   this.eventMutated.emit(true);
    // }

    const title = this.eventForm.controls.title.dirty ? val.title : undefined;

    const status = this.eventForm.controls.status.dirty ? val.status : undefined;

    let attributes = this.event.attributes || undefined;
    if (this.eventForm.controls.duration.dirty && attributes) {
      // duration was changed
      attributes = this.freyaHelperService.addAttribute(this.event.attributes, ATTRIBUTES.manualDuration);
    }

    const updateEventInput = {
      ids: [this.event.id],
      edit: {
        title,
        start,
        status,
        end,
        setAssets: {
          addAssets: addAssets?.map((a) => a.id) || [],
          removeAssets,
        },
        setLocations: {
          addLocations: this.generateAddLocations(addLocations),
          editLocations: this.generateEditLocations(),
          removeLocations: removeLocations?.map((rl) => rl.id) || [],
        },
        setAttendees: {
          addAttendees: attendeesToAdd,
          removeAttendees: attendeesToRemove,
        },
        attributes,
      }
    } as EditCalendarEventInput;

    this.subs.sink = this.eventService.editCalendarEvent(updateEventInput).subscribe((res) => {
      this.detailsHelper.pushUpdate({
        id:this.event.id,
        type:'Events',
        action:'update'
      });
      this.mutateRef.closeDialog();
      this.localNotify.success('Event updated');
    }, (err) => {
      this.mutateRef.loading = false;
      this.localNotify.apolloError(`Failed to update event`,err);
    });
  }

  // Generates the input for added locations
  generateAddLocations(added) {
    const addedLocations: CalendarEventLocationInput[] = [];

    for (const l of added) {
      const location = {
        locationId: l.location.id,
        type: l.type,
        ignoreInDuration: true,
        order: l.order,
      } as CalendarEventLocationInput;
      addedLocations.push(location);
    }

    return addedLocations;
  }

  // Generates the input for edited locations
  generateEditLocations() {
    const editLocations: EditCalendarEventLocationInput[] = [];

    for (const location of this.eventForm.value.locations) {
      const preexistingLocation = this.eventFormValues.locations.find((l) => l.id === location.id);

      // Skip any locations not pre-existing, those will be handled by add
      if (!preexistingLocation) { continue; }

      const locationEdited = !isEqual(location, preexistingLocation);

      if (!locationEdited) { continue; }

      const editedLocation = {
        id: location.id,
        order: location.order,
        estimatedTimeAtLocation: location.estimatedTimeAtLocation,
        travelTimeToNextLocationOffset: location.travelTimeToNextLocationOffset,
        type: location.type,
        ignoreInDuration: location.ignoreInDuration,
      } as EditCalendarEventLocationInput;

      editLocations.push(editedLocation);
    }

    return editLocations;
  }

  showLocationTemplate() {
    this.showNewLocation = true;
  }

  // addNewLocation(){
  //   const currentValue = this.eventForm.value.locations;

  //   const val = this.newLocationForm.value;

  //   // Format new address to match the locations array
  //   const newValue = {
  //     location: {
  //       addressLineOne: val.address,
  //       areaCode: val.areaCode,
  //       country: val.country,
  //       city: val.city,
  //       jurisdiction: val.jurisdiction,
  //     },
  //     type: val.type,
  //     estimatedTimeAtLocation: val.estimatedTimeAtLocation,
  //     travelTimeToNextLocationOffset: val.travelTimeToNextLocationOffset,
  //   };

  //   this.eventForm.controls.locations.setValue([...currentValue, newValue]);
  //   this.showNewLocation = false;
  // }

  removeLocation(location) {
    const afterRemoving = this.eventForm.value.locations.filter((l) => !(l.id === location.id && l.type === location.type));

    this.eventForm.controls.locations.setValue(afterRemoving);
  }

  // Asset Methods
  findAssets() {
    if (this.assetQueryRef) {
      // TODO: IRON this out
      this.assetQueryRef.setVariables({ filter: {} });
    } else {
      this.assetQueryRef = this.assetService.watchAssets({ filter: {} }, {});

      this.subs.sink = this.assetQueryRef.valueChanges.subscribe((res) => {
        this.assetOptions = res.data.assets.assets;
      });
    }
  }

  unselectAsset(asset: Asset) {
    const newValue = this.eventForm.value.assets.filter((a) => a.id !== asset.id);
    this.eventForm.controls.assets.setValue(newValue);
  }

  addLocationsToEvent(){
    const valuesToAdd = this.locationsToAdd.map((l) => (
      {
        location: l.location,
        type: l.locationType,
        estimatedTimeAtLocation: 0,
        travelTimeToNextLocationOffset: 0,
        order: this.eventForm.value.locations.length,
      }
    ));

    for(const val of valuesToAdd){
      if (val.type !== 'dock'){
        continue;
      }

      if (this.eventForm.controls.locations.value.find((l) => l.type === 'basestart')){
        val.type = 'baseend';
      } else {
        val.type = 'basestart';
      }
    }

    this.eventForm.controls.locations.setValue([...this.eventForm.value.locations, ...valuesToAdd]);

    this.locationsToAdd = [];

    this.setUnaddableLocations();
  }

  setUnaddableLocations() {
    const locations = this.eventForm.value.locations;
    console.log(locations);

    const unaddableLocations = [];
    unaddableLocations.push(...locations.filter((l) => l.type !== 'basestart' && l.type !== 'baseend'));

    const hasBaseStart = locations.find((l) => l.type === 'basestart');
    const hasBaseEnd = locations.find((l) => l.type === 'baseend');

    if (hasBaseStart && hasBaseEnd) {
      unaddableLocations.push(locations.find((l) => l.type === 'basestart'));
    }

    this.unaddableLocationIds = unaddableLocations.map((l) => l.location?.id);
    console.log(this.unaddableLocationIds);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.eventForm.value.locations, event.previousIndex, event.currentIndex);

    for (const [index, location] of this.eventForm.value.locations.entries()) {
      location.order = index;
    }
  }

  addAttendee() {
    const currentValues = this.eventForm.controls.attendees.value;
    this.eventForm.controls.attendees.setValue(
      [
        ...currentValues,
        {
          user: this.addAttendeeForm.value.user,
          role: this.addAttendeeForm.value.role,
        }
      ]
    );

    // Update the job roles array to prevent the role from being added again
    this.updateAttendeeRolesOptions(this.addAttendeeForm.value.role, 'remove');
    this.addAttendeeForm.reset();
  }

  removeAttendee(attendee: Attendee) {
    if(attendee.role === EventAttendeeRoles.author) {
      this.localNotify.addToast.next({ severity: 'error', summary: 'The author role cannot be removed once assigned.' });
      return;
    }
    const without = this.eventForm.value.attendees.filter((u: Attendee) => !(u.user.id === attendee.user.id && u.role === attendee.role));
    this.eventForm.controls.attendees.setValue(without);
    // Update the job roles array to allow the role to be added again
    this.updateAttendeeRolesOptions(attendee.role, 'add');
  }

  // While pre-loading form values, we need to update the userJobRoles array to allow unique staff roles (crew lead, member, author etc.)
  removeAssignedAttendeeRoles(roles: string[]) {
    this.attendeeRoles = new Set(eventAttendeeRolesArray);
    roles?.forEach((role) =>
      this.updateAttendeeRolesOptions(role, 'remove')
    );
  }

  updateAttendeeRolesOptions(role: string, operation: 'add' | 'remove') {
    if (!operation || !role) {
      return;
    }

    // There can be multiple crew members, so we don't want to remove the role from the array
    if (role === EventAttendeeRoles.crewMember) {
      return;
    }


    if (operation === 'add') {
      this.attendeeRoles.add(role as EventAttendeeRoles);
    } else if (operation === 'remove') {
      this.attendeeRoles.delete(role as EventAttendeeRoles);
    }

    this.attendeeRolesArray = [...this.attendeeRoles];

  }

  // setRoleId(event) {
  //   this.addAttendeeForm.patchValue({ role: event.value });

  //   // Set the user search options for user-search component based on the selected role
  //   if (event.value === JOB_ROLE_MAP.customerRole) {
  //     this.userSearchOptions = { roles: this.customerRoleIds };
  //   } else {
  //     this.userSearchOptions = { roles: this.staffRoleIds };
  //   }
  // }
}
