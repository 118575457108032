import { Component, Input, OnChanges, OnInit } from '@angular/core';

import { BaseLocationFragment, FullJobFragment } from '../../../generated/graphql.generated';
import { showGoogleMapsRoute, viewLocationOnGoogleMaps } from '../../utilities/locations.util';

@Component({
  selector: 'app-job-locations',
  templateUrl: './job-locations.component.html',
  styleUrls: ['./job-locations.component.scss']
})
export class JobLocationsComponent implements OnInit, OnChanges {

  @Input() job: FullJobFragment;

  // Set to true to see the 'View Route' button
  @Input() showRoute = false;

  // If True will show all locations on the job, even if they are not in showLocationTypes
  @Input() showAll = false;

  // Shows these locations even if they are not set
  @Input() showLocationTypes: string[] = ['dock', 'start', 'end'];

  locationsToShow: FullJobFragment['locations'];

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.locationsToShow = this.getLocationsToShow();
  }

  getLocationsToShow() {
    if (this.showAll) {
      return this.job?.locations;
    }
    return this.job?.locations.filter((l) => this.showLocationTypes.includes(l.locationType));
  }

  viewLocationOnGoogleMaps(location: BaseLocationFragment) {
    return viewLocationOnGoogleMaps(location);
  }

  showGoogleMapsRoute(){
    return showGoogleMapsRoute(this.job);
  }
}
