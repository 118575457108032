import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';

import { DividerModule } from 'primeng/divider';

import { jobToolFeature } from '../job-tool.reducer';

import { JobTimelinePipe } from './job-timeline.pipe';
import { OverviewCustomerComponent } from "./overview-customer/overview-customer.component";
import { OverviewHeaderComponent } from "./overview-header/overview-header.component";
import { OverviewLocationsComponent } from './overview-locations/overview-locations.component';
import { OverviewSummaryComponent } from './overview-summary/overview-summary.component';
import { OverviewTimelineComponent } from "./overview-timeline/overview-timeline.component";

@Component({
  selector: 'app-job-overview',
  standalone: true,
  imports: [
    CommonModule,
    DividerModule,
    DividerModule,
    JobTimelinePipe,
    OverviewHeaderComponent,
    OverviewCustomerComponent,
    OverviewLocationsComponent,
    OverviewTimelineComponent,
    OverviewSummaryComponent
  ],
  templateUrl: './job-overview.component.html',
  styleUrls: ['./job-overview.component.scss'],
})
export class JobOverviewComponent implements OnInit {

  job$ = this.store.select(jobToolFeature.selectJob);
  customer$ = this.store.select(jobToolFeature.selectCustomer);
  customerName$ = this.store.select(jobToolFeature.selectCustomerName);
  fields$ = this.store.select(jobToolFeature.selectFieldsInput);

  showMap = false;

  constructor(
    public store: Store,
  ) { }

  ngOnInit(): void {
  }

}
