import { inject } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { JobCreateActions } from "./jobv2-create/jobv2-create-state/jobv2-create.actions";
import { JobToolActions } from "./job-tool.actions";
import { distinctUntilChanged, filter, map, of, withLatestFrom } from "rxjs";
import { CONFIGS_KEYS } from "../global.constants";
import { Store } from "@ngrx/store";
import { jobToolFeature } from "./job-tool.reducer";
import { FreyaHelperService } from "../services/freya-helper.service";
import { CalendarEventWithLockedAndInvoicedFlag } from "./jobv2-create/jobv2-interfaces";
import { isFinalizedInvoice } from "../invoices/invoices.utils";
import { WorkOrdersActions } from "./job-state/workorders-state/workorders.actions";


//TO DO see how to optimize it for job-create tool edit mode,
//as we get there from job overview page and can avoid loading configs twice
export const loadJobConfigsEffect = createEffect(() => {
   const actions$ = inject(Actions);


   return actions$.pipe(
     ofType(
       JobCreateActions.initCreateJobTool,
       JobCreateActions.changeZone,
       JobToolActions.initJobOverviewPage
   ),
     map(() =>
       JobToolActions.loadJobConfigs({
         jobConfigsKeys: [
           CONFIGS_KEYS.howHeardMandatory,
           CONFIGS_KEYS.howHeardOptions,
           CONFIGS_KEYS.inventoryItems,
           CONFIGS_KEYS.closedReasons,
           CONFIGS_KEYS.roleCustomer,
           CONFIGS_KEYS.lockDate,
         ],
       })
     )
   );
 }, { functional: true, dispatch: true });


export const trackLockedAndInvoicedEventsEffect = createEffect(() => {
  const actions$ = inject(Actions);
  const store = inject(Store);
  const freyaHelper = inject(FreyaHelperService);

  return actions$.pipe(
    ofType(JobToolActions.jobLoaded),
    withLatestFrom(store.select(jobToolFeature.selectJob)),
    filter(([_, job]) => job && job.events && job.events.length > 0),
    map(([_, job]) => {
      const updatedEvents = job.events.map(event => {
        // Check if the event should be marked as locked
        const locked = event.end && freyaHelper.lockDate > event.end;

        // Check if the event should be marked as invoiced
        const invoiced = event.invoices?.some(isFinalizedInvoice);

        return {
          ...event,
          ...(locked ? { locked: true } : {}),
          ...(invoiced ? { invoiced: true } : {})
        };
      });

      return JobToolActions.addLockedAndInvoicedFlagToEvents({
        events: updatedEvents as CalendarEventWithLockedAndInvoicedFlag[]
      });
    })
  );
}, { functional: true, dispatch: true });

//load products, discounts, expenses and taxes every time when state?.job?.id changed
export const triggerLoadProductsOnZoneChangeEffect = createEffect(() => {
  const store = inject(Store);

  return store.select(state => state?.job?.zone?.id).pipe(
    distinctUntilChanged(),
    map(() => WorkOrdersActions.productsLoading())
  );
}, { functional: true, dispatch: true });
