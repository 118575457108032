<p-multiSelect
    appendTo="body"
    [placeholder]="placeholder"
    [options]="eventSuggestions"
    [(ngModel)]="selectedEvents"
    (ngModelChange)="onChange(selectedEvents)"
    [dataKey]="'id'"
    (onChange)="selectEvent()"
    (focus)="touched = true"
    [autofocusFilter]="!responsiveHelper.isSmallScreen"
    optionDisabled="disabled"
    optionValue="event"
>
    <ng-template let-suggestion pTemplate="item">
        <div>
            <span>{{suggestion.event.title}} ({{suggestion.event.status | titlecase}})</span>
            <p-tag *ngIf="suggestion.invoiceStatus === 'finalized'" class="p-ml-2" value="Invoiced" severity="success"></p-tag>
            <p-tag *ngIf="suggestion.invoiceStatus === 'draft'" class="p-ml-2" value="Invoice drafted"></p-tag>
            <br>
            <small *ngIf="suggestion.event?.start">{{(suggestion.event.start * 1000) | date}}</small>
            <small *ngIf="!suggestion.event?.start">Unscheduled</small>
        </div>
    </ng-template>
</p-multiSelect>

<ng-template #eventInfo>
</ng-template>