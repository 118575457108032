import { inject } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router, RouterStateSnapshot } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { isEmpty } from 'lodash';
import { DialogService } from 'primeng/dynamicdialog';
import {
  catchError,
  defer,
  delay,
  exhaustMap,
  filter,
  forkJoin,
  from,
  iif,
  map,
  mergeMap,
  of,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs';

import {
  Asset,
  AvailableZonesGQL,
  BaseZoneFragment,
  BulkEditCalendarEventGQL,
  BulkEditCalendarEventMutationVariables,
  CalculateDistanceGQL,
  CalendarEvent,
  CalendarEventLocation,
  CalendarEventLocationInput,
  CreateCalendarEventGQL,
  CreateCommentGQL,
  DeleteCommentsGQL,
  DeleteJobGQL,
  EditProfileGQL,
  InvoiceDetailsGQL,
  JobsV2PageGQL,
  JobsV2PageQueryVariables,
  ListBaseAssetsGQL,
  ListBaseAssetsQueryVariables,
  ListCommentsWithRepliesGQL,
  ListUsersGQL,
  ListUsersQueryVariables,
  RemoveCalendarEventGQL,
  SetTagsOnObjectGQL,
  SetTagsOnObjectMutationVariables,
  SingleEditInput,
  TagsGQL,
  TagsQueryVariables,
  UpdateCommentsGQL,
  UpdateJobGQL,
  UpdateJobInput,
  User
} from '../../generated/graphql.generated';

import { generateMutationContext } from '../core/auth/session';
import { PlusAuthenticationService } from '../core/public-api';
import { EventLocationInfo } from '../estimates/estimates.component';
import { CREW_ROLE_ATTRIBUTE, EMPLOYEE_ROLE_ATTRIBUTE, JOB_FORM_FIELDS } from '../global.constants';
import { TAG_TYPES } from '../interfaces/tag';
import { FreyaHelperService } from '../services/freya-helper.service';

import { FreyaNotificationsService } from '../services/freya-notifications.service';

import { ResponsiveHelperService } from '../services/responsive-helper.service';
import { UpdateEventLocationsDialogComponent } from '../shared/update-event-locations-dialog/update-event-locations-dialog.component';


import { sortCommentsWithReplies } from './job-activity/comments.utils';
import { JobToolSubscriptionActions } from './job-state/job-tool-subscription.actions';
import { JobToolActions } from './job-tool.actions';
import {
  DetailedLocation,
  jobToolFeature,
  UserWithName,
} from './job-tool.reducer';
import { extractFieldsFromResponse, filterLocationsForAddAndRemove, generateFieldsInputFromLatestChanges, generateInventoryInputFromLatestChanges, generateJobCreateVariables, generateLocationsInputsFromLatestChanges, generateUpdateCustomerInput } from './jobsv2-helpers';
import { selectLatestInvoiceId } from './jobv2-create/jobv2-create-state/jobv2-create.selectors';

const mapJobUsers = (users: UserWithName[]) =>
  users.map((user) => ({
    role: user.role,
    userId: user.id,
  }));

export const addComment = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    createCommentGQL = inject(CreateCommentGQL),
  ) => {
    return actions$.pipe(
      ofType(JobToolActions.addComment),
      withLatestFrom(store.select(jobToolFeature.selectZone)),
      exhaustMap(([{ input, temporaryCommentId }, zone]) => {
        return createCommentGQL
          .mutate(
            {
              input,
            },
            {
              context: generateMutationContext({
                zone: zone?.id,
                actionId: temporaryCommentId,
              }),
            },
          )
          .pipe(
            map((result) =>
              JobToolActions.addCommentSuccess({
                comment: result.data?.addComment,
                commentId: temporaryCommentId,
              }),
            ),
            catchError((error) =>
              of(
                JobToolActions.addCommentError({
                  error,
                  input,
                  commentId: temporaryCommentId,
                }),
              ),
            ),
          );
      }),
    );
  },
  { functional: true },
);

export const updateComment = createEffect(
  (
    actions$ = inject(Actions),
    updateCommentGQL = inject(UpdateCommentsGQL),
  ) => {
    return actions$.pipe(
      ofType(JobToolActions.updateComment),
      exhaustMap(({ input }) => {
        return updateCommentGQL
          .mutate({
            input,
          })
          .pipe(
            map((result) => {
              if (!result.data?.updateComments?.length) {
                return JobToolActions.updateCommentError({
                  error: new Error(`Comment not found, cannot update`),
                  input,
                });
              }

              return JobToolActions.updateCommentSuccess({
                comment: result.data?.updateComments[0],
              });
            }),
            catchError((error) =>
              of(
                JobToolActions.updateCommentError({
                  error,
                  input,
                }),
              ),
            ),
          );
      }),
    );
  },
  { functional: true },
);

export const deleteComment = createEffect(
  (
    actions$ = inject(Actions),
    deleteCommentGQL = inject(DeleteCommentsGQL),
  ) => {
    return actions$.pipe(
      ofType(JobToolActions.deleteComment),
      exhaustMap(({ comment }) => {
        return deleteCommentGQL
          .mutate({
            input: {
              ids: [comment.id],
            },
          })
          .pipe(
            map((result) => {
              if (!result.data?.deleteComments?.commentsDeleted?.length) {
                return JobToolActions.deleteCommentError({
                  comment,
                  error: new Error(`Comment not found, cannot delete`),
                });
              }

              return JobToolActions.deleteCommentSuccess({
                comment,
              });
            }),
            catchError((error) =>
              of(
                JobToolActions.deleteCommentError({
                  error,
                  comment,
                }),
              ),
            ),
          );
      }),
    );
  },
  { functional: true },
);

/**
 * Called when we add a remote comment to load more info like
 * the author name
 */
export const loadComment = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    listCommentWithRepliesGQL = inject(ListCommentsWithRepliesGQL),
  ) => {
    return actions$.pipe(
      ofType(JobToolSubscriptionActions.remoteCommentAdded),

      // we delay so that we can wait for the add comment query
      // to finish... I dont like this
      delay(300),
      withLatestFrom(store.select(jobToolFeature.selectComments)),
      exhaustMap(([{ input, output }, comments]) => {
        // does the comment already exist?
        // const { comment } = findComment(comments, output.id);
        // if (comment) {
        // 	return of(undefined);
        // }

        return listCommentWithRepliesGQL
          .fetch({
            filter: {
              ids: [output.id],
              objectType: 'Job',
              parentsOnly: false,
              // objectIds: [],
            },
          })
          .pipe(
            map((result) => {
              const [comment] = result.data?.comments?.comments || [];
              if (!comment) {
                // some very weird remote error happened
                return undefined;
              }

              console.log(`Comment loaded after remote retrieval`);

              return JobToolActions.commentLoaded({
                comment,
              });
            }),
            // TODO: We need a better remote error
            catchError((error) =>
              of(
                JobToolActions.commentLoadError({
                  error,
                  commentId: output.id,
                }),
              ),
            ),
          );
      }),
    );
  },
  { functional: true },
);

export const tabChanged = createEffect(
  (actions$ = inject(Actions)) => {
    const router = inject(Router);
    const route = inject(ActivatedRoute);
    const store = inject(Store);
    return actions$.pipe(
      ofType(JobToolActions.tabChanged),
      concatLatestFrom((action) => store.select(jobToolFeature.selectJobId)),
      tap(([{ name }, jobId]) => {
        router.navigate(['/jobs/', jobId, name], {
          relativeTo: route,
          queryParams: route.snapshot.queryParams,
        });
      }),
    );
  },
  { functional: true, dispatch: false },
);

export const getJobFromParams = createEffect(
  (
    actions$ = inject(Actions),
    jobsV2GQL = inject(JobsV2PageGQL),
    plusAuthService = inject(PlusAuthenticationService),
    store = inject(Store),
  ) => {
    return actions$.pipe(
      ofType(
        JobToolActions.paramsSet,
        JobToolActions.updateJobAreaSuccess,
      ),
      exhaustMap(({ jobId }) => {

        const variables: JobsV2PageQueryVariables = {
          jobId,
          resolve: ['discounts', 'users', 'locations', 'distances'],
          objects: [jobId],
        };

        return jobsV2GQL
          .fetch(variables, {
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
          })
          .pipe(
            mergeMap((res) => {
              if (res.loading || !res?.data) {
                return of(JobToolActions.jobLoading());
              }

              const { jobs, comments } = res.data;
              if (!jobs.jobs?.length) {
                return of(JobToolActions.jobNotFoundError({
                  jobId,
                }));
              }

              // Automatically context into the 
              const job = jobs.jobs[0];
              let zone = job.zone;
              if (zone.type === 'area') {
                zone = zone.parent;
              }

              const shouldChangeZones = zone && zone.type !== 'area' && zone.id !== plusAuthService.contextedZoneId;
              // Use `iif` to conditionally call `setContext`
              return iif(
                () => shouldChangeZones,
                defer(() => from(plusAuthService.setContext(zone.id))), // Use defer to delay the creation of the observable
                of(null) // Otherwise, skip `setContext`
              ).pipe(
                switchMap(() => {
                  return of(JobToolActions.jobLoaded({
                    job,
                    comments: sortCommentsWithReplies(comments.comments, false),
                    fields: extractFieldsFromResponse(res.data.fields.fields),
                    totalComments: comments.total,
                  }));
                })
              );

              }),
              catchError((error) => {
                // TODO: fire job load error
                return of(JobToolActions.jobLoadError({
                  jobId, error
                }));
              }),
            );
      }),
    );
  },
  { functional: true, dispatch: true },
);

export const handleJobNotFound = createEffect(
  (
    actions$ = inject(Actions),
    availableZonesGQL = inject(AvailableZonesGQL),
    plusAuthService = inject(PlusAuthenticationService),
    store = inject(Store),
  ) => {
    return actions$.pipe(
      ofType(JobToolActions.jobNotFoundError),
      mergeMap(({ jobId }) => {

        return availableZonesGQL
          .fetch({
            objectId: jobId,
            objectLabel: 'Job',
            limit: 1,
          })
          .pipe(
            withLatestFrom(
                store.select(jobToolFeature.selectUser),
                store.select(jobToolFeature.selectTab),
            ),
            switchMap(([ res, user, tab ]) => {
              if (!res.data.availableZones?.length) {
                return of(JobToolActions.jobLoadError({
                  jobId,
                  error: new Error(`Cannot find job in any zone with id ${ jobId }`)
                }));
              }

              // Call async function here and process `res.availableZones[0].id`
              let zone: BaseZoneFragment = res.data.availableZones[0];
              if (zone.type === 'area' && res.data.availableZones[0].parent) {
                zone = res.data.availableZones[0].parent;
              }

              if (plusAuthService.contextedZoneId === zone.id) {
                return of(JobToolActions.jobLoadError({
                  jobId,
                  error: new Error(
                    `Report this bug: Cannot find job anywhere in currently contexted zone `
                    + `even though it's supposed to be here ${ jobId }`
                  )
                }));
              }

              const newZoneId = zone.id;
              console.log(`Switching to zone for job: ${ newZoneId }`);

              return from(plusAuthService.setContext(newZoneId)).pipe(
                switchMap((res) => {
                  return of(JobToolActions.paramsSet({
                    jobId,
                    tab,
                    user,
                  }));
                })
              )
            }),
            catchError((error) => {
              return of(JobToolActions.jobLoadError({ jobId, error }));
            }),
          );
      }),
    );
  },
  { functional: true, dispatch: true },
);


export const handleJobError = createEffect(
  (
    actions$ = inject(Actions),
    router = inject(Router),
    store = inject(Store),
    notifications = inject(FreyaNotificationsService),
  ) => {
    return actions$.pipe(
      ofType(JobToolActions.jobLoadError),
      tap(({ error }) => {

        router.navigate([ '/jobs' ]);

        notifications.error(`Failed to load job`, error.message);

        return {};

      }),
    );
  },
  { functional: true, dispatch: false },
);

// TODO: [HIGH_PRIORITY] This is a duplicate one in jobsv2-timeline-availability-state.effects.ts
// TODO: [HIGH_PRIORITY] Merge them once the overview-tool and job-creation-tool are merged
export const calculateDistances = createEffect(
  (
    actions$ = inject(Actions),
    calculateDistanceGQL = inject(CalculateDistanceGQL),
    store = inject(Store),
    freyaHelper = inject(FreyaHelperService),
  ) => {
    return actions$.pipe(
      ofType(JobToolActions.jobUpdateSuccess),
      withLatestFrom(store.select(jobToolFeature.selectJobLocations)),
      filter(([jobUpdateInput, _]) => {
        const { locationsToAdd = {}, locationsToRemove = {}} = jobUpdateInput;
        return Object.keys(locationsToAdd).length > 0 || Object.keys(locationsToRemove).length > 0;
      }),
      exhaustMap(([_, jobLocations]) => {
        function getRoutesToCalculate(jobLocations) {
          const { start, end, dock } = jobLocations;

          const routes: { [type: string]: DetailedLocation[] } = {
            start_end: [start, end],
            dock_start_end_dock: [dock, start, end, dock],
            dock_start: [dock, start],
            start_dock: [start, dock],
            end_dock: [end, dock],
          };

          return Object.entries(routes).reduce(
            (filteredRoutes, [key, route]) => {
              if (!route.some((location) => !location || !location.id)) {
                filteredRoutes[key] = route;
              }
              return filteredRoutes;
            },
            {} as { [type: string]: DetailedLocation[] },
          );
        }

        const routesToCalculate = getRoutesToCalculate(jobLocations);

        if (!Object.keys(routesToCalculate).length) {
          return of(JobToolActions.calculateDistanceSuccess({ distances: {} }));
        }

        const units = freyaHelper.getUnits();

        const distanceObservables = Object.entries(routesToCalculate).map(
          ([key, route]) => {
            return calculateDistanceGQL
              .fetch({
                units: units,
                locationIds: route.map((location) => location.id),
              })
              .pipe(
                map((res) => ({
                  key,
                  distance: res.data?.calculateDistance,
                })),
                catchError((error) => {
                  console.error(
                    `Error calculating distance for route ${key}:`,
                    error,
                  );
                  return of({ key, distance: null }); // Return a default value in case of error
                }),
              );
          },
        );

        return forkJoin(distanceObservables).pipe(
          map((results) => {
            const distances = results.reduce((acc, { key, distance }) => {
              acc[key] = distance;
              return acc;
            }, {});
            return JobToolActions.calculateDistanceSuccess({ distances });
          }),
          catchError((error) => {
            console.error('Error calculating distances:', error);
            return of(JobToolActions.calculateDistanceError({ error })); // Dispatch a failure action
          }),
        );
      }),
    );
  },
  { functional: true, dispatch: true },
);

export const GetUsers = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    listUsersGQL = inject(ListUsersGQL),
  ) => {
    return actions$.pipe(
      ofType(JobToolActions.employeeSearched),
      withLatestFrom(
        store.select(jobToolFeature.selectEmployeeSearch),
      ),
      exhaustMap(([_, search]) => {
        store.dispatch(JobToolActions.employeesLoading());

        const variables: ListUsersQueryVariables = {
          filter: {
            search,
            roleHasAttribute: EMPLOYEE_ROLE_ATTRIBUTE,
          },
          limit: 5,
        };

        return listUsersGQL.fetch(variables).pipe(
          map((res) => {
            if (res.loading || !res?.data) {
              return JobToolActions.employeesLoading();
            }

            const { usersv2 } = res.data;

            return JobToolActions.employeesLoaded({
              employees: usersv2.users as User[],
            });
          }),

          catchError((error) => {
            return of(JobToolActions.employeesLoadError({ error }));
          }),
        );
      }),
    );
  },
  { functional: true, dispatch: true },
);

export const changeJobStage = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    updateJobGQL = inject(UpdateJobGQL),
    notify = inject(FreyaNotificationsService),
  ) => {
      return actions$.pipe(
        ofType(JobToolActions.updateJobStage),
        withLatestFrom(store.select(jobToolFeature.selectJob)),
        exhaustMap(([ { updateJobInput }, job ]) => {

          const currentStage = job.stage;

          const input = {
            jobId: updateJobInput.jobId,
            stage: updateJobInput.stage,
          }

          return updateJobGQL.mutate({ updateJobs: [input] }).pipe(
            map((result) => {
              const isSuccess = result.data?.updateJobs?.isSuccess;

              if (isSuccess) {
                notify.success('Job updated successfully');
                return JobToolActions.updateJobStageSuccess({
                  isSuccess,
                });
              } else {
                const error = new Error('Error updating job');
                notify.error('Error updating job');
                console.error('Error updating job:', error);
                return JobToolActions.updateJobStageError({
                  error,
                  updateJobInput,
                  currentStage,
                });
              }
            }),
            catchError((error) => {
              notify.apolloError('Error updating job', error);
              console.error('Error updating job:', error);
              return of(
                JobToolActions.updateJobStageError({
                  error,
                  updateJobInput,
                  currentStage,
                }),
              );
            }),

          );
        }),
      );
  },
  { functional: true, dispatch: true },
);

export const changeAgent = createEffect(
  (
    actions$ = inject(Actions),
    updateJobGQL = inject(UpdateJobGQL),
    notify = inject(FreyaNotificationsService),
  ) => {
    return actions$.pipe(
      ofType(JobToolActions.jobAgentChanged),
      exhaustMap(({ updateJobInput }) => {
        const input = {
          jobId: updateJobInput.jobId,
          addUsers: mapJobUsers(updateJobInput.addUsers),
          removeUsers: mapJobUsers(updateJobInput.removeUsers),
        };

        return updateJobGQL.mutate({ updateJobs: [input] }).pipe(
          map((result) => {
            const isSuccess = result.data?.updateJobs?.isSuccess;

            if (isSuccess) {
              notify.success('Job updated successfully');
              return JobToolActions.jobAgentChangeSuccess({
                isSuccess,
              });
            } else {
              const error = new Error('Error updating job');
              notify.error('Error updating job');
              console.error('Error updating job:', error);
              return JobToolActions.jobAgentChangeError({
                error,
                updateJobInput,
              });
            }
          }),
          catchError((error) => {
            notify.apolloError('Error updating job', error);
            console.error('Error updating job:', error);
            return of(
              JobToolActions.jobAgentChangeError({
                error,
                updateJobInput,
              }),
            );
          }),
        );
      }),
    );
  },
  { functional: true, dispatch: true },
);

//TODO: Optimise this effect
export const updateCustomerEffect = createEffect(() => {
  const actions$ = inject(Actions);
  const store = inject(Store);
  const editProfileGQL = inject(EditProfileGQL);
  const notify = inject(FreyaNotificationsService);

  return actions$.pipe(
    ofType(JobToolActions.jobUpdateRequested),
    withLatestFrom(store.select(jobToolFeature.selectChanges)),
    map(([_, changes]) => {
      const initialCustomerCopy = changes.find(item => item.fieldName === JOB_FORM_FIELDS.initialCustomerCopy);
      if (initialCustomerCopy) {
        const updateCustomerVariables = generateUpdateCustomerInput(changes, initialCustomerCopy.value.id);
        return updateCustomerVariables;
      }
      return null;
    }),
    filter(customerChanges => customerChanges !== null),
    exhaustMap((customerChanges) => {
      return from(editProfileGQL.mutate(customerChanges)).pipe(
        map(response => {

          const isSuccess = response.data?.users.setProfile;
          if (isSuccess) {
            notify.success('Customer updated successfully');
            return JobToolActions.updateCustomerSuccess({ customerEditInput: customerChanges });
          } else {
            notify.error('Error updating customer');
            console.error('Error updating customer:', response);
            return JobToolActions.updateCustomerError({ error: new Error('Error updating customer') });
          }
        }),
        catchError(error => {
          notify.apolloError('Error updating customer', error);
          console.error('Error updating customer:', error);
          return of(JobToolActions.updateCustomerError({ error }));
        })
      );
    })
  );
}, { functional: true, dispatch: true });

//TODO: Optimise this effect
export const updateJob = createEffect(() => {
  const actions$ = inject(Actions);
  const store = inject(Store);
  const updateJobGQL = inject(UpdateJobGQL);
  const notify = inject(FreyaNotificationsService);
  const plusAuthService = inject(PlusAuthenticationService);

  return actions$.pipe(
    ofType(JobToolActions.jobUpdateRequested),
    withLatestFrom(
      store.select(jobToolFeature.selectChanges),
      store.select(jobToolFeature.selectJob),
    ),
    exhaustMap(([_, changes = [], serverCopy]) => {

      // Check if the job has been updated
      const jobChanges = changes.filter(item => item.namespace !== 'customerInput');
      const customerChanges = changes.some(item => item.namespace === 'customerInput');

      if (!jobChanges.length) {
        // Customer changes are handled by updateCustomerEffect
        if (!customerChanges) {
          notify.success('Job is already up to date');
        }
        return of(JobToolActions.jobUpdateSuccess({ updateJobInput: { jobId: serverCopy.id } }));
      }

      const { jobType, jobOrigin, resolvedServiceArea, ...recentChanges } = generateJobCreateVariables(changes);
      const locations = generateLocationsInputsFromLatestChanges(changes);
      const { forRemove, forAdd } = filterLocationsForAddAndRemove(locations as any);

      const addLocationsInput = Object.values(forAdd).map(item => ({
        locationId: item.id,
        locationType: item.locationType
      }));

      const removeLocationsInput = Object.values(forRemove).map(item => item?.id);


      const fieldsChanges = generateFieldsInputFromLatestChanges(changes);
      const { inventory } = generateInventoryInputFromLatestChanges(changes);
      const selectedExistingCustomer = changes.find(
        item => item.fieldName === JOB_FORM_FIELDS.selectedExistingCustomer);
      const editJobVariables: UpdateJobInput = {
        jobId: undefined, //is added below,
        metadata: {
          jobType,
          jobOrigin,
        },
        fields: {},
        addLocations: addLocationsInput,
        removeLocations: removeLocationsInput,
        ...recentChanges,
      }

      let fieldsForQuery = [];
      if (resolvedServiceArea) {
        editJobVariables.setZone = resolvedServiceArea?.id;
      }

      if (!isEmpty(fieldsChanges)) {
        fieldsForQuery = Object.keys(fieldsChanges).map(key => ({
          fieldName: key,
          value: fieldsChanges[key]
        }));
      }

      if (!isEmpty(inventory)) {
        const inventoryForQuery = {
          fieldName: "jobs.inventory",
          value: JSON.stringify(inventory)
        }

        fieldsForQuery.push(inventoryForQuery);
      }

      if (fieldsForQuery?.length) {
        editJobVariables.fields = {
          objectLabel: "Job",
          fields: fieldsForQuery,
        };
      }

      if (selectedExistingCustomer) {
        const previousCustomerId = serverCopy?.users?.find(u => u.role === 'customer')?.user?.id;
        const newCustomerId = selectedExistingCustomer?.value?.id;
        editJobVariables.removeUsers = [
          {
            role: 'customer',
            userId: previousCustomerId,
          }
        ];
        editJobVariables.addUsers = [
          {
            role: 'customer',
            userId: newCustomerId,
          }
        ]
      }

      const updateJobInput: UpdateJobInput = { ...editJobVariables, jobId: serverCopy.id };

      return updateJobGQL.mutate({ updateJobs: [updateJobInput] }).pipe(
        switchMap((result) => {
          const isSuccess = result.data?.updateJobs?.isSuccess;
          const job = result?.data?.updateJobs?.jobs[0];

          if (isSuccess) {
            notify.success('Job updated successfully');

            let zone = job.zone;
            if (zone.type === 'area') {
              zone = zone.parent;
            }

            const shouldChangeZones =
              zone &&
              zone.type !== 'area' &&
              zone.id !== plusAuthService.contextedZoneId;

            return (shouldChangeZones
              ? from(plusAuthService.setContext(zone.id))
              : of(null)
            ).pipe(
              map(() =>
                JobToolActions.jobUpdateSuccess({
                  updateJobInput,
                  locationsToAdd: forAdd,
                  locationsToRemove: forRemove,
                  zone: job?.zone,
                })
              )
            );
          } else {
            notify.error('Error updating job');
            return of(JobToolActions.jobUpdateError({ error: new Error('Error updating job') }));
          }
        }),
        catchError((error) => {
          notify.apolloError('Error updating job', error);
          console.error('Error updating job:', error);
          return of(JobToolActions.jobUpdateError({ error }));
        }),
      );
    }),
  );
}, { functional: true, dispatch: true });


export const GetTags = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    tagsGQL = inject(TagsGQL),
  ) => {
    return actions$.pipe(
      ofType(JobToolActions.tagSearched),
      withLatestFrom(store.select(jobToolFeature.selectTagSearch)),
      exhaustMap(([_, search]) => {
        const variables: TagsQueryVariables = {
          filter: {
            search,
            limit: 5,
            objectTypes: [TAG_TYPES.Job],
          },
          order: 'order:asc',
        };

        return tagsGQL.fetch(variables).pipe(
          map((res) => {
            if (res.loading || !res?.data) {
              return JobToolActions.tagsLoading();
            }

            const { tags } = res.data.tags;

            return JobToolActions.tagsLoaded({
              tags,
            });
          }),

          catchError((error) => {
            return of(JobToolActions.tagsLoadError({ error }));
          }),
        );
      }),
    );
  },
  { functional: true, dispatch: true },
);

// Effect to add a tag on an object (Job)
export const AssignTagsToJob = createEffect(
  (
    store = inject(Store),
    actions$ = inject(Actions),
    setTagsOnObject = inject(SetTagsOnObjectGQL),
    notify = inject(FreyaNotificationsService),
  ) => {
    return actions$.pipe(
      ofType(JobToolActions.tagAssignedToJob),
      withLatestFrom(store.select(jobToolFeature.selectJobId)),
      exhaustMap(([{ tags, originalTags }, objectId]) => {

        const input: SetTagsOnObjectMutationVariables = {
          tags: tags.map((tag) => ({ tagId: tag.id, private: false })),
          objectId,
          objectLabel: TAG_TYPES.Job,
        };

        return setTagsOnObject.mutate(input).pipe(
          map((result) => {
            const isSuccess = result.data?.tags.setObjectTags;

            if (isSuccess) {
              notify.success('Tags assigned successfully');
              return JobToolActions.tagAssignmentSuccess({
                isSuccess,
              });

            } else {

              const error = new Error('Error assigning tags to job');

              notify.error('Error assigning tags to job');
              console.error('Error assigning tags to job:', error);

              return JobToolActions.tagAssignmentError({
                error,
                tags,
                originalTags
              });
            }
          }),
          catchError((error) => {

            notify.apolloError('Error assigning tags to job', error);
            console.error('Error assigning tags to job:', error);

            return of(
              JobToolActions.tagAssignmentError({
                error,
                tags,
                originalTags
              }),
            );
          }),
        );
      }),
    );
  },
  { functional: true, dispatch: true },
);

export const GetAssets = createEffect(
  (actions$ = inject(Actions), listAssets = inject(ListBaseAssetsGQL)) => {
    return actions$.pipe(
      ofType(JobToolActions.assetSearched),
      exhaustMap(({ assetSearch }) => {
        const variables: ListBaseAssetsQueryVariables = {
          filter: {
            // types: ['Truck'],
            search: assetSearch,
          },
          limit: 5,
        };



        return listAssets.fetch(variables).pipe(
          map((res) => {
            if (res.loading || !res?.data) {
              return JobToolActions.assetsSearchLoading();
            }

            const { assets } = res.data;

            return JobToolActions.assetsSearchLoaded({
              assets: assets.assets as Asset[],
            });
          }),

          catchError((error) => {
            return of(JobToolActions.assetsSearchError({ error }));
          }),
        );
      }),
    );
  },
  { functional: true, dispatch: true },
);

export const GetCrew = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    listUsersGQL = inject(ListUsersGQL),
  ) => {
    return actions$.pipe(
      ofType(JobToolActions.crewSearched),
      withLatestFrom(
        store.select(jobToolFeature.selectCrewSearch),
      ),
      exhaustMap(([_, search]) => {

        store.dispatch(JobToolActions.searchedCrewLoading());

        const variables: ListUsersQueryVariables = {
          filter: {
            search,
            roleHasAttribute: CREW_ROLE_ATTRIBUTE,
          },
          limit: 5,
        };

        return listUsersGQL.fetch(variables).pipe(
          map((res) => {
            if (res.loading || !res?.data) {
              return JobToolActions.searchedCrewLoading();
            }

            const { usersv2 } = res.data;

            return JobToolActions.searchedCrewLoaded({
              crew: usersv2.users as User[],
            });
          }),

          catchError((error) => {
            return of(JobToolActions.searchedCrewError({ error }));
          }),
        );
      }),
    );
  },
  { functional: true, dispatch: true },
);

export const CreateEvent = createEffect(
  (
    actions$ = inject(Actions),
    createCalendarEventGQL = inject(CreateCalendarEventGQL),
    notify = inject(FreyaNotificationsService),
  ) => {
    return actions$.pipe(
      ofType(JobToolActions.eventCreationRequested),
      exhaustMap(({ createEventsInput }) => {

        return createCalendarEventGQL.mutate(createEventsInput).pipe(
          map((result) => {
            const createdEvents = result.data?.createCalendarEvent.events;
            const isSuccess = createdEvents.length > 0;

            if (isSuccess) {
              notify.success('Event created successfully.');
              return JobToolActions.eventCreationSuccess({
                isSuccess,
                events: createdEvents as CalendarEvent[],
              });
            } else {
              const error = new Error('Error creating an event.');
              notify.error('Error creating an event.');
              console.error('Error creating an event:', error);

              return JobToolActions.eventCreationError({
                error,
              });
            }
          }),
          catchError((error) => {
            notify.apolloError('Error creating an event.', error);
            console.error('Error creating an event:', error);
            return of(
              JobToolActions.eventCreationError({
                error,
              }),
            );
          }),
        );
      }),
    );
  },
  { functional: true, dispatch: true },
);

export const UpdateEvents = createEffect(
  (
    actions$ = inject(Actions),
    bulkEditCalendarEventGQL = inject(BulkEditCalendarEventGQL),
    notify = inject(FreyaNotificationsService),
  ) => {
    return actions$.pipe(
      ofType(JobToolActions.eventUpdateRequested),
      exhaustMap(({ edits }) => {

        const createEditInput = (editObject): SingleEditInput => {

          const singleEdit = editObject.edit;
          const { addAttendees = [], removeAttendees = [] } = singleEdit?.setAttendees || {};
          const { addAssets = [], removeAssets = [] } = singleEdit?.setAssets || {};
          const { addLocations = [], editLocations = [], removeLocations = [] } = singleEdit?.setLocations || {};

          return {
            id: editObject.id,
            edit: {
              ...(singleEdit.type && { type: singleEdit.type }),
              ...(singleEdit.title && { title: singleEdit.title }),
              ...(singleEdit.status && { status: singleEdit.status }),

              setAttendees: {
                addAttendees,
                removeAttendees,
              },
              setAssets: {
                addAssets,
                removeAssets,
              },
              setLocations: {
                addLocations,
                editLocations,
                removeLocations,
              }
            },
          };
        };

        const input: BulkEditCalendarEventMutationVariables = {
          edits: edits.map(createEditInput),
        };

        return bulkEditCalendarEventGQL.mutate(input).pipe(
          map((result) => {
            const { total, events } = result.data?.bulkEditCalendarEvent;


            if (total === edits.length) {
              notify.success('Event updated successfully');
              return JobToolActions.eventUpdateSuccess({
                updatedEvents: events as CalendarEvent[],
              });
            } else {
              const error = new Error('Error updating event');
              notify.error('Error updating event');
              console.error('Error updating event:', error);
              return JobToolActions.eventUpdateError({
                error,
              });
            }
          }),
          catchError((error) => {
            notify.apolloError('Error updating job', error);
            console.error('Error updating job:', error);
            return of(
              JobToolActions.eventUpdateError({
                error,
              }),
            );
          }),
        );
      }),
    );
  },
  { functional: true, dispatch: true },
);

export const DeleteEvent = createEffect(
  (
    actions$ = inject(Actions),
    removeCalendarEventGQL = inject(RemoveCalendarEventGQL),
    notify = inject(FreyaNotificationsService),
  ) => {
    return actions$.pipe(
      ofType(JobToolActions.eventDeletionRequested),
      exhaustMap(({ eventId }) => {
        return removeCalendarEventGQL
          .mutate({
            ids: [eventId],
          })
          .pipe(
            map((result) => {
              const isSuccess = result.data?.removeCalendarEvent;

              if (isSuccess) {

                notify.success('Event deleted successfully');
                return JobToolActions.eventDeletionSuccess({
                  eventId,
                });

              } else {

                const error = new Error('Error deleting event');
                notify.error('Error deleting event');
                console.error('Error deleting event:', error);
                return JobToolActions.eventDeletionError({
                  error,
                });

              }
            }),
            catchError((error) => {
              notify.apolloError('Error deleting job', error);
              console.error('Error deleting job:', error);
              return of(
                JobToolActions.eventDeletionError({
                  error,
                }),
              );
            }),
          );
      }),
    );
  },
  { functional: true },
);

export const ConfirmEventLocationChanges = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    notify = inject(FreyaNotificationsService),
    freyaHelper = inject(FreyaHelperService),
    dialogService = inject(DialogService),
    responsiveHelper = inject(ResponsiveHelperService),
  ) => {
    return actions$.pipe(
      ofType(JobToolActions.jobUpdateSuccess),
      withLatestFrom(store.select(jobToolFeature.selectJob)),
      exhaustMap(([{ locationsToAdd, locationsToRemove }, job]) => {
        if (!job?.events?.length || (!Object.keys(locationsToAdd || {}).length && !Object.keys(locationsToRemove || {}).length)) {
          return of(JobToolActions.noOp());
        }

        const edits: SingleEditInput[] = [];
        const confirmDialogInfo: EventLocationInfo[] = [];

        job.events.map(event => {
          const addLocations: CalendarEventLocationInput[] = [];
          const removeLocations: string[] = [];
          const dialogInfo: EventLocationInfo = { event, locations: [] };

          event.locations.forEach(location => {
            if (locationsToAdd[location.type]) {
              addLocations.push({
                locationId: locationsToAdd[location.type].id,
                type: location.type,
                order: location.order,
                estimatedTimeAtLocation: location.estimatedTimeAtLocation,
                ignoreInDuration: true,
              });
              removeLocations.push(location.id);

              dialogInfo.locations.push({
                currentLocation: location as CalendarEventLocation,
                newLocation: {
                  address: locationsToAdd[location.type].addressLineOne,
                  locationId: locationsToAdd[location.type].id,
                  locationType: location.type,
                }
              });
            } else if (locationsToRemove[location.id]) {
              removeLocations.push(location.id);
            }
          });

          if (addLocations.length || removeLocations.length) {
            edits.push({
              id: event.id,
              edit: {
                setLocations: {
                  addLocations,
                  removeLocations
                }
              }
            });
          }

          if (dialogInfo.locations.length) {
            confirmDialogInfo.push(dialogInfo);
          }
        });

        if (!edits.length) return of(JobToolActions.noOp());

        const hasLockedEvents = confirmDialogInfo.some(({ event }) => freyaHelper.lockDate > event.end);

        if (hasLockedEvents) {
          notify.warning(
            'Job location will be updated but cannot automatically update event locations as some events end before the lock date'
          );
          return of(JobToolActions.noOp());
        }

        const dialogRef = dialogService.open(UpdateEventLocationsDialogComponent, {
          header: 'Update Event Locations?',
          contentStyle: freyaHelper.getDialogContentStyle('1.5rem'),
          width: responsiveHelper.dialogWidth,
          closable: false,
          data: { eventLocations: confirmDialogInfo },
        });

        return dialogRef.onClose.pipe(
          switchMap(confirmed => confirmed
            ? of(JobToolActions.eventUpdateRequested({ edits }))
            : of(JobToolActions.noOp())
          )
        );
      }),
    );
  },
  { functional: true, dispatch: true }
);

export const GetDocumentArtifact = createEffect(
  (
    store = inject(Store),
    actions$ = inject(Actions),
    invoiceDetailsGQL = inject(InvoiceDetailsGQL)
  ) => {
    return actions$.pipe(
      ofType(JobToolActions.jobLoaded),
      withLatestFrom(store.select(selectLatestInvoiceId)),
      filter(([_, latestInvoiceId]) => Boolean(latestInvoiceId)),
      switchMap(([_, latestInvoiceId]) => {
        return invoiceDetailsGQL.fetch({ invoiceId: latestInvoiceId }).pipe(
          map((res) =>{
            const artifact = res?.data?.invoices?.invoices[0]?.artifacts[0];
            return JobToolActions.getDocumentArtifactSuccess({ artifact, documentType: latestInvoiceId });
          }),
          catchError((error) =>
            of(JobToolActions.getDocumentArtifactError({ error }))
          )
        );
      }),
    );
  },
  { functional: true, dispatch: true }
);

export const closeExistingJob = createEffect(
  (
    actions$ = inject(Actions),
    updateJobGQL = inject(UpdateJobGQL),
    notify = inject(FreyaNotificationsService),
  ) => {
    return actions$.pipe(
      ofType(JobToolActions.closeExistingJob),
      exhaustMap(({ jobId, closedReason }) => {
        const input = {
          closeJob: true,
          jobId,
          closedReason,
        };

        return updateJobGQL.mutate({ updateJobs: [input] }).pipe(
          map((result) => {
            const isSuccess = result.data?.updateJobs?.isSuccess;

            if (isSuccess) {
              notify.success('Job closed');
              //TO DO find better way to track closedAt,
              //this is not correct, but for this purpose works
              return JobToolActions.closeExistingJobSuccess({
                closedAt: Date.now()
              });
            } else {
              const error = new Error('Error closing job');
              notify.error('Error closing job');
              return JobToolActions.closeExistingJobError({
                error,
              });
            }
          }),
          catchError((error) => {
            notify.apolloError('Error closing job', error);
            return of(
              JobToolActions.closeExistingJobError({
                error,
              }),
            );
          }),
        );
      }),
    );
  },
  { functional: true, dispatch: true },
);

export const reopenExistingJob = createEffect(
  (
    actions$ = inject(Actions),
    updateJobGQL = inject(UpdateJobGQL),
    notify = inject(FreyaNotificationsService),
  ) => {
    return actions$.pipe(
      ofType(JobToolActions.reopenExistingJob),
      exhaustMap(({ jobId }) => {
        const input = {
          closeJob: false,
          jobId,
        };

        return updateJobGQL.mutate({ updateJobs: [input] }).pipe(
          map((result) => {
            const isSuccess = result.data?.updateJobs?.isSuccess;

            if (isSuccess) {
              notify.success('Job reopened');
              //TO DO find better way to track closedAt,
              //this is not correct, but for this purpose works
              return JobToolActions.closeExistingJobSuccess({
                closedAt: null,
              });
            } else {
              const error = new Error('Error reopening job');
              notify.error('Error reopening job');
              return JobToolActions.closeExistingJobError({
                error,
              });
            }
          }),
          catchError((error) => {
            notify.apolloError('Error reopening job', error);
            return of(
              JobToolActions.closeExistingJobError({
                error,
              }),
            );
          }),
        );
      }),
    );
  },
  { functional: true, dispatch: true },
);

export const deleteJob = createEffect((
  actions$ = inject(Actions),
  deleteJobGQL = inject(DeleteJobGQL),
  notify = inject(FreyaNotificationsService),
) => {
    return actions$.pipe(
      ofType(JobToolActions.jobDeletedRequested),
      exhaustMap(({ jobId }) => deleteJobGQL.mutate({ jobIds: [ jobId ] }).pipe(
        map((res) => {

          const success = res.data?.deleteJobs;

          if (success) {
            notify.success('Job deleted successfully');
            return JobToolActions.jobDeletedSuccess({ jobId });
          }
          notify.error('Failed to delete job');
          return JobToolActions.jobDeletedError({ error: new Error('Failed to delete job') });
        }),
        catchError((error) => of(JobToolActions.jobDeletedError({ error }))),
      )),
    );
  },
  { functional: true, dispatch: true },
);

export const redirectToJobsPageOnDeleteJobSuccess = createEffect((
  actions$ = inject(Actions),
    router = inject(Router),
) => {

    const currentUrl$ = getCurrentUrl(router);

    return actions$.pipe(
      ofType(JobToolActions.jobDeletedSuccess),
      withLatestFrom(currentUrl$),
      filter(([ action, url ]) => isJobRoute(action.jobId, url)),
      tap(() => router.navigate([ '/jobs'])),
    );
  },
  { functional: true, dispatch: false },
);

function isJobRoute(jobId: string, url: string) {
  if (!url) { return false; }
  const [ _blank,  page, jobIdParam ] = url.split('/');
  if (page !== 'jobs') { return false; }
  return jobId === jobIdParam;
}

function getCurrentUrl(router: Router) {
  return router.events.pipe(
    filter((event): event is NavigationEnd => event instanceof NavigationEnd),
    map(() => router.routerState.snapshot.url),
  );
}
