import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Store } from '@ngrx/store';
import { from, of, switchMap, withLatestFrom } from 'rxjs';


import { quickAccessFeature } from './quick-access-reducers';
import { QuickAccessActions } from './quick-access.actions';

export const loadQuickAccessItems = createEffect(() => {
	const actions$ = inject(Actions);
	const store = inject(Store);

	return actions$.pipe(
		ofType(QuickAccessActions.initialized),
		withLatestFrom(
			store.select(quickAccessFeature.selectPinnedJobs),
			store.select(quickAccessFeature.selectRecentJobs),
			store.select(quickAccessFeature.selectTodaysJobs),
		),
		switchMap(([ action, pinnedJobs, recentJobs, todaysJobs ]) => {

			const unsavedChanges = localStorage.getItem('workorders_unsaved_changes');
			let jobsWithUnsavedChanges: string[] = [];

			if (unsavedChanges) {
				const unsavedChangesObj = JSON.parse(unsavedChanges);
				jobsWithUnsavedChanges = Object.keys(unsavedChangesObj);
			}


			// now load em & store it


			return of(QuickAccessActions.loaded({
				pinnedJobs,
				recentJobs,
				todaysJobs,
				jobsWithUnsavedChanges,
			}));
		})
	);
}, { functional: true, dispatch: true });

