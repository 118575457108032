<ng-container *ngIf="!renderHeadless; else headlessEditSummary">
  <p-card>
    <div class="header-container">
      <div class="header-with-icon">
        <i class="pi pi-book p-mr-2"></i>
        <h2>Summary</h2>
      </div>
      <div class="saving-disabled-msg header-with-icon">
        <p-message
          severity="warn"
          *ngIf="creatingDisabled"
          [text]="savingDisabledMessage"
        ></p-message>
      </div>
    </div>
    <div class="one-column-container">
      <div class="create-btn-container">
        <span [class.error-msg]="errorMessage" *ngIf="mode === 'create'">
          {{
            errorMessage ? errorMessage : 'Create this job to determine price.'
          }}
        </span>
        <span [class.error-msg]="errorMessage" *ngIf="mode === 'edit'">
          {{
            errorUpdateMessage
              ? errorUpdateMessage
              : 'Update job to save your changes.'
          }}
        </span>
        <app-form-action-button
          *ngIf="mode === 'create'"
          action="create"
        ></app-form-action-button>
        <app-form-action-button
          *ngIf="mode === 'edit'"
          action="update"
        ></app-form-action-button>
      </div>
      <ng-container *ngTemplateOutlet="summaryEditTemplate"></ng-container>
    </div>
  </p-card>
</ng-container>

<ng-template #headlessEditSummary>
  <div class="headless">
    <ng-container *ngTemplateOutlet="summaryEditTemplate"></ng-container>
  </div>
</ng-template>

<ng-template #summaryEditTemplate>
  <p-tabView
    *ngIf="jobSummaries && (jobsV2Enabled$ | async)"
    [(activeIndex)]="activeTabIndex"
  >
    <p-tabPanel *ngFor="let summary of jobSummaries; let i = index" #tabHeaders>
      <ng-template pTemplate="header">
        <div [tabindex]="jobCreateIndexes[summary.key]">
          <span>{{ summary.name }}</span>
        </div>
      </ng-template>
      <div class="description">
        <p>{{ summary.description }}</p>
      </div>
      <quill-editor
        #quillEditor
        [(ngModel)]="summariesMap[summary.key]"
        [format]="json"
        [modules]="editModules"
        (ngModelChange)="onEditorChange(summary.key, i)"
      >
      </quill-editor>
    </p-tabPanel>
  </p-tabView>
</ng-template>
