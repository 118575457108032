<div *ngIf="job$ | async as job">
  <span
    class="job-status {{ job.closedAt ? 'closed' : 'open' }} {{ job.stage }}"
    (click)="toggleMenu($event)"
  >
    <span class="content">
      {{ job.closedAt ? 'Closed' : 'Open' }}
      {{ job.stage | titlecase }}
      <ng-container *ngIf="closedReason$ | async as closedReason">
        - {{ closedReason }}
      </ng-container>
    </span>
    <i
      class="pi {{
        isJobStatusMenuVisible ? 'pi-chevron-up' : 'pi-chevron-down'
      }}"
    ></i>
  </span>
  <p-menu #menu [model]="jobStatusItems" [popup]="true" appendTo="body" />
</div>
