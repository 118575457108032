import { AfterContentChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { SubSink } from 'subsink';

import { AssetsGQL, BaseAssetFragment, BaseTokenFragment, CreateIcalTokenGQL } from '../../../generated/graphql.generated';
import { JOB_EVENT_TYPES } from '../../global.constants';
import { BrandingService } from '../../services/branding.service';
import { DetailsHelperService } from '../../services/details-helper.service';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';
import { MutateObjectComponent, MutateObjectElement } from '../mutate-object/mutate-object.component';

import { IcalTokenCreatedAction } from './ical.state';


@Component({
  selector: 'app-mutate-ical-token',
  templateUrl: './mutate-ical-token.component.html',
  styleUrl: './mutate-ical-token.component.scss'
})
export class MutateIcalTokenComponent implements OnInit, OnDestroy, AfterContentChecked {

  @ViewChild('mutate') mutateRef: MutateObjectComponent;

  // Template Refs
  @ViewChild('name') nameRef: TemplateRef<any>;
  @ViewChild('assets') assetsRef: TemplateRef<any>;
  @ViewChild('eventTypes') eventTypesRef: TemplateRef<any>;

  @Input() mutateType: 'update' | 'create';
  @Input() token: BaseTokenFragment;

  steps: MutateObjectElement[];

  subs = new SubSink();

  assetId?: string;
  allAssets: BaseAssetFragment[] = [];
  allEventTypes = JOB_EVENT_TYPES;

  form = new FormGroup({
    name: new FormControl('', [ Validators.required, Validators.minLength(2) ]),
    assets: new FormControl([] as string[], [ ]),
    eventTypes: new FormControl([] as string[], [ ]),
  });

  defaultValues = this.form.value;

  // $branding = this.store.select(brandingFeature.selectBranding);
  // branding: ZoneBranding;

  constructor(
    private cd: ChangeDetectorRef,
    private createIcalTokenGQL: CreateIcalTokenGQL,
    private store: Store,
    private brandingSvc: BrandingService,
    private detailsHelper: DetailsHelperService,
    private localNotify: FreyaNotificationsService,
    private assetsGQL: AssetsGQL,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngAfterContentChecked(){
    this.cd.detectChanges();
  }

  openDialog() {

    // maybe more?
    this.steps = [
      {name: 'Assets', ref: this.assetsRef, control: 'assets', type: 'func', reviewFunc: (val) => {
        return val?.length || 'all';
      }},
      {name: 'Event Types', ref: this.eventTypesRef, control: 'eventTypes', type: 'func', reviewFunc: (val) => {
        return val?.length || 'all';
      }},
      {name: 'Name', ref: this.nameRef, control: 'name', type: 'text'},
    ];
  
    if (this.mutateType === 'create') {
      this.form.reset();

      if (this.assetId) {
        this.form.get('assets').setValue([ this.assetId ]);
      }
    } else if (this.mutateType === 'update') {

    }

    this.mutateRef.steps = this.steps;
    this.mutateRef.mutateType = this.mutateType;

    this.allAssets = undefined;
    this.subs.sink = this.assetsGQL.fetch({
      limit: -1,
    }).subscribe((res) => {
      this.allAssets = res.data.assets?.assets;
    });

    this.mutateRef.openDialog();
  }

  mutateObject() {
    if (this.mutateType === 'create') {
      this.create();
    } else if (this.mutateType === 'update') {
      // if (this.form.dirty) {
      //   this.updateTax();
      // }
    }
  }

  create() {
    const value = this.form.value;

    this.subs.sink = this.createIcalTokenGQL.mutate({
      name: value.name,
      assetIds: (value.assets?.length
        // selecting all assets will not add a filter here
        && value.assets?.length !== this.allAssets?.length
      ) ? value.assets : undefined,
      eventTypes: (value.eventTypes?.length
        // selecting all events will not add a filter here
        && value.eventTypes?.length !== this.allEventTypes?.length
      ) ? value.eventTypes : undefined,
    }).subscribe({
      next: (res) => {
        const token = res.data.createIcalToken;
        if (!token) { return; }

        this.mutateRef.closeDialog();

        const url = new URL(location.href);
        url.pathname = `/api/ical/${ token.token }`;
        url.search = '';

        console.log(`iCal URL created`, token, url.toString());

        this.store.dispatch(IcalTokenCreatedAction({
          token,
          url: url.toString(),
        }));
      },
      error: (error) => {
      this.mutateRef.loading = false;
      console.error(error);
      this.localNotify.apolloError(`Failed to create ical token`, error);
      },
    });
  }

  generateNameIfEmpty() {
    let newName = this.form.value.name;

    const branding = this.brandingSvc.currentBranding.value;
    const currentZone = this.brandingSvc.currentZone().value;

    if (!newName) {

      // TODO: insert asset names, event types
      newName = `${ branding.shortName } ${ currentZone?.name }`;

      const selectedAssets = this.allAssets.filter(
        (a) => this.form.value.assets?.includes(a.id)
      );
  
      const maxAssetNames = 3;
      if (selectedAssets?.length && selectedAssets?.length <= maxAssetNames) {
        newName += ` ${ selectedAssets.map((a) => a.name).join(', ') }`;
      } else if (selectedAssets?.length > maxAssetNames) {
        newName += ` ${ selectedAssets?.length } assets`;
      }

      const eventTypes = this.form.value.eventTypes;
      const maxEventTypes = 1;
      if (eventTypes?.length && eventTypes?.length <= maxEventTypes) {
        newName += ` ${ eventTypes.join(', ') }`;
      } else if (eventTypes?.length > maxEventTypes) {
        newName += ` ${ eventTypes?.length } event types`;
      }
    }

    this.form.get('name').setValue(newName);
  }

}
