<div class="p-grid">
    <div class="p-col-12 drop-container">
        <div class="p-grid">
            <div class="p-col estimate-container">
                <h5>
                    Estimated Total:
                    {{
                        job?.discountedSubTotal / 100
                            | currency : job?.currency || "USD"
                    }}
                    <span *ngIf="job?.discountTotal > 0">
                        / Discounts:
                        {{
                            job?.discountTotal / 100
                                | currency : job?.currency || "USD"
                        }}
                    </span>
                </h5>
                <button
                    *hasPermission="['artifacts.create']"
                    pButton
                    class="p-button-outlined p-m-1"
                    label="Create & Send Estimate"
                    (click)="openDocuments()"
                    [disabled]="jobLoading || job?.closedAt"
                ></button>
                <p-message
                    *ngIf="job?.zone?.type !== 'area'"
                    severity="warn"
                    text="Your job needs to be in an area to apply charges"
                    styleClass="mr-2"
                ></p-message>
                <div *ngIf="!job?.users?.length || !job?.users[0].user">
                    <p-message
                        severity="warn"
                        text="Charges will not be saved until you have set the customer"
                    ></p-message>
                </div>
            </div>
            <div *hasPermission="['jobs.update']" class="p-col p-text-right">
                <button
                    pButton
                    class="p-button-outlined p-m-1"
                    [icon]="isAllEventsCollapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                    [label]="isAllEventsCollapsed ? 'Expand All Events' : 'Collapse All Events'"
                    (click)="toggleAllEvents()"
                ></button>
                <app-add-event-button
                    *hasPermission="['calendarEvents.create']"
                    [job]="job"
                    [disabled]="estimateHelper.jobLoading | async"
                ></app-add-event-button>
            </div>
            <div
                class="dashed-container no-charges-msg"
                *ngIf="!loadingNewJob && job?.events.length === 0"
                style="margin-left: 0.7rem"
            >
                <i class="pi pi-inbox p-mr-1" style="font-size: 1.1rem"></i>No
                events added to this job.
                <br />
                <br />
                <app-add-event-button
                    *hasPermission="['calendarEvents.create']"
                    [job]="job"
                    [disabled]="estimateHelper.jobLoading | async"
                ></app-add-event-button>
            </div>
        </div>
        <div style="overflow-x: scroll">
            <div
                class="p-datatable p-component data-table"
                *ngFor="let event of eventsWithCharges let i = index"
            >
                <div class="p-datatable-wrapper main-wrapper p-md-9 card">
                    <table role="grid" width="100%">
                        <thead class="p-datatable-thead">
                            <tr class="breakdown-header">
                                <th colspan="12" class="event-info">
                                    <div class="event-info-container">
                                        <div class="main-info">
                                            <div>
                                                <span
                                                    class="p-text-bold p-mb-1 freya-link-button"
                                                    (click)="
                                                        openEvent(
                                                            event.event?.id
                                                        )
                                                    "
                                                >
                                                    {{ event.event.title }}
                                                </span>
                                                <p-tag
                                                    *ngIf="event.event"
                                                    severity="success"
                                                    class="p-ml-2"
                                                    value="{{
                                                        event.event
                                                            .discountedSubTotal /
                                                            100
                                                            | currency
                                                                : job?.currency ||
                                                                      'USD'
                                                    }}"
                                                >
                                                </p-tag>
                                                <p-tag
                                                    *ngIf="
                                                        event.event &&
                                                        event.discountTotal
                                                    "
                                                    severity="warning"
                                                    class="p-ml-2"
                                                    value="-{{
                                                        event.discountTotal /
                                                            100
                                                            | currency
                                                                : job?.currency ||
                                                                      'USD'
                                                    }}"
                                                >
                                                </p-tag>
                                                <p-tag
                                                    *ngIf="event?.event"
                                                    class="p-ml-2"
                                                    value="{{
                                                        event.event.type
                                                            | titlecase
                                                    }} {{
                                                        event.event.status
                                                            | titlecase
                                                    }}"
                                                ></p-tag>
                                                <p-tag
                                                    *ngIf="
                                                        event?.event
                                                            | eventHasInvoice
                                                    "
                                                    value="Invoiced"
                                                    severity="success"
                                                    [style]="{ 'margin-top': '1rem' }"
                                                ></p-tag>
                                            </div>
                                            <div>
                                                <span class="event-date">{{
                                                    event.event.start
                                                        ? (event.event.start
                                                          | freyaDate
                                                              : "h:mm a, MMM d")
                                                        : "Unscheduled"
                                                }}</span>
                                            </div>
                                        </div>
                                        <button *hasPermission="['calendarEvents.edit']"
                                            pButton type="button" icon="pi pi-ellipsis-v"
                                            class="p-button-icon-only p-button-text ellipsis-button"
						                    (click)="setEventActions(event, i); eventActionsMenu.toggle($event)">
					                    </button>
                                        <button
                                            type="button"
                                            pButton
                                            pRipple
                                            class="p-button-text p-button-rounded p-button-plain event-row-button"
                                            [pTooltip]="isEventCollapsed(event?.event?.id) ? 'Expand Event' : 'Collapse Event'"
                                            [icon]="isEventCollapsed(event?.event?.id)
                                                ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                                            (click)="toggleEvent(event?.event?.id)"
                                        ></button>
                                        <button
                                            pButton
                                            class="p-button-primary event-row-button"
                                            icon="pi pi-save"
                                            (click)="unsavedWarningClick()"
                                            [disabled]="jobLoading || !(event?.unsavedChanges || isEventsOrderModified)"
                                        ></button>
                                    </div>
                                    <!-- <label *ngIf="event.event?.start; else notScheduled">Start / End: {{ event.event | eventDuration }}</label>
                                    <ng-template #notScheduled>
                                        <label>Not Scheduled</label>
                                    </ng-template> -->
                                </th>
                            </tr>
                        </thead>
                        <tbody
                            *ngIf="!(isEventCollapsed(event?.id))"
                            class="p-datatable-tbody"
                            cdkDropList
                            [cdkDropListConnectedTo]="validEventIds"
                            [cdkDropListData]="event"
                            (cdkDropListDropped)="drop($event)"
                            [id]="event.event.id"
                            [cdkDropListDisabled]="
                                disabled ||
                                lockedEvents[event?.event.id] ||
                                (event?.event | eventHasInvoice)
                            "
                        >
                            <tr
                                *ngIf="eventTypeInfoMap[event?.event.type]?.revenueGenerating"
                                >
                                <td colspan="12">
                                    <div
                                        class="charges-container add-buttons"
                                        >
                                        <app-estimate-products
                                            [availableProducts]="availableProducts"
                                            [job]="job"
                                            [productsLoading]="productsLoading"
                                            [eventId]="event.event.id"
                                            [eventTitle]="event.event.title"
                                            [eventStart]="event.event.start"
                                                (chargesAddedFromModal)="
                                                handleChargesAdded($event)"
                                                (createCustomChargeClicked)="
                                                createCustomCharge(
                                                $event.productSearch,
                                                event.id)"
                                            [disabled]="
                                                disableModifying(event).chargesDisabled
                                                || jobLoading
                                                || (estimateHelper.jobLoading | async)"
                                            [disabledToolTip]="
                                            disableModifying(event).disabledToolTip"
                                            >
                                        </app-estimate-products>
                                        <button
                                            *hasPermission="['jobs.update']"
                                            pButton type="button" label="Add Discounts"
					                        icon="pi pi-plus" (click)="openDiscountsModal(addDiscountsChild)"
                                            [disabled]="
                                                disableModifying(event).discountsDisabled
                                                || jobLoading
                                                || (estimateHelper.jobLoading | async)"
					                        [pTooltip]="disableModifying(event).disabledToolTip"
                                            tooltipPosition="left">
				                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr
                                class="event-row"
                                [class.hidden]="!event?.charges.length"
                            >
                                <td class="fixed-width hidden-column"></td>
                                <td class="hidden-column">
                                    Charges
                                </td>
                                <td class="hidden-column">
                                    Rate
                                </td>
                                <td class="hidden-column">
                                    Quantity
                                </td>
                                <td class="hidden-column subtotal-column">
                                    Subtotal
                                </td>
                                <td></td>
                            </tr>
                            <tr *ngIf="loadingNewJob">
                                <td></td>
                                <td>
                                    <p-skeleton
                                        styleClass="p-mb-1"
                                        width="10rem"
                                        height="1.5rem"
                                    ></p-skeleton>
                                    <p-skeleton
                                        width="4rem"
                                        height="1.5rem"
                                    ></p-skeleton>
                                </td>
                                <td>
                                    <p-skeleton
                                        width="4rem"
                                        height="1.5rem"
                                    ></p-skeleton>
                                </td>
                                <td>
                                    <p-skeleton
                                        width="4rem"
                                        height="1.5rem"
                                    ></p-skeleton>
                                </td>
                                <td>
                                    <p-skeleton
                                        width="4rem"
                                        height="1.5rem"
                                    ></p-skeleton>
                                </td>
                                <td>
                                    <p-skeleton
                                        width="4rem"
                                        height="1.5rem"
                                    ></p-skeleton>
                                </td>
                            </tr>
                            <tr
                                *ngFor="let charge of event.charges"
                                cdkDrag
                                [cdkDragStartDelay]="
                                    responsiveHelper.isSmallScreen
                                        ? responsiveHelper.dragDelay
                                        : undefined
                                "
                                [cdkDragData]="charge"
                                [cdkDragPreviewContainer]="previewContainer"
                                (cdkDragEntered)="handleDragEnter($event)"
                                class="selectable-row"
                            >
                                <td
                                    class="fixed-width color-border"
                                    cdkDragHandle
                                    [style.border-right-color]="
                                        charge.product?.metadata.color
                                    "
                                >
                                    <button
                                        pButton
                                        class="p-button-text p-button-rounded p-button-plain p-button-lg"
                                        icon="pi pi-bars"
                                    ></button>
                                </td>
                                <td
                                    (click)="openCharge(charge)"
                                    class="charge-name"
                                >
                                <div class="charge-name-wrapper">
                                    <p class="p-text-bold p-mb-1">
                                    {{
                                    (charge?.product?.name || charge.productName)
                                    }}
                                    </p>
                                </div>
                                <label
                                    >{{
                                    charge?.product?.category
                                    ? charge?.product?.category
                                    : "None"
                                    }}
                                </label>
                                    <br />
                                    <p class="subtotal-label">
                                    Subtotal: &nbsp;
                                    {{
                                    charge.chargeSubTotal / 100
                                    | currency
                                    : job?.currency || "USD"
                                    }}
                                    </p>
                                </td>
                                <td
                                    *ngIf="
                                        charge?.price?.priceType !==
                                        'percentage'
                                    "
                                    class="stack-vertically input-with-subtotal"
                                >
                                    <button pButton type="button" icon="pi pi-ellipsis-v"
                                        class="p-button-icon-only p-button-text ellipsis-button mobile-visible"
                                        (click)="setChargeActions(event, charge); chargeActionsMenu.toggle($event)"
                                        [disabled]="disableModifying(event).chargesDisabled">
                                    </button>
                                    <p-inputNumber
                                        [(ngModel)]="
                                            editableAmounts[
                                                charge.id || charge.key
                                            ]
                                        "
                                        (ngModelChange)="
                                            handleQuantityChange(event, charge)
                                        "
                                        class="mid-width-number-input
                                            mid-width-number-input--adjustable"
                                        (click)="handleInputClick($event)"
                                        mode="currency"
                                        [currency]="job?.currency || 'USD'"
                                        [locale]="
                                            job?.currency === 'CAD'
                                                ? 'en-CA'
                                                : 'en-US'
                                        "
                                        [inputStyleClass]="(charge.chargeSubTotal / 100) > subtotalLimit ? 'p-invalid-input' : ''"
                                    ></p-inputNumber>
                                </td>
                                <td
                                    *ngIf="
                                        charge?.price?.priceType ===
                                        'percentage'
                                    "
                                    class="stack-vertically"
                                >
                                    <button pButton type="button" icon="pi pi-ellipsis-v"
                                        class="p-button-icon-only p-button-text ellipsis-button mobile-visible"
						                (click)="setChargeActions(event, charge); chargeActionsMenu.toggle($event)"
						                [disabled]="disableModifying(event).chargesDisabled">
					                </button>
                                    <p-inputNumber
                                        [(ngModel)]="
                                            editableAmounts[
                                                charge.id || charge.key
                                            ]
                                        "
                                        (ngModelChange)="
                                            handleQuantityChange(event, charge)
                                        "
                                        class="mid-width-number-input
                                            mid-width-number-input--adjustable"
                                        type="number"
                                        (click)="handleInputClick($event)"
                                        suffix="%"
                                        max="100"
                                        min="0"
                                    ></p-inputNumber>
                                </td>
                                <td
                                    *ngIf="
                                        charge?.price?.priceType !==
                                        'percentage'
                                    "
                                    class="stack-vertically"
                                >
                                    <button
                                        pButton
                                        type="button"
                                        (click)="
                                        handleRemoveCharge(event, charge)
                                        "
                                        class="p-button-danger delete-charge
                                            delete-charge-decrement-replacement mobile-visible"
                                        [class.delete-button-hidden]="
                                            charge.quantity > 1
                                        "
                                        icon="pi pi-trash"
                                        [disabled]="
                                        (estimateHelper.jobLoading
                                            | async) ||
                                        lockedEvents[event.id]"
                                    ></button>
                                    <p-inputNumber
                                        class="short-number-input mid-width-number-input--adjustable"
                                        type="number"
                                        [minFractionDigits]="1"
                                        [maxFractionDigits]="2"
                                        mode="decimal"
                                        [showButtons]="true"
                                        buttonLayout="horizontal"
                                        min="0"
                                        incrementButtonIcon="pi pi-plus"
                                        decrementButtonIcon="pi pi-minus"
                                        [decrementButtonClass]="
                                            (charge.quantity === 0 || charge.quantity === 1 || !charge.quantity)
                                                ? 'p-button-secondary decrement-button-hidden'
                                                : 'p-button-secondary'
                                        "
                                        incrementButtonClass="p-button-success"
                                        [(ngModel)]="charge.quantity"
                                        (ngModelChange)="
                                            handleQuantityChange(event, charge)
                                        "
                                        (click)="handleInputClick($event)"
                                        [disabled]="
                                            (estimateHelper.jobLoading
                                                | async) ||
                                            lockedEvents[event.id]
                                        "
                                        [inputStyleClass]="(charge.chargeSubTotal / 100) > subtotalLimit ? 'p-invalid-input' : ''"
                                    ></p-inputNumber>
                                </td>
                                <td
                                    *ngIf="
                                        charge?.price?.priceType ===
                                        'percentage'
                                    "
                                    class="stack-vertically percentage-quantity"
                                >
                                    <button
                                        pButton
                                        type="button"
                                        (click)="
                                            handleRemoveCharge(event, charge)
                                        "
                                        class="p-button-danger delete-charge percentage-delete-button"
                                        icon="pi pi-trash"
                                        [disabled]="
                                            (estimateHelper.jobLoading
                                                | async) ||
                                            lockedEvents[event.id]
                                        "
                                    ></button>
                                    <p>N/A</p>
                                </td>
                                <td class="subtotal-column">
                                    <span
                                        [class.error-text]="
                                            (charge.chargeSubTotal / 100) >
                                            subtotalLimit"
                                        >
                                        {{
                                        charge.chargeSubTotal / 100
                                            | currency : job?.currency || "USD"
                                        }}
                                    </span>
                                </td>
                                <td
                                    *hasPermission="['jobs.update']"
                                    class="hidden-column"
                                >
                                    <div class="charge-actions">
                                        <button pButton type="button" icon="pi pi-ellipsis-v"
                                            class="p-button-icon-only p-button-text ellipsis-button"
						                    (click)="setChargeActions(event, charge); chargeActionsMenu.toggle($event)"
						                    [disabled]="disableModifying(event).chargesDisabled">
					                    </button>
                                        <!--<button
                                            pButton
                                            type="button"
                                            (click)="
                                                handleRemoveCharge(event, charge)
                                            "
                                            class="p-button-danger delete-charge"
                                            icon="pi pi-trash"
                                            [disabled]="
                                                (estimateHelper.jobLoading | async) ||
                                                lockedEvents[event.id]"
                                        ></button>-->
                                        <!--<span
                                            *ngIf="
                                                productRuleService.isAutoCharge(
                                                    charge
                                                )
                                            "
                                            class="charge-mode-auto"
                                            (click)="openChargeRule(charge)"
                                        >
                                            AUTO
                                        </span>
                                        <button
                                            *ngIf="
                                                productRuleService.isManualCharge(
                                                    charge
                                                )
                                            "
                                            pButton
                                            type="button"
                                            label="RESET"
                                            class="p-button-text charge-mode-manual"
                                            (click)="
                                                setChargeToAuto(charge);
                                                $event.stopPropagation()
                                            "
                                            [disabled]="
                                                estimateHelper.jobLoading
                                                    | async
                                            "
                                        ></button>-->
                                    </div>
                                </td>
                            </tr>
                            <tr [class.hidden]="
                                event?.charges?.length
                                || event?.event?.discounts?.length
                                || !eventTypeInfoMap[event?.event?.type]?.revenueGenerating">
                                <td colspan="12">
                                    <div class="flex">
                                        <div class="charges-container">
                                            <p
                                                *ngIf="
                                                    !loadingNewJob &&
                                                    event?.charges.length === 0
                                                "
                                            >
                                                No charges or discounts added
                                            </p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="12" class="no-padding">
                                    <app-estimate-discounts
                                        #addDiscountsChild
                                        [job]="job"
                                        [currentEventId]="
                                            event.event.id"
                                            [readonly]="readonly"
                                            [archived]="readonly"
                                            discountsPerEvent="true"
                                            [disabled]="
                                                disableModifying(event).discountsDisabled
                                                || jobLoading
                                                || (estimateHelper.jobLoading | async)"
                                            [addButtonDisabledTooltip]="
                                                disableModifying(event).disabledToolTip"
                                    ></app-estimate-discounts>
                                </td>
                            </tr>
                        </tbody>
                        <tbody
                            *ngIf="
                                !existingEventIds || !existingEventIds.length
                            "
                        >
                            <tr>
                                <td colspan="6">
                                    <div class="p-py-2 p-text-center">
                                        <p-message
                                            severity="warn"
                                            text="No events added. Add an event to start adding charges."
                                        ></p-message>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody
                            class="p-datatable-tbody"
                            #previewContainer
                        ></tbody>
                    </table>
                </div>
                <div class="right-cards-container" *ngIf="!(isEventCollapsed(event?.id))">
                    <p-messages
                        *ngIf="
                            disableModifying(event).disabledWarning"
                        severity="warn"
                        [styleClass]="
                            disableModifying(event).clickable
                                ? 'clickable-warning'
                                : ''"
                        (click)="unsavedWarningClick()"
                        [disabled]="jobLoading"
                        >
                        <ng-template pTemplate>
                            <div>
                                {{ disableModifying(event).disabledWarning }}

                                <div class="p-mt-2 p-text-center">
                                    <button
                                        *ngIf="lockedEvents[event?.event?.id]"
                                        pButton
                                        [label]="contactAccountingButtonText"
                                        icon="pi pi-send"
                                        class="p-button-warning p-button-outlined"
                                        (click)="contactAccounting(event)"
                                        ></button>
                                </div>
                            </div>
                        </ng-template>
                    </p-messages>
                    <div class="card">
                        <div class="card-header" (click)="toggle(collapsedEventFinancialsIds, event?.event?.id)">
                            <h6>Event Financials  {{
                                event?.total / 100
                                    | currency : event?.event?.currency || "USD"
                            }}</h6>
                            <button
                                type="button"
                                pButton
                                pRipple
                                [pTooltip]="isEventFinancialsCollapsed(event?.event?.id)
                                    ? 'Collapse Event Financials' : 'Expand Event Financials'"
                                tooltipPosition="left"
                                class="p-button-text p-button-rounded p-button-plain"
                                [icon]="isEventFinancialsCollapsed(event?.event?.id) ? 'pi pi-chevron-right' : 'pi pi-chevron-down'"
                            ></button>
                        </div>
                        <div *ngIf="isEventFinancialsCollapsed(event?.event?.id)">
                            <p-divider />
                            <app-financials-table
                                [loading]="!event"
                                [item]="event.event"
                                [showPaid]="false"
                            >
                            </app-financials-table>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" (click)="toggle(collapsedEventAssetsIds, event?.event?.id)">
                            <h6>Assets</h6>
                            <button
                                type="button"
                                pButton
                                pRipple
                                [pTooltip]="isEventAssetsCollapsed(event?.event?.id)
                                    ? 'Colapse Assets' : 'Expand Assets'"
                                tooltipPosition="left"
                                class="p-button-text p-button-rounded p-button-plain"
                                [icon]="isEventAssetsCollapsed(event?.event?.id) ? 'pi pi-chevron-right' : 'pi pi-chevron-down'"
                            ></button>
                        </div>
                        <div *ngIf="isEventAssetsCollapsed(event?.event?.id)">
                            <p-divider />
                            <div *ngFor="let asset of event.event.assets">
                                <p>
                                    {{ asset.name }}
                                    <p-tag [value]="asset.type"></p-tag>
                                </p>
                            </div>
                            <div *ngIf="!event.event.assets?.length">
                                No Assets Assigned.
                            </div>
                        </div>
                    </div>
                    <!--display job financials only for last event in array-->
                    <div *ngIf="i === eventsWithCharges.length - 1" class="card">
                        <div class="card-header" (click)="toggle(collapsedJobFinancialsIds, event?.event?.id)">
                            <h6>Job Financials  {{
                                job?.discountedSubTotal / 100
                                    | currency : job?.currency || "USD"
                            }}</h6>
                            <button
                                type="button"
                                pButton
                                pRipple
                                [pTooltip]="isJobFinancialsCollapsed(event?.event?.id)
                                    ? 'Collapse Job Financials': 'Expand Job Financials'"
                                tooltipPosition="left"
                                class="p-button-text p-button-rounded p-button-plain"
                                [icon]="isJobFinancialsCollapsed(event?.event?.id)
                                    ? 'pi pi-chevron-right' : 'pi pi-chevron-down'"
                            ></button>
                        </div>
                        <div *ngIf="isJobFinancialsCollapsed(event?.event?.id)">
                            <p-divider />
                            <app-financials-table
                                [loading]="!job"
                                [item]="job"
                                [showPaid]="false"
                            >
                            </app-financials-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<p-slideMenu
    #chargeActionsMenu appendTo="body"
    [popup]="true" [model]="chargeActions"
    [menuWidth]="menuWidth" (outsideClick)="closeChargeActionsMenu()">
</p-slideMenu>

<p-menu #eventActionsMenu appendTo="body" [popup]="true" [model]="eventActions"></p-menu>
