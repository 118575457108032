
import { Clipboard } from '@angular/cdk/clipboard';
import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { createAction, props } from '@ngrx/store';

import { map } from 'rxjs';

import { BaseTokenFragment } from '../../../generated/graphql.generated';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';

export const IcalTokenCreatedAction = createAction(`[ICAL] Token Created`, props<{
	token: BaseTokenFragment,
	url: string,
}>());

export const afterIcalTokenCreatedEffect = createEffect((
	actions$ = inject(Actions),
	localNotify = inject(FreyaNotificationsService),
	clipboard = inject(Clipboard),
) => {

    return actions$.pipe(
		ofType(IcalTokenCreatedAction),
		map(async ({ token, url }) => {

			clipboard.copy(url);

			localNotify.addToast.next({
				severity: 'success',
				summary: 'ICal URL Copied to clipboard',
				detail: `Import this as a URL in the calendar of your choice`,
			});
		}),
	);

}, { functional: true, dispatch: false });

export const icalStateEffects = {
	afterIcalTokenCreatedEffect,
};
