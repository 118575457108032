import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import {QueryRef} from 'apollo-angular';

import { LazyLoadEvent } from 'primeng/api';
import { SubSink } from 'subsink';

import {
  AttentionItem,
  AttentionItemsFilter,
  AttentionItemsGQL,
  AttentionItemsQuery,
  AttentionItemsQueryVariables,
  ZoneDir,
} from '../../../generated/graphql.generated';
import { ATTENTION_ACTION_ICONS } from '../../global.constants';
import { DetailsHelperService } from '../../services/details-helper.service';
import { PermissionService } from '../../services/permission.service';
import { WatchQueryHelper } from '../../utilities/watchQueryHelper';
import { AttentionItemsService } from '../attention-items.service';

@Component({
  selector: 'app-attention-items',
  templateUrl: './attention-items.component.html',
  styleUrls: ['./attention-items.component.scss']
})
export class AttentionItemsComponent implements OnInit, OnChanges, OnDestroy {

  @Input() filter: AttentionItemsFilter = {};

  @Input() maxExplanations = 3;

  subs = new SubSink();

  attentionItemsQuery: QueryRef<AttentionItemsQuery, AttentionItemsQueryVariables>;

  attentionItemsQH: WatchQueryHelper = {
    limit: 10,
    skip: 0,
    loading: true,
  };

  attentionItems: AttentionItem[] = [];

  buttonStyle = {
    width: '11rem',
  };

  processing: { [attentionItemId: string]: boolean } = {};

  actionButtonIcons = ATTENTION_ACTION_ICONS;

  permissions = {
    ['complete-event']: false,
    ['generate-invoice']: false,
    ['process-job']: false,
  };

  constructor(
    private attentionItemsGQL: AttentionItemsGQL,
    private detailsHelper: DetailsHelperService,
    private permissionHandler: PermissionService,
    public attentionItemsService: AttentionItemsService,
  ) { }

  ngOnInit(): void {
    this.fetchAttentionItems();

    this.subs.sink = this.detailsHelper.getObjectUpdates([ 'Jobs', 'Events' ])
      .subscribe(() => this.fetchAttentionItems());

    this.setPermissions();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('filter' in changes) {
      this.attentionItemsQH.skip = 0;
      this.fetchAttentionItems();
    }
  }

  setPermissions() {
    this.subs.sink = this.permissionHandler.watchPermissions([
      'calendarEvents.edit',
      'invoices.create',
      'frontend.estimating',
    ]).subscribe((res) => {
      this.permissions['complete-event'] = res[0];
      this.permissions['generate-invoice'] = res[1];
      this.permissions['process-job'] = res[2];
    });
  }

  fetchAttentionItems() {

      if (this.attentionItemsQuery) {
        this.attentionItemsQuery.refetch(this.getQueryVars());
        return;
      }

      this.attentionItemsQuery = this.attentionItemsGQL.watch(this.getQueryVars(), {
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true,
      });

      this.subs.sink = this.attentionItemsQuery.valueChanges
        .subscribe((res) => {

          this.attentionItemsQH.loading = res.loading;

          if (res.loading) { return; }

          this.attentionItems = res.data.attentionItems.items as AttentionItem[];

          this.attentionItemsQH.total = res.data.attentionItems.total;
        });
  }

  goToPage(event: LazyLoadEvent) {
    this.attentionItemsQH.limit = event.rows;
    this.attentionItemsQH.skip = event.first;

    this.fetchAttentionItems();
  }

  getQueryVars(): AttentionItemsQueryVariables {
    return {
      limit: this.attentionItemsQH.limit,
      skip: this.attentionItemsQH.skip,
      filter: {
        ...this.filter,
        zoneDir: ZoneDir.Lte,
      },
    };
  }

  performItemAction(item: AttentionItem) {
    if (this.processing[item.id]) { return; }

    this.processing[item.id] = true;

    this.attentionItemsService.performItemAction(item, () => {
      this.processing[item.id] = false;

      if (item.action === 'complete-event') {
        this.fetchAttentionItems();
      }
    });
  }

}
