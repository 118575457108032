<div [formGroup]="form">
  <ng-template #assets>
    <div *appVar="form.get('assets') as control">
      <label>Assets</label>
      <br />
      <p-multiSelect
        [options]="allAssets"
        formControlName="assets"
        optionLabel="name"
        optionValue="id"
        width="100%"
        [filter]="true"
        appendTo="body"
        display="chip"
        filterBy="name"
        [showClear]="true"
        placeholder="Select assets"
      >
      </p-multiSelect>
      <app-validation-messages [control]="control"> </app-validation-messages>
    </div>
  </ng-template>
  <ng-template #eventTypes>
    <div *appVar="form.get('eventTypes') as control">
      <label>Event Types</label>
      <br />
      <p-multiSelect
        [options]="allEventTypes"
        formControlName="eventTypes"
        display="chip"
        appendTo="body"
        filterBy="name"
        [showClear]="true"
        placeholder="Select assets"
      >
      </p-multiSelect>
      <app-validation-messages [control]="control"> </app-validation-messages>
    </div>
  </ng-template>
  <ng-template #name>
    <div *appVar="form.get('name') as control">
      <label>Name</label>
      <br />
      <input
        type="text"
        pInputText
        placeholder="Name"
        formControlName="name"
        (focus)="generateNameIfEmpty()"
        [class.p-invalid-input]="!control.valid && control.touched"
      />
      <app-validation-messages [control]="control"> </app-validation-messages>

      <div>
        <br />
        <span>This name will show on your calendar and cannot be changed.</span>
        <br /><br />
        <strong>️ 🚨🛡️ SECURITY WARNING!️ 🛡️🚨 </strong><br />
        <span>
          Keep this link private! It contains up to date customer data.<br />
          If you suspect a link has been compromised contact the Move Right
          staff immediately.<br />
        </span>
        <span>🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨</span>

        <br />
        <br />
        <strong>Instructions for Google Calendar:</strong><br />
        <ol>
          <li>In the top right click the gear icon then click "Settings"</li>
          <li>In the left sidebar click "Add Calendar" > "From URL"</li>
          <li>
            After creating this calendar the URL will be in your clipboard.
            Paste this value into the section that says "From URL".
          </li>
          <li>DO NOT make the calendar publicly accessible.</li>
          <li>Click "Add Calendar" and navigate back to your calendar.</li>
        </ol>
      </div>
    </div>
  </ng-template>
</div>

<app-mutate-object
  #mutate
  [fg]="form"
  objectType="iCal URL"
  [mutateType]="mutateType"
  [steps]="steps"
  (objectMutated)="mutateObject()"
></app-mutate-object>
