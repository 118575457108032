import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';


import { FREYA_ROUTES } from './global.constants';
import { AppLoadingService } from './services/app-loading.service';
import { PermissionService } from './services/permission.service';
import { valueAsArray } from './utilities/arrays.util';

/**
 * This guard will check for permissions.
 * How to Use: Add to the route you want to guard and pass the permissions you want to check in data.permissions
 */
@Injectable({
  providedIn: 'root'
})
export class RoutePermissionGuard  implements OnDestroy {

  subscription: Subscription;

  constructor(
    private router: Router,
    private appLoading: AppLoadingService,
    private permissionHandler: PermissionService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const accessDeniedURL = `/${ FREYA_ROUTES.accessDenied }?url=${ encodeURIComponent('/' + route.url.join('/')) }`;

    if(!route.data.permissions?.length && !environment.production) {
      console.warn(`data.permissions not set for permission guard on route: ${accessDeniedURL}`);
      return this.router.parseUrl(accessDeniedURL);
    }

    return new Promise(async (resolve, reject) => {
      await this.appLoading.appFullyLoaded;

      const permissions: string[] = valueAsArray(route.data.permissions);

      // Check the cache first, if we have the permissions we can resolve immediately
      if (this.permissionHandler.checkPermissions(permissions)){
        return resolve(true);
      }

      // If we don't have the permissions in the cache, we need to check the server
      this.subscription = this.permissionHandler.watchPermissions(permissions).subscribe((res) => {
        if ((res as boolean[]).some(per => per === true)) {
          return resolve(true);
        } else {
          debugger;
          return resolve(this.router.parseUrl(accessDeniedURL));
        }
      });
    });
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.canActivate(childRoute, state);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
