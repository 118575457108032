import { Pipe, PipeTransform } from '@angular/core';

import { AttentionItem } from '../../generated/graphql.generated';
import { strToTitleCase } from '../js';
import { FreyaHelperService } from '../services/freya-helper.service';
import { getJobCustomer } from '../utilities/job-customer.util';

import { getEventCustomerName } from './event-location/calendarevent.util';
import { FreyaDatePipe } from './freya-date.pipe';

@Pipe({
  name: 'attentionItemName'
})
export class AttentionItemNamePipe implements PipeTransform {

  constructor(
    private freyaHelper: FreyaHelperService,
    private freyaDate: FreyaDatePipe,
  ) {}

  transform(item: AttentionItem): string {
    switch (item.__typename) {
      case 'Job':
        const jobName = [strToTitleCase(item?.stage), item?.code].filter(Boolean).join(' | ');
        const customerName = getJobCustomer(item.users, true);
        return `${jobName} for ${customerName}`;
      case 'CalendarEvent':
        const { eventStart } = this.freyaHelper.getCalendarEventStartandEnd(item);

        const startDate = eventStart ? this.freyaDate.transform(eventStart, 'MMM d') : '';

        return `${startDate} ${strToTitleCase(item.type)} for ${getEventCustomerName(item)}`;
      case 'User':
        return `${item.givenName} ${item.familyName}`;
    }
  }

}
