import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { FreyaCommonModule } from "src/app/freya-common/freya-common.module";
import { SharedModule } from "src/app/shared/shared.module";
import { workOrdersSelectors } from "../../job-state/workorders-state/workorders.selectors";
import { EstimatesJobFragment } from "graphql.generated";
import { CalendarEventWithLockedAndInvoicedFlag } from "../../jobv2-create/jobv2-interfaces";
import { calculateJobTotals } from "../../job-calculator";
import { SubSink } from "subsink";


@Component({
  selector: 'app-financials-table-v2',
  standalone: true,
  imports: [
    SharedModule,
    FreyaCommonModule,
  ],
  templateUrl: './financials-table-v2.component.html',
  styleUrl: './financials-table-v2.component.scss',
})
export class FinancialsTableV2Component implements OnInit, OnDestroy {

  @Input() eventId: string;

  job$ = this.store.select(workOrdersSelectors.selectJobWithPendingChargesUpdates);

  job: EstimatesJobFragment;
  event: CalendarEventWithLockedAndInvoicedFlag;
  showConfidentialInfo = false;

  constructor(
    public store: Store,
  ) { }

  private subs = new SubSink();

  ngOnInit(): void {
    this.subs.sink = this.job$.subscribe((job) => {
      if (job) {

        this.job = calculateJobTotals(job);

        this.event = this.job?.events?.find(
          (e): e is CalendarEventWithLockedAndInvoicedFlag => e?.id === this.eventId
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  getJobGrossProfitMargin(data: any): number {
    if (!data || !data.discountedSubTotal || !data.expenseTotal) {
      return 0;
    }
    return ((data.discountedSubTotal - data.expenseTotal) / data.discountedSubTotal) * 100;
  }
}