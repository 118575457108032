<div [formGroup]="closeJobForm">
    <p>Select closure reason:</p>
    <label>Reason</label>
    <br>
    <p-dropdown
        appendTo="body"
        placeholder="Select Closure Reason"
        [options]="closureReasons$ | async"
        formControlName="reason"
        data-cy="reason-drop-down-field"
        >
    </p-dropdown>
        <div class="footer">
            <button
                pButton
                label="Exit Dialog"
                icon="pi pi-times"
                (click)="closeDialog(false)"
                data-cy="exit-window-action-button"
                ></button>
            <button
                class="p-button-success"
                pButton
                [disabled]="closeJobForm.invalid"
                label="Close Job"
                icon="pi pi-circle-fill"
                (click)="closeJob()"
                data-cy="close-job-action-button"
                ></button>
        </div>
</div>
