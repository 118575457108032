<section>
  <header>
    <div>
      <h2><i class="pi pi-map p-mr-1"></i> Locations</h2>
      <div
        class="job-location-info"
        *ngIf="jobLoaded && !isEditMode"
      >
        <span
          *ngIf="canChangeArea$ | async"
          class="freya-link-button"
          (click)="changeArea()"
          pTooltip="Change area"
          tooltipPosition="bottom"
          > {{ job?.zone?.name }} </span>
        <span
          *ngIf="(canChangeArea$ | async) === false"
          pTooltip="Cannot change area"
          tooltipPosition="bottom"
          >
          {{ job?.zone?.name }}
        </span>
        <span class="separator">|</span>
        <span>Dock: {{ jobLocations?.dock?.addressLineOne || 'N/A' }}</span>
      </div>
    </div>
    <div class="flex">
      <p-button
        class="p-mr-2"
        [disabled]="!jobLocations?.start || !jobLocations?.end"
        label="Directions"
        icon="pi pi-directions"
        [outlined]="true"
        (onClick)="openDirections()"
      ></p-button>
      <p-button
        class="editButton"
        *ngIf="!isEditMode; else cancelButton"
        icon="pi pi-pencil"
        [text]="true"
        (onClick)="editLocations()"
        [disabled]="!jobLoaded"
      />
    </div>
  </header>
  <ng-container *ngIf="jobLoaded; else loading">
    <main
      class="p-mt-4"
      *ngIf="!isEditMode; else editLocationsTemplate"
    >
      <div
        class="locationDetails"
        *ngIf="jobLocations"
      >
        <p-panel
          *ngIf="jobLocations.start"
          [toggleable]="true"
          [collapsed]="true"
        >
          <ng-template pTemplate="header">
            <div class="locationPanelHeader">
              <span class="address">Start: {{ jobLocations.start.addressString }}</span>
              <span class="details">{{ jobLocations.start.details }}</span>
            </div>
          </ng-template>
          <ng-template
            pTemplate="headericons"
            let-collapsed
          >
            <button class="p-panel-header-icon p-panel-toggler p-link">
              <span
                class="pi"
                [ngClass]="{
                'pi-chevron-down': collapsed,
                'pi-chevron-up': !collapsed,
              }"
              ></span>
            </button>
          </ng-template>
          <div class="locationPanelContent">
            <span>Access: {{ jobLocations.start.accessInformation }}</span>
            <span>Parking: {{ jobLocations.start.parkingInformation }}</span>
            <p-button
              label="Directions"
              icon="pi pi-directions"
              [outlined]="true"
              (onClick)="viewLocationOnGoogleMaps(jobLocations.start)"
            ></p-button>
          </div>
        </p-panel>
        <i
          class="pi pi-arrow-down"
          *ngIf="
          (jobLocations.start && jobLocations.end) ||
          (jobLocations.start && jobLocations.otherStops.length)
        "
        ></i>
        <ng-container *ngFor="let stop of jobLocations.otherStops">
          <p-panel
            [toggleable]="true"
            [collapsed]="true"
          >
            <ng-template pTemplate="header">
              <div class="locationPanelHeader">
                <span class="address">Stop: {{ stop.addressString }}</span>
                <span class="details">{{ stop.locationType }}</span>
              </div>
            </ng-template>
            <ng-template
              pTemplate="headericons"
              let-collapsed
            >
              <button class="p-panel-header-icon p-panel-toggler p-link">
                <span
                  class="pi"
                  [ngClass]="{
                  'pi-chevron-down': collapsed,
                  'pi-chevron-up': !collapsed,
                }"
                ></span>
              </button>
            </ng-template>
            <div class="locationPanelContent">
              <p-button
                label="Directions"
                icon="pi pi-directions"
                [outlined]="true"
                (onClick)="viewLocationOnGoogleMaps(stop)"
              ></p-button>
            </div>
          </p-panel>
          <i class="pi pi-arrow-down"></i>
        </ng-container>
        <p-panel
          *ngIf="jobLocations.end"
          [toggleable]="true"
          [collapsed]="true"
        >
          <ng-template pTemplate="header">
            <div class="locationPanelHeader">
              <span class="address">End: {{ jobLocations.end.addressString }}</span>
              <span class="details">{{ jobLocations.end.details }}</span>
            </div>
          </ng-template>
          <ng-template
            pTemplate="headericons"
            let-collapsed
          >
            <button class="p-panel-header-icon p-panel-toggler p-link">
              <span
                class="pi"
                [ngClass]="{
                'pi-chevron-down': collapsed,
                'pi-chevron-up': !collapsed,
              }"
              ></span>
            </button>
          </ng-template>
          <div class="locationPanelContent">
            <span>Access: {{ jobLocations.end.accessInformation }}</span>
            <span>Parking: {{ jobLocations.end.parkingInformation }}</span>
            <p-button
              label="Directions"
              icon="pi pi-directions"
              [outlined]="true"
              (onClick)="viewLocationOnGoogleMaps(jobLocations.end)"
            ></p-button>
          </div>
        </p-panel>
      </div>
      <div class="locationMap">
        <app-travel-map></app-travel-map>
        <app-travel-time></app-travel-time>
      </div>
    </main>
  </ng-container>
</section>

<ng-template #cancelButton>
  <div class="flex">
    <p-button
      class="cancelButton p-mr-2"
      [outlined]="true"
      label="Cancel"
      (onClick)="cancelEditLocations()"
    />
    <p-button
      class="saveButton"
      icon="pi pi-check"
      label="Save"
      (onClick)="updateJob()"
    />
  </div>
</ng-template>

<ng-template #editLocationsTemplate>
  <app-jobv2-create-locations [renderHeadless]="true"></app-jobv2-create-locations>
</ng-template>

<ng-template #loading>
  <main class="p-mt-4">
    <p-skeleton
      class="flex-1"
      *ngFor="let _ of [1, 2]"
      width="100%"
      height="20rem"
    />
  </main>

</ng-template>
