import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { Store } from "@ngrx/store";
import { SharedModule } from "primeng/api";
import { FreyaCommonModule } from "src/app/freya-common/freya-common.module";
import { SubSink } from "subsink";
import { workOrdersSelectors } from "../job-state/workorders-state/workorders.selectors";
import { CalendarEventForSelectEventFragment, Invoice } from "graphql.generated";
import { cloneDeep } from "lodash";
import { EventSuggestion } from "src/app/custom-inputs/event-select/event-select.component";
import { CalendarEventWithLockedAndInvoicedFlag } from "../jobv2-create/jobv2-interfaces";
import { isDraftInvoice, isFinalizedInvoice } from "src/app/invoices/invoices.utils";
import { ResponsiveHelperService } from "src/app/services/responsive-helper.service";
import { AbstractControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from "@angular/forms";
import { eventTypeInfoMapV2 } from "src/app/global.constants";

@Component({
    selector: 'app-event-select-v2',
    standalone: true,
    imports: [SharedModule, FreyaCommonModule],
    templateUrl: './event-select-v2.component.html',
    styleUrl: './event-select-v2.component.scss',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: EventSelectV2Component
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: EventSelectV2Component,
        }
    ]
})

export class EventSelectV2Component implements OnInit, OnDestroy, AfterViewInit {
    @Output() eventsSelected = new EventEmitter();
    @Input() disabledEvents = {
        locked: false,
        incomplete: false,
        hasInvoice: false,
        invoiced: false,
        shouldNotHaveCharges: false,
    };

    @Input() preselectEventId = undefined;

    placeholder = 'Select Events';

    // FORM CONTROL VARIABLES
    selectedEvents: CalendarEventForSelectEventFragment[] = [];
    touched = false;
    disabled = false;

    constructor(
        private store: Store,
        public responsiveHelper: ResponsiveHelperService,
    ) { }

    private subs = new SubSink();

    jobEvents$ = this.store.select(workOrdersSelectors.selectAllActiveJobEventsIncludingUnsaved);
    eventSuggestions: EventSuggestion[] = [];

    ngOnInit(): void {
        this.subs.sink = this.jobEvents$.subscribe((jobEvents) => {
            if (jobEvents?.length) {
                this.setEventSuggestions(jobEvents);
            }
        });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.preselectEvent();
        this.onChange(this.selectedEvents);
    }

    preselectEvent() {
        if (!this.eventSuggestions.length) { return; };

        if (this.preselectEventId) {
            this.selectedEvents = this.eventSuggestions
                .filter((s) => !s.disabled && s.event.id === this.preselectEventId)
                .map((s) => s.event);

            this.selectEvent();
            this.onChange(this.selectedEvents);
        } else {
            //by default first event preselected
            const availableEvents = this.eventSuggestions
                .filter((s) => !s.disabled)
                .map((s) => s.event);

            const [firstAvailableEvent] = availableEvents;

            if (firstAvailableEvent) {
                this.selectedEvents = [firstAvailableEvent];
            }

        }

    }

    setEventSuggestions(events: CalendarEventWithLockedAndInvoicedFlag[]): void {
        if (!events) return;

        events = cloneDeep(events);
        const suggestions: EventSuggestion[] = [];

        for (const event of events) {
            console.log('event', event);
            const eventConditions = {
                locked: event?.end && event?.locked,
                incomplete: event.status !== 'completed',
                hasInvoice: event.invoices.some((i) => !i.deletedAt && !i.voidedAt),
                invoiced: event.invoices.some(isFinalizedInvoice),
                shouldNotHaveCharges: event.type === eventTypeInfoMapV2?.estimating?.value
                    || event.type === eventTypeInfoMapV2?.virtualEstimate?.value
            };

            let disabled = false;

            for (const condition of Object.keys(this.disabledEvents)) {
                if (!this.disabledEvents[condition]) continue;
                if (eventConditions[condition]) {
                    disabled = true;
                    break;
                }
            }

            suggestions.push({
                event,
                disabled,
                label: event.title,
                invoiceStatus: this.getInvoiceStatus(event.invoices),
            });
        }

        this.eventSuggestions = suggestions;

        this.preselectEvent();

        this.onChange(this.selectedEvents);
    }

    getInvoiceStatus(invoices: Invoice[]): 'finalized' | 'draft' | undefined {
        if (invoices.some(isFinalizedInvoice)) return 'finalized';
        if (invoices.some(isDraftInvoice)) return 'draft';
        return undefined;
    }

    // FORM CONTROL FUNCTIONS
    onChange = (selectedEvents) => { };

    onTouched = () => {
        this.touched = true;
    };

    writeValue(events: CalendarEventWithLockedAndInvoicedFlag[]) {
        this.selectedEvents = events ?? [];
    }

    registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: any) {
        this.onTouched = onTouched;
    }

    validate(control: AbstractControl): ValidationErrors | null {
        return null;
    }

    selectEvent(): void {
        this.eventsSelected.emit(this.selectedEvents);
    }
}