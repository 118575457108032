<div class="p-grid workorders-top-section">
    <div class="p-col estimate-container">
        <div>
            <h5>
                {{
                    job?.discountedSubTotal / 100
                        | currency : job?.currency || "USD"
                }}
            </h5>
            <span>
                Estimated total
            </span>
            <span *ngIf="job?.discountTotal > 0">
                | Discounts:
                {{
                    job?.discountTotal / 100
                        | currency : job?.currency || "USD"
                }}
            </span>
            <i
                class="pi pi-info-circle"
                [pTooltip]="discountsTooltipContent">
            </i>
        </div>
        <button
            *hasPermission="['artifacts.create']"
            pButton
            class="p-m-1 workorders-btn-primary"
            label="Create Estimate"
            icon="pi pi-file-plus"
            (click)="openDocuments()"
            [disabled]="jobLoading || job?.closedAt"
        ></button>
        <p-message
            *ngIf="job?.zone?.type !== 'area'"
            severity="warn"
            text="Your job needs to be in an area to apply charges"
            styleClass="mr-2"
        ></p-message>
        <div *ngIf="!job?.users?.length || !job?.users[0].user">
            <p-message
                severity="warn"
                text="Charges will not be saved until you have set the customer"
            ></p-message>
        </div>
    </div>
</div>
<div class="events-top-section">
    <div
        *ngIf="(numberEventsWithUnsavedChanges$ | async) > 0"
    >
        <h5>
            {{ 'Events: ' + (numberEventsWithUnsavedChanges$ | async ) }}
        </h5>
        <i
            class="pi pi-exclamation-triangle">
        </i>
        <span>
            Unsaved changes
        </span>
    </div>
    <div class="p-col p-text-right">
        <div *ngIf="!jobLoading && job?.events.length > 0">
            <button
            pButton
            class="p-button-text p-m-1 workorders-btn-text"
            [icon]="isAllEventsCollapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up'"
            [label]="isAllEventsCollapsed ? 'Expand All' : 'Collapse All'"
            (click)="toggleAllEvents()"
        ></button>
        <app-add-event-button-v2
            *hasPermission="['calendarEvents.create']"
        ></app-add-event-button-v2>
        <button
            pButton
            class="p-button-text p-m-1 workorders-btn-outlined"
            label="Cancel"
            icon="pi pi-times"
            (click)="cancelJobCharges()"
            [pTooltip]="getDiscardTooltipText()"
            [disabled]="!unsavedChanges"
          ></button>
        <button
            pButton
            class="p-m-1 workorders-btn-primary"
            label="Save"
            icon="pi pi-check"
            (click)="saveModifiedCharges()"
            [pTooltip]="getSaveTooltipText()"
            tooltipPosition="left"
            [disabled]="(jobBeingUpdated$ | async) || (jobLoading$ | async) || !unsavedChanges || !isOnline"
          ></button>
        </div>
        <div
          class="dashed-container no-charges-msg"
          *ngIf="!jobLoading && job?.events.length === 0"
          style="margin-left: -2rem"
        >
          <i class="pi pi-inbox p-mr-1" style="font-size: 1.1rem"></i>No
          events added to this job.
          <br />
          <br />
          <app-add-event-button-v2
              *hasPermission="['calendarEvents.create']"
          ></app-add-event-button-v2>
      </div>
    </div>
</div>
<div style="overflow-x: scroll">
    <div
        class="p-datatable p-component data-table"
        *ngFor="let event of eventsWithCharges let i = index; trackBy: trackByEventId"
    >
        <div class="p-datatable-wrapper main-wrapper p-md-12 card">
            <table role="grid" width="100%" class="event-main-container">
                <thead class="p-datatable-thead">
                    <tr class="breakdown-header">
                        <th colspan="12" class="event-info">
                            <div class="event-info-container">
                                <div class="main-info">
                                    <div>
                                        <span
                                            class="p-text-bold p-mb-1 freya-link-button"
                                            (click)="
                                                openEvent(
                                                    event?.id
                                                )
                                            "
                                        >
                                            {{ event.title }}
                                        </span>
                                    </div>
                                    <div>
                                        <span class="event-date">{{
                                            event.start
                                                ? (event.start
                                                  | freyaDate
                                                      : "h:mm a, MMM d")
                                                : "Unscheduled"
                                        }}</span>
                                    </div>
                                </div>
                                <div class="tags-container">
                                    <p-tag
                                            *ngIf="event"
                                            severity="success"
                                            class="p-ml-2 totals-tag"
                                            [pTooltip]="discountsTooltipContent"
                                            value="{{
                                                event
                                                    .discountedSubTotal /
                                                    100
                                                    | currency
                                                        : job?.currency ||
                                                              'USD'
                                            }}"
                                        >
                                        </p-tag>
                                        <p-tag
                                            *ngIf="event"
                                            class="p-ml-2"
                                            severity="info"
                                            value="{{
                                                event.type
                                                    | titlecase
                                            }} {{
                                                event.status
                                                    | titlecase
                                            }}"
                                        ></p-tag>
                                        <p-tag
                                            *ngIf="
                                                event
                                                    | eventHasInvoice
                                            "
                                            value="Invoiced"
                                            severity="success"
                                        ></p-tag>
                                        <button
                                            *hasPermission="['calendarEvents.edit']"
                                            pButton
                                            type="button"
                                            icon="pi pi-ellipsis-v"
                                            class="p-button-icon-only p-button-text ellipsis-button"
                                            (click)="setEventActions(event, i); eventActionsMenu.toggle($event)">
                                        </button>
                                        <button
                                            type="button"
                                            pButton
                                            pRipple
                                            class="p-button-text p-button-rounded p-button-plain event-row-button"
                                            [pTooltip]="isEventCollapsed(event?.id) ? 'Expand Event' : 'Collapse Event'"
                                            [icon]="isEventCollapsed(event?.id)
                                                ? 'pi pi-chevron-down' : 'pi pi-chevron-up'"
                                            (click)="toggleEvent(event?.id)"
                                        ></button>
                                </div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody
                    *ngIf="!(isEventCollapsed(event?.id))"
                    class="p-datatable-tbody"
                    cdkDropList
                    [cdkDropListConnectedTo]="validEventIds"
                    [cdkDropListData]="event"
                    (cdkDropListDropped)="drop($event)"
                    [id]="event.id"
                    [cdkDropListDisabled]="jobLoading || event?.locked || event?.invoiced">
                    <tr
                        *ngIf="eventTypeInfoMap[event.type]?.revenueGenerating"
                        >
                        <td colspan="12">
                            <p-messages *ngIf="getDisabledStatus(event, job).disabledWarning" severity="warn">
                                <ng-template pTemplate>
                                    <div>
                                        {{ getDisabledStatus(event, job).disabledWarning }}
                                        <div class="p-mt-2 p-text-center">
                                            <button
                                                *ngIf="event?.locked"
                                                pButton
                                                [label]="contactAccountingButtonText"
                                                icon="pi pi-send"
                                                class="p-button-warning p-button-outlined"
                                                (click)="contactAccounting(event)">
                                            </button>
                                        </div>
                                    </div>
                                </ng-template>
                            </p-messages>
                            <div class="charges-with-actions">
                                <div>
                                    <h6 class="p-text-bold p-mb-1">
                                        Charges
                                    </h6>
                                </div>
                                <div class="charges-container add-buttons">
                                    <app-add-charges
                                        [event]="event"
                                        class="btn-m-r-1"
                                    ></app-add-charges>
                                    <app-discounts-modal-v2
                                        [event]="event"
                                        class="btn-m-r-1">
                                    </app-discounts-modal-v2>
                                    <button
                                        pButton class="p-button-text workorders-btn-outlined btn-m-r-1"
                                        icon="pi pi-times"
                                        (click)="cancelEventCharges(event?.id)"
                                        [pTooltip]="getEventDiscardTooltipText(event?.id)"
                                        [disabled]="!eventsWithUnsavedChanges.includes(event?.id)">
                                    </button>
                                    <button
                                        pButton class="workorders-btn-primary"
                                        icon="pi pi-check"
                                        (click)="saveModifiedCharges()"
                                        [pTooltip]="getSaveTooltipText()"
                                        [disabled]="(jobBeingUpdated$ | async) || !unsavedChanges || !isOnline">
                                    </button>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr
                        class="event-row"
                        [class.hidden]="!event?.charges.length"
                    >
                        <td class="fixed-width hidden-column"></td>
                        <td class="hidden-column">
                            Charge
                        </td>
                        <td class="hidden-column">
                            Rate
                        </td>
                        <td class="hidden-column">
                            Quantity
                        </td>
                        <td class="hidden-column subtotal-column">
                            Subtotal
                        </td>
                        <td></td>
                    </tr>
                    <tr *ngIf="jobLoading">
                        <td></td>
                        <td>
                            <p-skeleton
                                styleClass="p-mb-1"
                                width="10rem"
                                height="1.5rem"
                            ></p-skeleton>
                            <p-skeleton
                                width="4rem"
                                height="1.5rem"
                            ></p-skeleton>
                        </td>
                        <td>
                            <p-skeleton
                                width="4rem"
                                height="1.5rem"
                            ></p-skeleton>
                        </td>
                        <td>
                            <p-skeleton
                                width="4rem"
                                height="1.5rem"
                            ></p-skeleton>
                        </td>
                        <td>
                            <p-skeleton
                                width="4rem"
                                height="1.5rem"
                            ></p-skeleton>
                        </td>
                        <td>
                            <p-skeleton
                                width="4rem"
                                height="1.5rem"
                            ></p-skeleton>
                        </td>
                    </tr>
                    <tr
                        *ngFor="let charge of event.charges"
                        cdkDrag
                        [cdkDragStartDelay]="
                            responsiveHelper.isSmallScreen
                                ? responsiveHelper.dragDelay
                                : undefined
                        "
                        [cdkDragData]="charge"
                        [cdkDragPreviewContainer]="previewContainer"
                        (cdkDragEntered)="handleDragEnter($event)"
                        class="selectable-row"
                    >
                        <td
                            class="fixed-width color-border"
                            cdkDragHandle
                            [style.border-right-color]="
                                charge.product?.metadata?.color
                            "
                        >
                            <button
                                pButton
                                class="p-button-text p-button-rounded p-button-plain p-button-lg"
                                icon="pi pi-bars"
                            ></button>
                        </td>
                        <td
                            (click)="openCharge(charge)"
                            class="charge-name"
                        >
                        <div class="charge-name-wrapper">
                            <p class="p-text-bold p-mb-1">
                            {{
                            (charge?.product?.name || charge.productName)
                            }}
                            </p>
                        </div>
                        <label
                            >{{
                            charge?.product?.category
                            ? charge?.product?.category
                            : "None"
                            }}
                        </label>
                            <br />
                            <p class="subtotal-label">
                            Subtotal: &nbsp;
                            {{
                            charge.chargeSubTotal / 100
                            | currency
                            : job?.currency || "USD"
                            }}
                            </p>
                        </td>
                        <td class="mobile-separator"></td>
                        <td class="mobile-separator"></td>
                        <td
                            *ngIf="
                                charge?.price?.priceType !==
                                'percentage'
                            "
                            class="stack-vertically input-with-subtotal"
                        >
                            <button
                                pButton
                                type="button"
                                icon="pi pi-ellipsis-v"
                                class="p-button-icon-only p-button-text ellipsis-button mobile-visible"
                                (click)="setChargeActions(charge); chargeActionsMenu.toggle($event)"
                                [disabled]="jobLoading || event?.locked || event?.invoiced">
                            </button>
                            <p-inputNumber
                                [(ngModel)]="
                                    editableAmounts[
                                        charge.id || charge.key
                                    ]
                                "
                                (onBlur)="
                                    handleQuantityChange(
                                        event,
                                        charge,
                                        getEditableAmount(charge?.price?.priceType, editableAmounts[charge.id || charge.key]),
                                        'amount')
                                "
                                class="mid-width-number-input
                                    mid-width-number-input--adjustable"
                                (click)="handleInputClick($event)"
                                mode="currency"
                                [currency]="job?.currency || 'USD'"
                                [locale]="
                                    job?.currency === 'CAD'
                                        ? 'en-CA'
                                        : 'en-US'
                                "
                                [inputStyleClass]="(charge.chargeSubTotal / 100) > subtotalLimit ? 'p-invalid-input' : ''"
                            ></p-inputNumber>
                        </td>
                        <td
                            *ngIf="
                                charge?.price?.priceType ===
                                'percentage'
                            "
                            class="stack-vertically"
                        >
                            <button
                                pButton
                                type="button"
                                icon="pi pi-ellipsis-v"
                                class="p-button-icon-only p-button-text ellipsis-button mobile-visible"
                                (click)="setChargeActions(charge); chargeActionsMenu.toggle($event)"
                                [disabled]="jobLoading || event?.locked || event?.invoiced">
                            </button>
                            <p-inputNumber
                                [(ngModel)]="
                                    editableAmounts[
                                        charge.id || charge.key
                                    ]
                                "
                                (onBlur)="
                                    handleQuantityChange(
                                        event,
                                        charge,
                                        getEditableAmount(charge?.price?.priceType, editableAmounts[charge.id || charge.key]),
                                        'amount')
                                "
                                class="mid-width-number-input
                                    mid-width-number-input--adjustable"
                                type="number"
                                (click)="handleInputClick($event)"
                                suffix="%"
                                max="100"
                                min="0"
                            ></p-inputNumber>
                        </td>
                        <td
                            *ngIf="
                                charge?.price?.priceType !==
                                'percentage'
                            "
                            class="stack-vertically"
                        >
                            <button
                                pButton
                                type="button"
                                (click)="
                                handleRemoveCharge(event, charge)
                                "
                                class="delete-charge delete-charge-decrement-replacement
                                    mobile-visible p-button-text workorders-btn-text"
                                [class.delete-button-hidden]="
                                    charge.quantity > 1
                                "
                                icon="pi pi-trash"
                                [disabled]="jobLoading || event?.locked || event?.invoiced">
                            </button>
                            <p-inputNumber
                                class="short-number-input mid-width-number-input--adjustable"
                                type="number"
                                [minFractionDigits]="1"
                                [maxFractionDigits]="2"
                                mode="decimal"
                                [showButtons]="true"
                                buttonLayout="horizontal"
                                min="0"
                                incrementButtonIcon="pi pi-plus"
                                decrementButtonIcon="pi pi-minus"
                                [decrementButtonClass]="
                                    (charge.quantity === 0 || charge.quantity === 1 || !charge.quantity)
                                        ? 'p-button-text workorders-btn-text decrement-button-hidden'
                                        : 'p-button-text workorders-btn-text'
                                "
                                incrementButtonClass="p-button-text workorders-btn-text"
                                [(ngModel)]="charge.quantity"
                                (onBlur)="
                                    handleQuantityChange(event, charge, charge.quantity, 'quantity')
                                "
                                (click)="handleInputClick($event)"
                                [disabled]="jobLoading || event?.locked || event?.invoiced">
                                [inputStyleClass]="(charge.chargeSubTotal / 100) > subtotalLimit ? 'p-invalid-input' : ''"
                            ></p-inputNumber>
                        </td>
                        <td
                            *ngIf="
                                charge?.price?.priceType ===
                                'percentage'
                            "
                            class="stack-vertically percentage-quantity"
                        >
                            <button
                                pButton
                                type="button"
                                (click)="
                                    handleRemoveCharge(event, charge)
                                "
                                class="delete-charge percentage-delete-button p-button-text workorders-btn-text"
                                icon="pi pi-trash"
                                [disabled]="jobLoading || event?.locked || event?.invoiced"
                            ></button>
                            <p>N/A</p>
                        </td>
                        <td
                            class="subtotal-column"
                            (click)="openCharge(charge)"
                        >
                            <span
                                [class.error-text]="
                                    (charge.chargeSubTotal / 100) >
                                    subtotalLimit"
                                >
                                {{
                                charge.chargeSubTotal / 100
                                    | currency : job?.currency || "USD"
                                }}
                            </span>
                        </td>
                        <td
                            *hasPermission="['jobs.update']"
                            class="hidden-column"
                        >
                            <div class="charge-actions">
                                <button
                                    pButton
                                    type="button"
                                    icon="pi pi-ellipsis-v"
                                    class="p-button-icon-only p-button-text ellipsis-button"
                                    (click)="setChargeActions(charge, event); chargeActionsMenu.toggle($event)"
                                    [disabled]="jobLoading || event?.locked || event?.invoiced">
                                </button>
                            </div>
                        </td>
                    </tr>
                    <tr class="item-row" *ngIf="event?.discounts?.length">
                        <td class="fixed-width discount-hidden-column"></td>
                        <td class="discount-header-row" colspan="2">
                            Name / Code
                        </td>
                        <td class="discount-header-row">
                            Discount
                        </td>
                        <td class="discount-header-row">
                            Value
                        </td>
                        <td></td>
                    </tr>
                    <tr *ngFor="let discount of event.discounts" class="item-row">
                        <td class="fixed-width discount-hidden-column"></td>
                        <td (click)="openDiscount(discount)" colspan="2">
                            <div class="discount-name-wrapper">
                                <span class="discount-name">{{
                                    discount.discount.name
                                    }}</span>
                                <br />
                                <span class="discount-code discount-hidden-column">{{
                                    discount.discount.code
                                    }}</span>
                            </div>
                        </td>
                        <td>
                            {{ discount | discountCustomAmount : job.currency }}
                        </td>
                        <td>
                            {{
                            discount.discountedAmount | freyaCurrency : job.currency
                            }}
                        </td>
                        <td style="text-align: right;">
                            <span class="p-column-title"></span>
                            <span>
                                <button
                                    pButton
                                    type="button"
                                    icon="pi pi-ellipsis-v"
                                    class="p-button-icon-only p-button-text ellipsis-button discount-actions"
                                    (click)="setDiscountActions(discount); discountActionsMenu.toggle($event)"
                                    [disabled]="jobLoading || event?.locked || event?.invoiced">
                                </button>
                            </span>
                        </td>
                    </tr>
                    <tr [class.hidden]="event.charges.length || event.discounts.length || !eventTypeInfoMap[event.type]?.revenueGenerating">
                        <td colspan="12">
                            <div class="flex">
                                <div class="charges-container">
                                    <p
                                        *ngIf="
                                            !jobLoading &&
                                            event?.charges.length === 0
                                        "
                                    >
                                        No charges or discounts added
                                    </p>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="12" class="no-padding">
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="financials-assets-container">
                <div>
                    <h6 (click)="toggle(collapsedEventFinancialsIds, event?.id)">Financials</h6>
                    <app-financials-table-v2
                        [eventId]="event.id"
                    >
                    </app-financials-table-v2>
                </div>
                <div>
                    <h6 (click)="toggle(collapsedEventFinancialsIds, event?.id)">Assets</h6>
                    <div>
                        <div *ngFor="let asset of event.assets">
                            <p>
                                {{ asset.name }}
                                <p-tag [value]="asset.type" severity="info"></p-tag>
                            </p>
                        </div>
                        <div *ngIf="!event.assets?.length">
                            No Assets Assigned.
                        </div>
                    </div>
                </div>
                <div>
                    <!--<button
                        type="button"
                        pButton
                        pRipple
                        [pTooltip]="isEventFinancialsCollapsed(event?.id)
                            ? 'Collapse Event Financials' : 'Expand Event Financials'"
                        tooltipPosition="left"
                        class="p-button-text p-button-rounded p-button-plain"
                        [icon]="isEventFinancialsCollapsed(event?.id) ? 'pi pi-chevron-up' : 'pi pi-chevron-down'"
                        (click)="toggle(collapsedEventFinancialsIds, event?.id)"
                    ></button>-->
                </div>
            </div>
        </div>
    </div>
 </div>

 <p-menu #discountActionsMenu appendTo="body" [popup]="true" [model]="discountActions"></p-menu>

 <p-menu #chargeActionsMenu appendTo="body" [popup]="true" [model]="chargeActions"></p-menu>

 <p-menu #eventActionsMenu appendTo="body" [popup]="true" [model]="eventActions"></p-menu>

<ng-template #discountsTooltipContent>
    <table class="tooltip-table">
      <tr><td>Discounts</td><td>{{job?.discountTotal / 100 | currency : job?.currency || "USD"}}</td></tr>
      <tr><td>Damages</td><td>{{job?.damageTotal / 100 | currency : job?.currency || "USD"}}</td></tr>
      <tr><td>Taxes</td><td>{{job?.taxTotal / 100 | currency : job?.currency || "USD"}}</td></tr>
    </table>
</ng-template>