import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ListUsersGQL } from 'graphql.generated';
import { catchError, exhaustMap, map, of, withLatestFrom } from 'rxjs';


import { selectCustomerRole } from '../jobv2-create-state/jobv2-create.selectors';

import { JobCreateCustomerActions } from './jobv2-create-customer.actions';

export const userSearchInputChanged = createEffect((
	actions$ = inject(Actions),
	store = inject(Store),
	listUsersGQL = inject(ListUsersGQL),
) => {
	return actions$.pipe(
		ofType(JobCreateCustomerActions.customerValueInput),
		withLatestFrom(store.select(selectCustomerRole)),
		exhaustMap(([action, customerRoleId]) => {
			if (action.search) {
				return listUsersGQL.fetch(
					{
						filter: {...action.filter, roles: [customerRoleId]},
						skip: 0,
						limit: 25,
					},
				).pipe(
					map((result) => {
						const users = result.data?.usersv2.users || [];

						if (!users) {
							return undefined;
						}

						return JobCreateCustomerActions.searchUsersSuccess({
							users
						});
					}),
					catchError((error) => of(JobCreateCustomerActions.searchUsersError({
						error
					})))
				)
			}
		}),
	);
}, { functional: true, dispatch: true });