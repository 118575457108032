import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

import { SubSink } from 'subsink';

import { FreyaCommonModule } from '../../freya-common/freya-common.module';
import { BrandingService } from '../../services/branding.service';

@Component({
  selector: 'app-karve-tabview',
  standalone: true,
  imports: [
    FreyaCommonModule
  ],
  templateUrl: './karve-tabview.component.html',
  styleUrls: ['./karve-tabview.component.scss',]
})
export class KarveTabviewComponent implements OnInit, OnDestroy {
  @Input() tabs: {
    name: string;
    slug: string;
  }[];
  @Input() baseUrl: string;

  selectedTab: string;

  zone: string;

  subs = new SubSink();
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private branding: BrandingService,
  ) { }

  ngOnInit(): void {
    this.watchRouteChange();
    this.watchZoneChange();
  }

  private watchRouteChange(): void {

    this.subs.sink = this.route.firstChild?.url.subscribe(urlSegment => {
      const tab = urlSegment[0].path;

      // Get the last part of the URL
      if (!this.selectedTab || tab !== this.selectedTab) {
        this.selectedTab = tab;
      }
    });
  }

  private watchZoneChange(): void {
    this.subs.sink = this.branding.currentZone().subscribe((zone) => {
      this.zone = zone.id;
    });
  }

  public goToRoute(tab: any): void {
    this.router.navigate([this.baseUrl, tab.value]);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  getUrl(tab: typeof this.tabs[0]) {
    return `${this.baseUrl}/${tab.slug}?zone=${this.zone}`;
  }

  handleTabClick(event: MouseEvent) {
    // Only prevent default if no modifier keys are pressed
    if (!event.ctrlKey && !event.metaKey && !event.shiftKey) {
      event.preventDefault();
    }
  }
}
