import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AssetService, CalendarEventService, CommentService, PriceService, ProductService } from '@karve.it/features';
import { MutateReportComponent } from 'src/app/reports/mutate-report/mutate-report.component';
import { DetailsHelperService } from 'src/app/services/details-helper.service';
import { DeleteInput, FreyaMutateService, MutateInput } from 'src/app/services/freya-mutate.service';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { MutateAreaComponent } from 'src/app/shared/mutate-area/mutate-area.component';
import { MutateCalendarEventComponent } from 'src/app/shared/mutate-calendar-event/mutate-calendar-event.component';
import { MutateChargesComponent } from 'src/app/shared/mutate-charges/mutate-charges.component';
import { MutatePriceComponent } from 'src/app/shared/mutate-price/mutate-price.component';
import { MutateProductComponent } from 'src/app/shared/mutate-product/mutate-product.component';
import { MutateTransactionComponent } from 'src/app/shared/mutate-transaction/mutate-transaction.component';
import { PrettyPropertyPipe } from 'src/app/shared/pretty-property.pipe';
import { SubSink } from 'subsink';

import { DeleteArtifactsGQL, DeleteAvailabilityTemplatesGQL, DeleteTransactionByIdGQL, DeleteUsersGQL } from '../../../generated/graphql.generated';
import { BOOK_OFF_EVENT_TYPE } from '../../global.constants';
import { MutateAvailabilityTemplateComponent } from '../../shared/mutate-availability-template/mutate-availability-template.component';
import { MutateBookOffComponent } from '../../shared/mutate-book-off/mutate-book-off.component';
import { MutateBusinessUnitComponent } from '../../shared/mutate-business-unit/mutate-business-unit.component';

import { MutateCommentComponent } from '../../shared/mutate-comment/mutate-comment.component';
import { MutateDynamicReportComponent } from '../../shared/mutate-dynamic-report/mutate-dynamic-report.component';
import { MutateFilterComponent } from '../../shared/mutate-filter/mutate-filter.component';
import { MutateIcalTokenComponent } from '../../shared/mutate-ical-token/mutate-ical-token.component';
import { MutateInvoiceComponent } from '../../shared/mutate-invoice/mutate-invoice.component';
import { MutateJobComponent } from '../../shared/mutate-job/mutate-job.component';
import { MutateRollingLockDateComponent } from '../../shared/mutate-rolling-lock-date/mutate-rolling-lock-date.component';

import { MutateUserComponent } from '../../shared/mutate-user/mutate-user.component';

@Component({
  selector: 'app-mutate-container',
  templateUrl: './mutate-container.component.html',
  styleUrls: ['./mutate-container.component.scss']
})
export class MutateContainerComponent implements OnInit, OnDestroy {

  // MUTATE COMPONENTS
  @ViewChild('mutateProduct') mutateProductRef: MutateProductComponent;
  @ViewChild('mutatePrice') mutatePriceRef: MutatePriceComponent;
  @ViewChild('mutateArea') mutateAreaRef: MutateAreaComponent;
  @ViewChild('mutateCalendarEvent') mutateCalendarEventRef: MutateCalendarEventComponent;
  @ViewChild('mutateCharges') mutateChargeRef: MutateChargesComponent;
  @ViewChild('mutateTransaction') mutateTransactionRef: MutateTransactionComponent;
  @ViewChild('mutateComment') mutateCommentRef: MutateCommentComponent;
  @ViewChild('mutateUser') mutateUserRef: MutateUserComponent;
  @ViewChild('mutateJob') mutateJobRef: MutateJobComponent;
  @ViewChild('mutateAvailabilityTemplate') mutateAvailabilityTemplateRef: MutateAvailabilityTemplateComponent;
  @ViewChild('mutateReport') mutateReportRef: MutateReportComponent;
  @ViewChild('mutateFilter') mutateFilterRef: MutateFilterComponent;
  @ViewChild('mutateDynamicReport') mutateDynamicReportRef: MutateDynamicReportComponent;
  @ViewChild('mutateScheduledReport') mutateScheduledReport: MutateFilterComponent;
  @ViewChild('mutateBusinessUnit') mutateBusinessUnitRef: MutateBusinessUnitComponent;
  @ViewChild('mutateRollingLockDate') mutateRollingLockDateRef: MutateRollingLockDateComponent;
  @ViewChild('mutateInvoice') mutateInvoiceRef: MutateInvoiceComponent;
  @ViewChild('mutateIcal') mutateIcalRef: MutateIcalTokenComponent;
  @ViewChild(MutateBookOffComponent) mutateBookOffRef: MutateBookOffComponent;

  // CREATE/UPDATE
  mutateInput: MutateInput;

  // DELETE
  deleteInput: DeleteInput;
  deleteDialogVisible: boolean;
  // True while we are in the middle of deleting an object
  deletingObject = false;

  // CLEANUP
  subs = new SubSink();

  constructor(
    private freyaMutate: FreyaMutateService,
    private detailsHelper: DetailsHelperService,
    private localNotify: FreyaNotificationsService,
    // Object Services
    private productService: ProductService,
    private priceService: PriceService,
    private eventService: CalendarEventService,
    private commentService: CommentService,
    private deleteAvailabilityTemplateGQL: DeleteAvailabilityTemplatesGQL,
    private deleteUsersGQL: DeleteUsersGQL,
    private deleteTransactonGQL: DeleteTransactionByIdGQL,
    private assetService: AssetService,
    private deleteArtifactsGQL: DeleteArtifactsGQL,
    // Pipes
    private prettyPipe: PrettyPropertyPipe,
  ) { }

  ngOnInit(): void {
    this.subs.sink = this.freyaMutate.mutateInput.subscribe((input: MutateInput) => {
      this.openMutateDialog(input);
    });

    this.subs.sink = this.freyaMutate.deleteInput.subscribe((input: DeleteInput) => {
      this.openDeleteDialog(input);
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  openMutateDialog(input: MutateInput) {

    console.log(input);

    let ref; // The reference to the appropriate mutate component

    switch (input.objectType) {
      case 'area':
        ref = this.mutateAreaRef;
        ref.area = input.object;
        break;
      case 'product':
        ref = this.mutateProductRef;
        ref.product = input.object;
        break;
      case 'price':
        ref = this.mutatePriceRef;
        ref.price = input.object;
        ref.product = input.object?.product;
        break;
      case 'calendarEvent':
        ref = this.mutateCalendarEventRef;
        ref.event = input.object;
        break;
      case 'charge':
        ref = this.mutateChargeRef;
        ref.charge = input.object;
        break;
      case 'transaction':
        ref = this.mutateTransactionRef;
        ref.transaction = input.object;
        break;
      case 'comment':
        ref = this.mutateCommentRef;
        ref.comment = input.object;
        break;
      case 'user':
        ref = this.mutateUserRef;
        ref.user = input.object;
        break;
      case 'job':
        ref = this.mutateJobRef;
        ref.job = input.object;
        break;
      case 'availabilityTemplate':
        ref = this.mutateAvailabilityTemplateRef;
        ref.template = input.object;
        break;
      case 'report':
        ref = this.mutateReportRef;
        ref.reportType = input.object;
        break;
      case 'ScheduledReport':
        ref = this.mutateScheduledReport;
        ref.scheduledReport = input.object;
        break;
      case 'filter':
        ref = this.mutateFilterRef;
        ref.filter = input.object;
        break;
      case 'dynamicReport':
        ref = this.mutateDynamicReportRef;
        ref.reportParameters = input.object;
        break;
      case 'businessUnit':
        ref = this.mutateBusinessUnitRef;
        break;
      case 'bookOff':
        ref = this.mutateBookOffRef;
        ref.bookOffEvent = input.object;
        break;
      case 'ical':
        ref = this.mutateIcalRef;
        // TODO parse asset
        // ref = input.object;
        break;
      case 'rollingLockDate':
        ref = this.mutateRollingLockDateRef;
        ref.rollingLockDate = input.object;
        break;
      case 'invoice':
        ref = this.mutateInvoiceRef;
        break;
    }

    // Assign additional values
    if (input.additionalValues) {
      for (const value of input.additionalValues) {
        ref[value.property] = value.value;
      }
    }

    // Initialize component and open the dialog
    ref.mutateType = input.mutateType;
    ref.mutateRef.mutateType = input.mutateType; // Required because of change detection issue
    ref.openDialog();

    setTimeout(() => {
      if (input.removeSteps?.length){
        for (const step of input.removeSteps){
          ref.mutateRef.removeStep(step);
        }
      }
    }, 250);
    if (!input.openToStep) { return; }
    ref.mutateRef.viewSection(input.openToStep);
  }

  openDeleteDialog(input: DeleteInput) {
    this.deleteInput = input;
    this.deleteDialogVisible = true;
  }

  deleteObject() {
    this.deletingObject = true;
    this.deleteDialogVisible = false;

    this.freyaMutate.objectDeleted.next(this.deleteInput);

    switch (this.deleteInput.objectType) {
      case 'product':
        this.deleteProduct();
        break;
      case 'price':
        this.deletePrice();
        break;
      case 'calendarEvent':
        this.deleteCalendarEvent();
        break;
      case 'comment':
        this.deleteComment();
        break;
      case 'user':
        this.deleteUsers();
        break;
      case 'availabilityTemplate':
        this.deleteAvailabilityTemplate();
        break;
      case 'transaction':
        this.deleteTransaction();
        break;
      case 'asset':
        this.deleteAsset();
        break;
      case 'artifact':
        this.deleteArtifact();
        break;
      default:
        console.warn(`No Delete Funtionality Specified for Type: ${this.deleteInput.objectType}`);
        this.deletingObject = true;
    }
  }

  // DELETE HELPERS
  onDeleteSuccess(closeSidePanel = true) {
    if (closeSidePanel) {
      this.detailsHelper.detailsItem.next(null);
    }
    this.localNotify.success(`${this.prettyPipe.transform(this.deleteInput.objectType)} deleted`);
    this.freyaMutate.objectDeleted.next(this.deleteInput);
    this.deleteInput?.afterDelete?.();
    this.deleteInput = undefined;
    this.deletingObject = false;
  }

  onDeleteFailure(err) {
    console.error(err);
    this.localNotify.apolloError(`${this.prettyPipe.transform(this.deleteInput.objectType)} was not deleted`, err);
    this.deletingObject = false;
  }

  onDeleteCancelled(){
    this.deleteInput?.afterCancel?.();
    this.deleteDialogVisible = false;
  }

  // DELETE METHODS
  deleteProduct() {
    this.subs.sink = this.productService.deleteProduct([this.deleteInput.objectId]).subscribe((res) => {
      this.detailsHelper.pushUpdate({
        id:this.deleteInput.objectId,
        type:'Products',
        action:'delete'
      });
      this.onDeleteSuccess();
    }, (err) => {
      this.onDeleteFailure(err);
    });
  }

  deletePrice() {
    this.subs.sink = this.priceService.deletePrices({ ids: [this.deleteInput.objectId] }).subscribe((res) => {
      this.detailsHelper.pushUpdate({
        id:this.deleteInput.objectId,
        type:'Prices',
        action:'delete'
      });
      this.onDeleteSuccess();
    }, (err) => {
      this.onDeleteFailure(err);
    });
  }

  deleteCalendarEvent() {
    this.subs.sink = this.eventService.removeCalendarEvent({ ids: [this.deleteInput.objectId] }).subscribe((res) => {
      this.detailsHelper.pushUpdate({
        id:this.deleteInput.objectId,
        type:'Events',
        update: {
          type: BOOK_OFF_EVENT_TYPE,
        },
        action:'delete'
      }, true);

      this.detailsHelper.pushUpdate({
        id:this.deleteInput.objectId,
        type:'Jobs',
        action:'update'
      });
      this.onDeleteSuccess();
    }, (err) => {
      this.onDeleteFailure(err);
    });
  }

  deleteComment() {
    this.subs.sink = this.commentService.deleteComments({
      input: {
        ids: [this.deleteInput.objectId],
        hardDelete: false
      }
    }).subscribe((res) => {
      this.detailsHelper.pushUpdate({
        id:this.deleteInput.objectId,
        type:'Comments',
        action:'delete'
      });
      this.onDeleteSuccess();
    }, (err) => {
      this.onDeleteFailure(err);
    });
  }

  deleteAvailabilityTemplate(){
    this.subs.sink = this.deleteAvailabilityTemplateGQL.mutate({ids: [this.deleteInput.objectId]}).subscribe((res) => {
      this.detailsHelper.pushUpdate({
        id:this.deleteInput.objectId,
        type:'AvailabilityTemplate',
        action:'delete'
      });
      this.onDeleteSuccess();
    }, (err) => {
      this.onDeleteFailure(err);
    });
  }

  deleteUsers(){
    this.subs.sink = this.deleteUsersGQL.mutate({userIds: [this.deleteInput.objectId]}).subscribe((res) => {
      this.detailsHelper.pushUpdate({
        id: this.deleteInput.objectId,
        type: 'User',
        action: 'delete'
      });
      this.onDeleteSuccess();
    }, (err) => {
      this.onDeleteFailure(err);
    });
  }

  deleteTransaction() {
    this.subs.sink = this.deleteTransactonGQL.mutate({ transactionId: this.deleteInput.objectId }).subscribe((res) => {
      this.detailsHelper.pushUpdate({
        id: this.deleteInput.objectId,
        type: 'Transactions',
        action: 'delete'
      });
      this.onDeleteSuccess();
    }, (err) => {
      this.onDeleteFailure(err);
    });
  }

  deleteAsset() {
    this.subs.sink = this.assetService.deleteAssets({
      hardDelete: false,
      ids: [this.deleteInput.objectId],
    }).subscribe((output) => {
      if (output.data?.deleteAssets) {
        // Close the details pane
        this.detailsHelper.pushUpdate({
          id: this.deleteInput.objectId,
          type: 'Assets',
          action: 'delete'
        }, true);
        this.onDeleteSuccess();
      }
    }, (err) => {
      this.onDeleteFailure(err);
    });
  }

  deleteArtifact() {
    this.deleteArtifactsGQL.mutate({
      artifactIds: [ this.deleteInput.objectId ],
      deleteResource: true,
    }).subscribe(() => {

      this.detailsHelper.pushUpdate({
        id: this.deleteInput.objectId,
        type: 'Artifacts',
        action: 'delete'
      }, true);

      this.onDeleteSuccess();
    }, (err) => {
      this.onDeleteFailure(err);
    } );
  }

}
