import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { FreyaCommonModule } from "src/app/freya-common/freya-common.module";

import { JOB_CREATE_INDEXES, distancesToShowV2 } from 'src/app/global.constants';
import { LibModule } from 'src/app/lib.ts/lib.module';
import { EstimateHelperService } from 'src/app/services/estimate-helper.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { SubSink } from 'subsink';

import { LocationWithHowSetIndicator, Modes, jobToolFeature } from '../../job-tool.reducer';

import { JobCreateCustomerActions } from '../jobv2-create-customer-state/jobv2-create-customer.actions';
import { JobCreateActions } from '../jobv2-create-state/jobv2-create.actions';
import { selectStartTimeCanBeEdited, selectZoneIdForFind } from '../jobv2-create-state/jobv2-create.selectors';
import { DistanceCalculations, JobTiming } from '../jobv2-interfaces';

@Component({
    selector: 'app-jobv2-timeline-availability',
    standalone: true,
    imports: [
        FreyaCommonModule,
        LibModule,
        SharedModule,
    ],
    templateUrl: './jobv2-timeline-availability.component.html',
    styleUrls: ['./jobv2-timeline-availability.component.scss']
})
export class Jobv2TimelineAvailabilityComponent implements OnInit, OnDestroy {

    @ViewChild('timelineMovingDate', { static: false }) timelineMovingDateInput!: any;

    movingDate: Date | Date[];
    timeline: string;
    timelineDays: number;
    possibleTimes: number[] = [];
    selectedTimeSlot: number | undefined;
    locationsInputs: LocationWithHowSetIndicator | undefined;
    distancesToShow = distancesToShowV2;
    jobTiming: JobTiming;
    distances: DistanceCalculations;
    areaId: string;


    isCardCollapsed = false;
    jobCreateIndexes = JOB_CREATE_INDEXES;
    timeSlotDisabledTooltip = 'Moving event can be rescheduled from the Booking page.';
    startTimeCanBeEdited = true;

    constructor(
        private store: Store,
        private estimateHelper: EstimateHelperService,
    ) { }

    private subs = new SubSink();

    mode: Modes;
    jobFormMode$ = this.store.select(jobToolFeature.selectJobFormMode);
    jobInput$ = this.store.select(jobToolFeature.selectJobInput);
    locationsInputs$ = this.store.select(jobToolFeature.selectLocationsInputs);
    distances$ = this.store.select(jobToolFeature.selectDistances);
    jobTiming$ = this.store.select(jobToolFeature.selectJobTiming);
    areaId$ = this.store.select(selectZoneIdForFind);
    availableTimeSlots$ = this.store.select(jobToolFeature.selectAvailableTimeSlots);
    selectedTimeSlot$ = this.store.select(jobToolFeature.selectSelectedTimeSlot);
    startTimeCanBeEdited$ = this.store.select(selectStartTimeCanBeEdited);

    ngOnInit(): void {
        this.subs.sink = this.jobFormMode$.subscribe((jobFormMode) => {
            this.mode = jobFormMode;
          });

          this.subs.sink = this.jobInput$.subscribe((jobInput) => {
            this.timeline = jobInput?.timeline;
            this.timelineDays = jobInput?.timelineDays;
            this.setMovingDate(jobInput?.timeline);
          });

          this.subs.sink = this.startTimeCanBeEdited$.subscribe((startTimeCanBeEdited) => {
            this.startTimeCanBeEdited = startTimeCanBeEdited;
          });

          this.subs.sink = this.distances$.subscribe((distances) => {
            this.distances = distances;
          });

          this.subs.sink = this.jobTiming$.subscribe((jobTiming) => {
            this.jobTiming = jobTiming;
          });

          this.subs.sink = this.areaId$.subscribe((areaId) => {
            this.areaId = areaId;
          });

          this.subs.sink = this.availableTimeSlots$.subscribe((availableTimeSlots) => {
            this.possibleTimes = availableTimeSlots;
          });

          this.subs.sink = this.selectedTimeSlot$.subscribe((selectedTimeSlot) => {
            this.selectedTimeSlot = selectedTimeSlot;
          });

          this.subs.sink = this.locationsInputs$.subscribe((locationsInputs) => {
            this.locationsInputs = locationsInputs;

            // Dispatch only when locationsInputs changes
            if (this.locationsInputs !== locationsInputs) {
              // this.store.dispatch(JobTimelineActions.calculateDistances({ input: locationsInputs }));
            }
          });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    onMovingDateSelect(date: Date) {
        const { timeline, timelineDays } = this.estimateHelper.getTimelineV2(date);
        this.store.dispatch(JobCreateCustomerActions.selectMovingDate({
            timeline,
            timelineDays,
        }));
    }

    setMovingDate(timeline: string) {
        this.movingDate = this.estimateHelper.convertToDate(timeline);
    }

    setStartTime() {

    }

    objectIsEmpty(obj: Record<string, unknown> | null | undefined): boolean {
      return !obj || !Object.entries(obj).length;
    }

    selectAvailableTime(event) {
        this.store.dispatch(JobCreateActions.selectTimeSlot({
            timeSlot: event,
         }))
    }

    collapseCard() {
        this.isCardCollapsed = !this.isCardCollapsed
    }
}