import { createFeature, createReducer, on, State } from '@ngrx/store';

import { FeatureName } from '../services/features';

import { BrandingActions } from './branding.store';

const featureFlagsInitialState = {
	features: [] as FeatureName[],
};

export type FeatureFlagsState = typeof featureFlagsInitialState;

export const featureFlagsFeature = createFeature({
	name: 'featureFlags',
	reducer: createReducer(
		featureFlagsInitialState,
		on(BrandingActions.brandingUpdated, (state, { features }): FeatureFlagsState => ({
			...state,
			features: features as FeatureName[],
		})),
	),
});

export const genFeatureSelector = (feature: FeatureName) => 
	(state: State<any>) => {
		const features = featureFlagsFeature.selectFeatures(state)
		return features.includes(feature);
	};