import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

import { FreyaCommonModule } from '../../../freya-common/freya-common.module';
import { SharedModule } from '../../../shared/shared.module';
import { jobToolFeature } from '../../job-tool.reducer';

@Component({
  selector: 'app-travel-time',
  standalone: true,
  imports: [
    FreyaCommonModule,
    SharedModule
  ],
  templateUrl: './travel-time.component.html',
  styleUrl: './travel-time.component.scss'
})
export class TravelTimeComponent {

  jobLocations$ = this.store.select(jobToolFeature.selectJobLocations);
  jobTravelDistances$ = this.store.select(jobToolFeature.selectJobTravelDistances);
  collapsed: boolean = true;
  constructor(
    private store: Store,
  ) {
  }

}
