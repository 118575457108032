<main class="grid jobsv2-{{ colorScheme.colorScheme.value }}">
	<app-job-header></app-job-header>

	<app-karve-tabview
		[tabs]="tabsWithSlug"
		[baseUrl]="baseUrl"
		class="job-tabs"
	></app-karve-tabview>

	<div class="tabView">
		<router-outlet></router-outlet>
	</div>

	<app-job-activity></app-job-activity>
</main>

<div *ngIf="chargesUpdates?.length" class="sticky-unsaved-changes-banner">
    <div>
        <i class="pi pi-exclamation-triangle">
        </i>
        <span>
            You have unsaved changes
        </span>
    </div>
    <div>
        <button
        pButton
        class="p-button-text p-m-1 save-notification-text-btn"
        label="Save"
        (click)="saveModifiedCharges()"
        [pTooltip]="getSaveTooltipText()"
        [disabled]="(jobBeingUpdated$ | async) || (jobLoading$ | async) || !isOnline"
    ></button>
    </div>
</div>