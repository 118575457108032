<button
  *hasPermission="['jobs.update']"
   pButton
   type="button"
   class="add-charges-button"
   label="Add Charges"
   icon="pi pi-plus"
   (click)="openAvailableProductsModal()"
   [disabled]="jobLoading || getDisabledStatus(event, job).disabledStatus"
   [pTooltip]="getDisabledStatus(event, job).disabledStatus ? getDisabledStatus(event, job).disabledToolTip : ''"
   tooltipPosition="left">
</button>


<p-dialog header="Available Products for {{event?.title}} —
{{event?.start ? (event?.start | freyaDate : 'h:mm a, MMM d') :
'Unscheduled'}}" [modal]="true" [style]="{width: '40vw', height: '90vh'}"
 [breakpoints]="{'1024px': '60vw', '776px': '90vw', '510px': '90vw'}" [(visible)]="showAvailableProductsDialog" [dismissableMask]="true">
 <div class="search-row">
   <span class="p-input-icon-left" style="width: 100%;">
     <i class="pi pi-search"></i>
     <input type="text" pInputText [(ngModel)]="productSearch" placeholder="Search" style="width: 100%">
   </span>
 </div>
 <div class="p-d-flex p-jc-between p-ai-center">
   <div class="scrollable-container" id="availableProducts" style="width: 100%">
     <div *ngIf="productsLoading">
       <p-skeleton width="100%" height="1.5rem"></p-skeleton>
     </div>
     <div *ngIf="!productsLoading && !availableProducts?.length" class="no-products-msg">
       <i class="pi pi-inbox p-mr-1" style="font-size: 1.1rem"></i>
       There are no products with an active price, in or above this zone. You can add products and prices on the
       Products page.
       <br>
     </div>
     <div *ngIf="availableProducts.length && !productsLoading">
       <div *ngIf="(availableProducts | search : 'name' : productSearch).length === 0" class="no-products-msg">
         <i class="pi pi-inbox p-mr-1" style="font-size: 1.1rem"></i>No products match search {{productSearch}}.
         <br>
       </div>
       <div *ngFor="let product of availableProducts | search : 'name' : productSearch" (click)="handleClickOnProductRow(product)">
         <div class="product-item" *appVar="(product?.prices | activePrice) as activePrice">
           <div class="charge-row">
             <p class="p-text-bold p-mb-1">{{product?.name}}</p>
             <label>{{product?.category ? product?.category :'None'}} </label>
           </div>
           <div class="price-amount">
             <div class="price">
               <div>
                 <span *ngIf="product.quantity > 0 && activePrice?.priceType !== 'percentage'">
                   {{product.quantity}} x
                 </span>
                 <span *ngIf="activePrice?.priceType !== 'percentage'">{{ activePrice.amount / 100 | currency
                   : job?.currency || 'USD' }}</span>
                 <span *ngIf="activePrice?.priceType === 'percentage'">{{ activePrice.amount }}%</span>
               </div>
               <div>
                 <span *ngIf="product.quantity > 0 && activePrice?.priceType !== 'percentage'">
                   {{ (product.quantity * activePrice.amount / 100) | currency
                   : job?.currency || 'USD' }}
                 </span>
               </div>
             </div>
             <div *ngIf="activePrice.priceType !== 'percentage'">
               <p-inputNumber class="short-number-input" type="number" [minFractionDigits]="1" [maxFractionDigits]="2"
                 mode="decimal" [showButtons]="true" min="0" decrementButtonClass="p-button-secondary"
                 incrementButtonClass="p-button-success" incrementButtonIcon="pi pi-plus"
                 decrementButtonIcon="pi pi-minus" [(ngModel)]="product.quantity" buttonLayout="horizontal"
                 (ngModelChange)="handleQuantityChange(product, $event)" (click)="handleInputClick($event)"
                 [placeholder]="!product.quantity && 0">
               </p-inputNumber>
             </div>
             <div *ngIf="activePrice.priceType === 'percentage'">
               <p-inputSwitch [(ngModel)]="!!product.quantity" (ngModelChange)="handleQuantityChange(product, $event)"
                 [class]="{'short-number-input': true}" (click)="$event.stopPropagation()">
               </p-inputSwitch>
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>
 </div>
 <button *hasPermission="['charges.create']" pButton icon="pi pi-plus" label="Add Custom Charge"
   class="custom-charge-button" (click)="createCustomCharge()" [disabled]="!job?.id"></button>
 <ng-template pTemplate="footer">
   <div *ngIf="!productsLoading" class="buttons-row">
     <button pButton class="p-button-text p-m-1" icon="pi pi-times" type="button" label="Cancel"
       (click)="cancelCharges()"></button>
     <button pButton type="button" icon="pi pi-plus" (click)="addChargesToCurrentEvent()"
       [label]="addedFixedChargesTotal
         ? 'Add ' + (addedFixedChargesTotal / 100 | currency : job?.currency || 'USD') + ' charges'
         : 'Add charges'"
       [disabled]="!isChargesToAdd">
     </button>
   </div>
   <div class="subtotal-row">
     {{warningMessage}}
   </div>
 </ng-template>
</p-dialog>

<app-mutate-charges-v2
	#mutate
	mutateType="create">
</app-mutate-charges-v2>