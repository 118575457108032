import { createSelector } from "@ngrx/store";
import { LoadingState } from "src/app/utilities/state.util";
import { EstimatesJobFragment } from "graphql.generated";
import { jobToolFeature } from "../../job-tool.reducer";

export const selectJobUpdatingErrors = createSelector(
  jobToolFeature.selectCallState,
  (callState) => {
    const errorKeys = ['updateCustomer', 'updateJob', 'setFields'];
    const errors: string[] = [];

    errorKeys.forEach(key => {
      if (callState && callState[key]?.error) {
        errors.push(callState[key].error);
      }
    });

    return errors;
  }
);

export const selectJobUpdatingInProgress = createSelector(
  jobToolFeature.selectCallState,
  (callState) => {
    const loadingKeys = ['updateCustomer', 'updateJob', 'setFields'];

    return loadingKeys.some(key => callState && callState[key] === LoadingState.LOADING);
  }
);

export const selectCanChangeArea = createSelector(
  jobToolFeature.selectJob,
  (job) => {
    const hasTransactions = job?.transactions?.length;
    const hasCharges = job?.charges?.length;
    const hasDiscounts = (job as unknown as EstimatesJobFragment)?.discounts?.length;
    return !(hasTransactions || hasCharges || hasDiscounts);
  }
);

export const selectServerCopyAreaId = createSelector(
  jobToolFeature.selectJob,
  (job) => {
    const zone = job?.zone;
    if (zone && zone?.type === 'area') {
      return zone?.id;
    } else return undefined;
  }
);

export const jobUpdateSelectors = {
  selectJobUpdatingErrors,
  selectJobUpdatingInProgress,
  selectCanChangeArea,
  selectServerCopyAreaId,
};