import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Store } from "@ngrx/store";
import { cloneDeep } from 'lodash';
import { QuillEditorComponent, QuillModules } from 'ngx-quill';

import { TabPanel } from 'primeng/tabview';
import { Delta } from 'quill/core';
import { BehaviorSubject, combineLatest, distinctUntilChanged } from 'rxjs';
import { DistanceService } from "src/app/estimates/distance.service";
import { FreyaCommonModule } from 'src/app/freya-common/freya-common.module';
import { JOB_CREATE_INDEXES, JOB_SUMMARIES, jobSummaryDefaultToolbar } from 'src/app/global.constants';
import { LibModule } from 'src/app/lib.ts/lib.module';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { SharedModule } from 'src/app/shared/shared.module';

import { SubSink } from "subsink";

import { brandingFeature } from '../../../state/branding.store';
import { genFeatureSelector } from '../../../state/featureFlags.store';
import { JobChange, Modes, Summary, jobToolFeature } from '../../job-tool.reducer';

import { selectJobUpdatingErrors } from '../../jobv2-edit/jobv2-edit-state/jobv2-edit.selectors';
import { selectJobSavingErrors, selectSavingDisabled } from '../jobv2-create-state/jobv2-create.selectors';
import { JobSummaryActions } from '../jobv2-create-summary-state/jobv2-create-summary.actions';
import { JobV2FormActionButtonComponent } from '../jobv2-form-action-button/jobv2-form-action-button';




@Component({
    selector: 'app-jobv2-summary',
    standalone: true,
    imports: [
        FreyaCommonModule,
        LibModule,
        SharedModule,
        QuillEditorComponent,
        JobV2FormActionButtonComponent
    ],
    templateUrl: './jobv2-summary.component.html',
    styleUrls: ['./jobv2-summary.component.scss']
})
export class Jobv2SummaryComponent implements OnInit, OnDestroy, AfterViewInit {
    
    @Input() renderHeadless = false;
    @Input() defaultActiveTabIndex = 0;

    @ViewChildren(QuillEditorComponent) editors: QueryList<QuillEditorComponent>;
    @ViewChildren('tabHeaders', { read: ElementRef }) tabHeaders: QueryList<ElementRef>;
    @ViewChildren(TabPanel) tabPanels: QueryList<TabPanel>;

    constructor(
        private store: Store,
        public distanceService: DistanceService,
        public localNotify: FreyaNotificationsService,
    ) { }

    private subs = new SubSink();

    configs$ = this.store.select(brandingFeature.selectConfigs);
    selectSavingDisabled$ = this.store.select(selectSavingDisabled);

    //error handling
    savingDisabledMessage = 'A customer name is required to create this job.';
    jobSavingErrors$ = this.store.select(selectJobSavingErrors);
    errorMessage = '';

    jobUpdatingErrors$ = this.store.select(selectJobUpdatingErrors);
    errorUpdateMessage = '';
    creatingDisabled = false;

    //modes
    mode: Modes;
    jobFormMode$ = this.store.select(jobToolFeature.selectJobFormMode);
    jobsV2Enabled$ = this.store.select(genFeatureSelector('jobs-v2'));

    changes$ = this.store.select(jobToolFeature.selectChanges);
    changes: JobChange[] = [];

    jobInput$ = this.store.select(jobToolFeature.selectJobInput);

    editorsReady$ = new BehaviorSubject<boolean>(false);

    editModules: QuillModules = {
        toolbar: jobSummaryDefaultToolbar,
        keyboard: {
            bindings: {
              submit: {
                key: 'Enter',
                ctrlKey: true,
                handler: (range, context) => {
                  this.moveBackToSummaryTab();
                },
              },
            },
          },
    };

    crewSummary: Summary;
    adminSummary: Summary;
    customerSummary: Summary;

    crewSummaryContents: Delta | string;
    adminSummaryContents: Delta | string;
    customerSummaryContents: Delta | string;
    summariesMap = {};
    jobId: string;
    initialContentLoaded = false;

    jobSummaries = JOB_SUMMARIES;

    jobCreateIndexes = JOB_CREATE_INDEXES;
    activeTabIndex: number = 0;

    ngOnInit(): void {

        this.activeTabIndex = this.defaultActiveTabIndex;

        this.summariesMap = {
          crewSummary: this.crewSummaryContents,
          adminSummary: this.adminSummaryContents,
          customerSummary: this.customerSummaryContents,
        }

        this.subs.sink = this.jobFormMode$.subscribe((jobFormMode) => {
          this.mode = jobFormMode;
        });

        this.subs.sink = this.changes$.subscribe((changes) => {
          this.changes = changes;
        });

        this.subs.sink = this.selectSavingDisabled$.subscribe((selectSavingDisabled) => {
          this.creatingDisabled = selectSavingDisabled;
        });

        this.subs.sink = this.configs$.subscribe((configs) => {
          if (configs['jobs.howHeardMandatory']) {
            this.savingDisabledMessage = 'A customer name and referral source are required to create this job.'
          }
        })

        this.subs.sink = this.jobInput$
        .pipe(distinctUntilChanged())
        .subscribe((jobInput) => {
          this.crewSummary = cloneDeep(jobInput?.crewSummary);
          this.adminSummary = cloneDeep(jobInput?.adminSummary);
          this.customerSummary = cloneDeep(jobInput?.customerSummary);
          this.jobId = jobInput.id;

          this.crewSummaryContents = this.handleUndefined(this.crewSummary);
          this.adminSummaryContents = this.handleUndefined(this.adminSummary);
          this.customerSummaryContents = this.handleUndefined(this.customerSummary);
        });

          this.subs.sink = this.jobSavingErrors$.subscribe((jobSavingErrors) => {
            const defaultError = 'Errors occurred, job could not be created. Resolve these errors and try again.'
            if (jobSavingErrors.length) {
                this.errorMessage = defaultError;
            }
          });

          this.subs.sink = this.jobUpdatingErrors$.subscribe((jobUpdatingErrors) => {
            const defaultError = 'Errors occurred, job could not be created. Resolve these errors and try again.'
            if (jobUpdatingErrors.length) {
                this.errorUpdateMessage = defaultError;
            }
          });
    }

    ngAfterViewInit(): void {
      this.editorsReady$.next(true);
      this.setupTabHeaderClickEvents();
      this.updateEditorContentsIfReady();
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();

        this.tabHeaders.forEach((tabHeader) => {
          tabHeader.nativeElement.removeEventListener('click', this.focusEditor);
        });
    }

    updateEditorContentsIfReady(): void {
      combineLatest([this.editorsReady$, this.jobInput$])
          .pipe(distinctUntilChanged())
          .subscribe(([editorsReady, jobInput]) => {
              if (editorsReady && !this.initialContentLoaded) {
                  this.setEditorContents({
                    crewSummary: cloneDeep(jobInput.crewSummary),
                    adminSummary: cloneDeep(jobInput.adminSummary),
                    customerSummary: cloneDeep(jobInput.customerSummary),
                  });
                  this.initialContentLoaded = true;
              }
          });
    }

    setEditorContents(summaries): void {
      this.editors.forEach((editor, index) => {
        const trySetContents = (attempts = 3) => {
          const editorInstance = editor.quillEditor;
          if (editorInstance) {
            const summaryKey = Object.keys(summaries)[index];
            const content = this.handleUndefined(summaries[summaryKey]);
            if (content) {
              editorInstance.setContents(content, 'api');
            }
          } else if (attempts > 0) {
            setTimeout(() => trySetContents(attempts - 1), 100);
          }
        };
        trySetContents();
      });
    }

    handleUndefined(summary: Summary): Delta {
      if (summary?.text) {
          return summary.contents;
      } else {
          return new Delta([{ insert: '' }]);
      }
    }

    onEditorChange(key: string, index: number) {
        const text = this.editors.toArray()[index].quillEditor.getText();
        const contents = this.editors.toArray()[index].quillEditor.getContents();

        this.store.dispatch(JobSummaryActions.updateSummary({
            text,
            contents,
            key,
        }));
    }

    moveBackToSummaryTab() {
        const focusableElements = Array.from(document.querySelectorAll<HTMLElement>('div[tabindex]'))
          .filter(el => el.tabIndex >= 0);

        focusableElements.sort((a, b) => a.tabIndex - b.tabIndex);

        const nextElement = focusableElements[0];
        nextElement.focus();
      }

    setupTabHeaderClickEvents() {
      this.tabHeaders.forEach((tabHeader, index) => {
        tabHeader.nativeElement.addEventListener('click', () => {
          this.focusEditor(index);
        });
      });
    }

    focusEditor(index: number) {
      const editor = this.editors.toArray()[index];
      if (editor) {
        editor.quillEditor.focus();
      }
    }
}
