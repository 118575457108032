import { Pipe, PipeTransform } from '@angular/core';

import { BaseLocationFragment } from '../../generated/graphql.generated';
import { getLocationAreaCode } from '../utilities/locations.util';

@Pipe({
  name: 'location'
})
export class LocationPipe implements PipeTransform {

  transform(location: BaseLocationFragment): unknown {

    if (!location) { return; }

    const addressLineOne = location?.addressLineOne?.trim();
    const addressLineTwo = location?.addressLineTwo?.trim();
    const areaCode = getLocationAreaCode(location);
    const city = location?.city;

    let text: string;

    if (addressLineOne && addressLineTwo && city) {
      text = `${addressLineTwo} - ${addressLineOne} ${city} ${areaCode}`;
    } else if (addressLineOne && city) {
      text = `${addressLineOne} ${city} ${areaCode}`;
    } else {
      text = areaCode;
    }

    const url = `https://www.google.com/maps/place/${encodeURIComponent(text)}`;

    return {
      text,
      url
    };
  }

}
