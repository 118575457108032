<div [formGroup]="chargeForm">
    <ng-template #product>
        <div *appVar="chargeForm.get('product') as control">
            <input *ngIf="prefilledChargeName" type="text" pInputText [value]="prefilledChargeName" formControlName="product"/>
            <p-autoComplete *ngIf="!isDamage && !prefilledChargeName"
                field="name"
                formControlName="product"
                [suggestions]="productSuggestions"
                (completeMethod)="searchProduct($event)"
                (onSelect)="setPriceAmount()"
                (onKeyUp)="setPriceAmount()"
                [dropdown]="!isDamage"
                appendTo="body"
            >
                <ng-template
                    pTemplate="item"
                    let-product
                >
                    {{product?.id ? product.name : prodcut}}
                </ng-template>

            </p-autoComplete>
            <p-autoComplete *ngIf="isDamage && !prefilledChargeName"
                formControlName="product"
                [suggestions]="productSuggestions"
                (completeMethod)="searchProduct($event)"
                (onSelect)="setPriceAmount()"
                (onKeyUp)="setPriceAmount()"
                [dropdown]="!isDamage"
                appendTo="body"
            >
                <ng-template
                    pTemplate="item"
                    let-product
                >
                    {{product?.id ? product.name : prodcut}}
                </ng-template>

            </p-autoComplete>
            <app-validation-messages
                [control]="control"
                [requireTouched]="true"
            ></app-validation-messages>
        </div>
    </ng-template>

    <ng-template #amount>
        <div *appVar="chargeForm.get('amount') as control">
            <p-inputNumber
                *ngIf="!isPercentCharge"
                mode="currency"
                [currency]="job?.currency || 'USD'"
                formControlName="amount"
                (onBlur)="forceNegativeAmount()"
            ></p-inputNumber>
            <p-inputNumber
                *ngIf="isPercentCharge"
                [currency]="job?.currency || 'USD'"
                formControlName="amount"
                suffix="%"
            ></p-inputNumber>
            <br>
            <small *ngIf="control.disabled">The amount has been set to the active price on the product.</small>
            <app-validation-messages
                [control]="control"
                [requireTouched]="true"
            ></app-validation-messages>
        </div>
    </ng-template>

    <ng-template #quantity>
        <div *appVar="chargeForm.get('quantity') as control">
            <p-inputNumber
                [suffix]="productUnitLabel"
                min="0.01"
                [minFractionDigits]="1"
                [maxFractionDigits]="2"
                [showButtons]="true"
                formControlName="quantity"
            ></p-inputNumber>
            <app-validation-messages
                [control]="control"
                [requireTouched]="true"
            ></app-validation-messages>
        </div>
    </ng-template>

    <ng-template #event>
        <div *appVar="chargeForm.get('event') as control">
            <app-event-select-v2
                formControlName="event"
                [filter]="{jobId: job?.id}"
                [limit]="1"
                [disabledEvents]="{
                    locked: true,
                    invoiced: true
                }"
                [showInvoiceInfo]="true"
                [preselectEventId]="preselectedEventId"
            ></app-event-select-v2>
            <app-validation-messages
                [control]="control"
                [requireTouched]="true"
            ></app-validation-messages>
        </div>
    </ng-template>

    <ng-template #taxes>
        <div
            class="p-grid"
            *appVar="chargeForm.get('taxes') as control"
        >
            <div class="p-col">
                <p-multiSelect
                    [options]="taxOptions"
                    class="full-width-multiselect"
                    formControlName="taxes"
                    [showToggleAll]="false"
                    optionLabel="name"
                    dataKey="id"
                    [maxSelectedLabels]="1"
                    [selectedItemsLabel]="'{0} Taxes Selected'"
                    [autofocusFilter]="!responsiveHelper.isSmallScreen"
                    appendTo="body"
                >
                    <ng-template
                        let-tax
                        pTemplate="item"
                    >
                        <div>
                            <div>{{tax.name}}</div>
                        </div>
                    </ng-template>
                </p-multiSelect>
            </div>
            <div class="p-col">
                <span class="chip-container">
                    <p-chip
                        *ngFor="let tax of control.value"
                        [removable]="true"
                        (onRemove)="removeTax(tax)"
                        [label]="tax.name"
                    >
                    </p-chip>
                </span>
            </div>
        </div>
    </ng-template>
    <!-- TODO: Create a reusable component for this (used in mutate-charges and mutate-prices) -->
    <ng-template #expenses >
        <div formArrayName="expenses" class="p-datatable" >
         <table style="width: 100%;" class="p-datatable-table" >
             <thead class="p-datatable-thead" >
                 <tr>
                     <th style="min-width: 14rem;">Name</th>
                     <th>Unit Cost {{singularProductUnit}} </th>
                     <th>Total Cost</th>
                     <th></th>
                 </tr>
             </thead>
             <tbody class="p-datatable-tbody" >
                 <tr *ngFor="let expenseControl of expensesForm.controls; index as ri">
                     <td *appVar="expenseControl.get('expenseId') as control">
                         <p-dropdown
                             [options]="expenseOptions"
                             appendTo="body"
                             placeholder="Select Cost"
                             [formControl]="control"
                             optionLabel="name"
                             optionValue="id"
                             [ngClass]="{'ng-invalid': !control.valid, 'ng-dirty': control.touched}"
                         ></p-dropdown>
                     </td>
                     <td *appVar="expenseControl.get('unitCost') as control">
                         <p-inputNumber 
                             [formControl]="control"
                             inputId="currency-us" 
                             mode="currency" 
                             [currency]="job?.currency || 'USD'" 
                             locale="en-US"
                             [ngClass]="{'ng-invalid': !control.valid, 'ng-dirty': control.touched}"
                         /> 
                     </td>
                     <td>
                        {{expenseControl.value.unitCost * chargeForm.value.quantity | currency: job?.currency || 'USD'}}
                     </td>
                     <td>
                         <p-button icon="pi pi-trash" severity="danger" size="small" (click)="onRowDelete(ri)"/>
                     </td>
                 </tr>
             </tbody>
             <tfoot class="p-datatable-tfoot">
                 <tr>
                     <td colspan="3">
                         <p-button label="Add Cost" icon="pi pi-plus" size="small" [outlined]="true" (click)="onRowAdd()"></p-button>
                     </td>
                 </tr>
             </tfoot>
         </table>
         <small class="field-error-message p-mt-2" *ngIf="expensesForm.invalid && expensesForm.hasError('duplicateExpense') && (expensesForm.touched || expensesForm.dirty)">
             Costs must be unique. Please remove duplicate costs.
         </small>
        </div>
     </ng-template>
</div>

<app-mutate-object-v2
    #mutateRef
    [fg]="chargeForm"
    objectType="Charge"
    mutateType="create"
    [steps]="steps"
    (objectMutated)="mutateObject()"
    (activeStepIndexChange)="onStepChange($event)"
    scrollHeight="300px"
></app-mutate-object-v2>
