import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { SubSink } from 'subsink';

import { FreyaCommonModule } from '../../../freya-common/freya-common.module';
import { SharedModule } from '../../../shared/shared.module';
import { JobToolActions } from '../../job-tool.actions';
import { jobToolFeature } from '../../job-tool.reducer';
import { Jobv2CreateCustomerComponent } from '../../jobv2-create/jobv2-create-customer/jobv2-create-customer.component';
import { JobTimelinePipe } from '../job-timeline.pipe';

@Component({
  selector: 'app-overview-customer',
  standalone: true,
  imports: [
    FreyaCommonModule,
    SharedModule,
    JobTimelinePipe,
    Jobv2CreateCustomerComponent
  ],
  templateUrl: './overview-customer.component.html',
  styleUrl: './overview-customer.component.scss'
})
export class OverviewCustomerComponent implements OnDestroy {

  public job$ = this.store.select(jobToolFeature.selectJob);
  public jobLoaded$ = this.store.select(jobToolFeature.jobLoaded);
  public customer$ = this.store.select(jobToolFeature.selectCustomer);
  public customerName$ = this.store.select(jobToolFeature.selectCustomerName);
  public jobSource$ = this.store.select(jobToolFeature.selectJobSource);
  public jobMetadata$ = this.store.select(jobToolFeature.selectJobMetadata);

  private subs = new SubSink();


  public isEditMode = false;
  constructor(private store: Store) { }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public editCustomer() {
    this.isEditMode = true;
    this.store.dispatch(JobToolActions.customerDetailsInPlaceEditingActivated());
  }

  public cancelEditCustomer() {
    this.isEditMode = false;
    this.subs.unsubscribe();
  }

  updateJob() {
    this.store.dispatch(JobToolActions.jobUpdateRequested());

    const updateSuccess$ = this.store.select(jobToolFeature.updateJobLoaded);

    // Subscribe to the success indicator
    this.subs.unsubscribe();
    this.subs.sink = updateSuccess$.subscribe((success) => {
      if (success) {
        this.cancelEditCustomer();
      }
      // If not successful, do nothing and let the user adjust as needed
    });
  }
}
