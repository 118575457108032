<button
    *hasPermission="['jobs.update']"
    pButton type="button" label="Add Discounts"
    icon="pi pi-plus"
	class="add-discounts-button"
	(click)="openDiscountsModal()"
    [disabled]="jobLoading || getDisabledStatus(event, job).disabledStatus"
   	[pTooltip]="getDisabledStatus(event, job).disabledStatus ? getDisabledStatus(event, job).disabledToolTip : ''"
    tooltipPosition="left">
</button>

<p-dialog
    header="Apply Discount"
    [modal]="true"
    [style]="{width: '50vw'}"
    [breakpoints]="{'992px': '62vw', '510px': '93vw'}"
    [(visible)]="showApplyDiscountDialog"
    (onHide)="applyDiscountForm.reset()"
>
    <div [formGroup]="applyDiscountForm">
			<div class="p-grid p-fluid">
				<div class="p-col-12 p-md-4">
					<label>Discount</label>
					<br />
					<p-dropdown
							placeholder="Selected Discount"
							[options]="availableDiscounts"
							appendTo="body"
							formControlName="discount"
					>
						<ng-template pTemplate="item" let-discount>
							{{ discount.name }} ({{ discount.code }})
						</ng-template>
						<ng-template pTemplate="selectedItem" let-discount>
							{{ discount.name }} ({{ discount.code }})
						</ng-template>
					</p-dropdown>
				</div>
				<div class="p-col-12 p-md-4" *appVar="applyDiscountForm.get('amount') as control">
					<label>Amount
					</label>
					<br/>
					<p-inputNumber
						*ngIf="job?.currency || (job && defaultCurrency)"
						formControlName="amount"
						placeholder="Enter amount"
						[showButtons]="true"
						[step]="selectedDiscount?.discountType === 'percentage' ? 1 : 0.25"
						[mode]="selectedDiscount?.discountType === 'percentage' ? 'decimal' : 'currency'"
						[currency]="job?.currency || defaultCurrency"
						[minFractionDigits]="selectedDiscount?.discountType === 'amount' ? 1 : 0"
						[suffix]="selectedDiscount?.discountType === 'percentage' ? '%' : undefined"
						[min]="amountInputAtts.min"
						[max]="amountInputAtts.max"
					></p-inputNumber>
						<small *ngIf="selectedDiscount">
							Range: {{ selectedDiscount?.customAmountRange | discountCustomAmountRange : selectedDiscount?.discountType : (job.currency || defaultCurrency) }}
						</small>
					<app-validation-messages [control]="control"></app-validation-messages>
				</div>
				<div class="p-col-12 p-md-4">
					<label>Select Event</label>
					<br/>
					<app-event-select-v2
						formControlName="events"
						[disabledEvents]="{
							locked: true,
							invoiced: true,
							shouldNotHaveCharges: true,
						}"
						[preselectEventId]="event?.id">
					</app-event-select-v2>
			</div>
		</div>
	</div>
	<ng-template pTemplate="footer">
		<button pButton (click)="showApplyDiscountDialog = false" icon="pi pi-times" label="Cancel"></button>
		<button pButton (click)="openCreateDiscountDialog()" icon="pi pi-plus" label="New Single Use"></button>
		<button pButton [disabled]="!applyDiscountForm.valid" (click)="addDiscountToEvents()" icon="pi pi-check"
			label="Apply Changes" class="p-button-success"></button>
	</ng-template>
</p-dialog>

<app-mutate-discount-v2
	#mutate
	mutateType="create"
	[currentEventId]="event?.id">
</app-mutate-discount-v2>