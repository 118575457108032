import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { FreyaCommonModule } from "src/app/freya-common/freya-common.module";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { WorkOrdersActions } from "../job-state/workorders-state/workorders.actions";
import { Subject } from "rxjs";
import { SharedModule } from "src/app/shared/shared.module";

@Component({
    selector: 'app-leave-jobsv2',
    standalone: true,
    imports: [
      SharedModule,
      FreyaCommonModule,
    ],
    templateUrl: './leave-jobsv2-confirm.component.html',
    styleUrls: ['./leave-jobsv2-confirm.component.scss']
  })
  export class LeaveJobsv2Component {

    private sub: Subject<boolean>;

    constructor(
      private store: Store,
      public ref: DynamicDialogRef,
      public config: DynamicDialogConfig,
    ) {
      this.sub = config.data.sub;
    }

    closeDiscardDialog(closed: boolean){
        this.ref.close(closed);
    }

    discardChanges() {
      this.store.dispatch(WorkOrdersActions.cancelButtonClicked({}));
      this.sub.next(true);
      this.closeDiscardDialog(true);
    }

    saveChanges() {
      this.store.dispatch(WorkOrdersActions.saveButtonClicked());
      this.sub.next(true);
      this.closeDiscardDialog(true);
    }

    leaveAndSaveLater() {
      this.sub.next(true);
      this.closeDiscardDialog(true);
    }
  }