import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { combineLatest, distinctUntilChanged } from 'rxjs';
import { FreyaCommonModule } from 'src/app/freya-common/freya-common.module';
import { AddLocationType, CONFIGS_KEYS, FIELD_CONFIG, JOB_CREATE_INDEXES, estimating } from 'src/app/global.constants';
import { SubSink } from 'subsink';

import { brandingFeature } from '../../../state/branding.store';
import { jobToolFeature } from '../../job-tool.reducer';
import { JobCreateLocationsActions } from '../jobv2-create-locations-state/jobv2-create-locations-state.actions';

import { jobCreateSelectors } from '../jobv2-create-state/jobv2-create.selectors';

export interface Location {
    key: AddLocationType;
    label: string;
  }


export type LocationFieldNameSpace = 'buildingType' | 'sqft' | 'stairs' | 'bedrooms' | 'accessNotes' | 'accessOptions';

@Component({
    selector: 'app-jobv2-create-locations-details',
    standalone: true,
    imports: [
        FreyaCommonModule,
    ],
    templateUrl: './jobv2-create-locations-details.component.html',
    styleUrls: ['./jobv2-create-locations-details.component.scss']
})
export class Jobv2LocationsDetailsComponent implements OnInit, OnDestroy {

    @Input()locationType: Location;

    constructor(
        private store: Store,
    ) { }

    private subs = new SubSink();

    fieldsInputValues$;
    locationsInput$ = this.store.select(jobToolFeature.selectLocationsInputs);

    changes$ = this.store.select(jobToolFeature.selectChanges);
    configs$ = this.store.select(brandingFeature.selectConfigs);

    address: string;
    buildingType: string;

    STAIRS_DROPDOWN_OPTIONS = estimating.stairsDropDownOptions;
    BEDROOMS_DROPDOWN_OPTIONS = estimating.bedroomDropdownOptions;
    ACCESS_OPTIONS = estimating.accessOptions;

    stairsSelectedOption: string;
    bedroomsSelectedOption: number;
    sqftSelectedOption: number;
    accessNotes: string;
    accessSelectedOptions: string[];

    jobCreateIndexes = JOB_CREATE_INDEXES;

    bedroomsMandatory = false;

    ngOnInit(): void {
        if (this.locationType?.key) {
            this.fieldsInputValues$ = this.store.select(jobCreateSelectors.selectFieldsValues(this.locationType.key));

            this.subs.sink = this.fieldsInputValues$
                .pipe(distinctUntilChanged())
                .subscribe(fieldsValues => {
                    this.sqftSelectedOption = fieldsValues.sqftSelectedOption;
                    this.accessNotes = fieldsValues.accessNotes;
                    this.buildingType = fieldsValues.buildingType;
                    this.bedroomsSelectedOption = fieldsValues.bedroomsSelectedOption;
                    this.stairsSelectedOption = fieldsValues.stairsSelectedOption;
                    this.accessSelectedOptions = fieldsValues.accessSelectedOptions;
                });
        }

        this.subs.sink = this.locationsInput$
            .pipe(distinctUntilChanged())
            .subscribe(locationsInput => {
                this.address = locationsInput?.[this.locationType.key]?.addressLineOne;
            });

        this.subs.sink = combineLatest([
            this.changes$,
            this.configs$
        ]).pipe(distinctUntilChanged())
        .subscribe(([changes, configs]) => {

            const locationInfoChanged = changes?.find(
                c => c?.fieldName.includes(`${this.locationType?.key}`));

            if (configs['jobs.bedroomsMandatory'] && locationInfoChanged) {
                this.bedroomsMandatory = true;
            } else {
                this.bedroomsMandatory = false;
            }
        });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    onLocationFieldTyped(option: string, locationType: AddLocationType, namespace: LocationFieldNameSpace) {
        this.store.dispatch(JobCreateLocationsActions.setLocationFields({
            fieldName: FIELD_CONFIG[locationType]?.[namespace]?.name,
            fieldType: FIELD_CONFIG[locationType]?.[namespace]?.type,
            fieldValue: option,
        }));
    }

    onLocationFieldSelected(option: DropdownChangeEvent, locationType: AddLocationType, namespace: LocationFieldNameSpace) {
        this.store.dispatch(JobCreateLocationsActions.setLocationFields({
            fieldName: FIELD_CONFIG[locationType]?.[namespace]?.name,
            fieldType: FIELD_CONFIG[locationType]?.[namespace]?.type,
            fieldValue: option.value,
        }));
    }

    generateId(subKey: string): string | null {
        const key = this.locationType?.key;
        if (key) {
            return (key + subKey);
        } else {
            return null;
        }
    }
}