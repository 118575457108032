import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { UpdateJobGQL } from 'graphql.generated';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DetailsHelperService } from 'src/app/services/details-helper.service';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { SubSink } from 'subsink';

import { BaseJobFragment, Job_UsersFragment } from '../../../generated/graphql.generated';
import { MenuService } from '../../base/menu/app.menu.service';

import { createConfigSelector } from '../../state/branding.store';

@Component({
  selector: 'app-close-job',
  templateUrl: './close-job.component.html',
  styleUrls: ['./close-job.component.scss', '../../dynamic-dialog-styling.scss']
})
export class CloseJobComponent implements OnInit, OnDestroy {

  @Input() job: BaseJobFragment & Job_UsersFragment;

  closeJobForm = new UntypedFormGroup({
    reason: new UntypedFormControl(undefined, [Validators.required]),
    explanation: new UntypedFormControl(undefined, []),
  });

  closureReasons$ = this.store.select(createConfigSelector('jobs.closedReasons', (reasons) => {
    return reasons
      .filter((r) => r.stages?.length === 0 || r.stages.includes(this.job.stage))
      .map((r) => ({ option: r.id, label: r.title }));
  }));

  subs = new SubSink();

  constructor(
    private menuService: MenuService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private updateJob: UpdateJobGQL,
    private notify: FreyaNotificationsService,
    private detailsHelper: DetailsHelperService,
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.job = this.config.data?.job;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  closeJob() {
    this.menuService.pushJob(this.job, 0, true);

    this.updateJob.mutate({
      updateJobs: [{
        jobId: this.job.id,
        closeJob: true,
        closedReason: this.closeJobForm.value.reason.option
      }],
    }).subscribe(() => {
      this.detailsHelper.pushUpdate({
        id: this.job.id,
        type: 'Jobs',
        action: 'update',
      });

      this.notify.success('Job closed');
    }, (err) => {
      this.notify.apolloError('Failed to close job', err);
    });

    this.closeDialog(true);
  }

  closeDialog(closed: boolean){
    this.ref.close(closed);
  }

}
