import { createActionGroup, props } from '@ngrx/store';

import { BaseZoneFragment } from 'graphql.generated';

export const ZonesActions = createActionGroup({
	source: 'Zones',
	events: {
		'Zone Changed': props<{
			currentAppZone: BaseZoneFragment
		}>(),
	},
});
