import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { FreyaCommonModule } from "src/app/freya-common/freya-common.module";

import { SubSink } from "subsink";

import { ConfigValue } from '../../../services/frontend-configs';
import { brandingFeature } from '../../../state/branding.store';
import { JobToolActions } from "../../job-tool.actions";
import { jobToolFeature } from "../../job-tool.reducer";
import { JobCreateActions } from "../jobv2-create-state/jobv2-create.actions";

@Component({
    selector: 'app-close-job-component-v2',
    standalone: true,
    imports: [
      FreyaCommonModule,
    ],
    templateUrl: './close-job-componentV2.component.html',
    styleUrls: ['./close-job-componentV2.component.scss']
  })
  export class CloseJobV2Component implements OnInit, OnDestroy {

    newJob: boolean;

    constructor(
      private store: Store,
      public ref: DynamicDialogRef,
    ) { }

    private subs = new SubSink();

    configs$ = this.store.select(brandingFeature.selectConfigs);
    closedReason$ = this.store.select(jobToolFeature.selectClosedReason);
    job$ = this.store.select(jobToolFeature.selectJob);

    closureReasons: ConfigValue<'jobs.closedReasons'>;
    selectedClosedReason: string | undefined;
    jobId: string | undefined = undefined;

    ngOnInit(): void {

      this.subs.sink = this.configs$.subscribe((configs) => {
        this.closureReasons = configs['jobs.closedReasons'].filter(i =>
          Array.isArray(i.stages) && i.stages.length === 0
        );
      });

      this.subs.sink = this.closedReason$.subscribe((closedReason) => {
        this.selectedClosedReason = closedReason;
      });

      this.subs.sink = this.job$.subscribe((job) => {
        this.jobId = job?.id;
      });
    }

    ngOnDestroy(): void {
      this.subs.unsubscribe();
    }

    onClosureReasonSelected(event) {
      this.store.dispatch(JobCreateActions.selectClosedReason({ closedReason: event }));
    }

    closeJob() {
        if (this.newJob) {
          this.store.dispatch(JobCreateActions.saveJobForm());
          this.closeDialog(true);
        } else {
          this.store.dispatch(JobToolActions.closeExistingJob({
            jobId: this.jobId, closedReason:
            this.selectedClosedReason
          }));
          this.closeDialog(true);
        }
    }

    closeDialog(closed: boolean){
        this.ref.close(closed);
    }
  }
