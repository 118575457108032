import { on } from '@ngrx/store';

import { JOB_FORM_FIELDS } from 'src/app/global.constants';

import { JobToolState } from '../../job-tool.reducer';

import { trackChanges } from '../../jobsv2-helpers';

import { JobSummaryActions } from './jobv2-create-summary.actions';

export const jobSummaryReducers = [
    on(
        JobSummaryActions.updateSummary,
        (state: JobToolState, res): JobToolState => {
            const changes = trackChanges(state.changes, {
                fieldName: JOB_FORM_FIELDS[res.key],
                namespace: 'jobInput',
                value: {
                    text: res.text,
                    contents: res.contents,
                }
            }, true);

            return {
                ...state,
                jobInput: {
                    ...state.jobInput,
                    [res.key]: {
                        text: res.text,
                        contents: res.contents,
                    }
                },
                changes,
            };
        }
    ),
] as const;
