import { Component, HostListener, Input, OnDestroy, OnInit, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";

import { isEmpty } from "lodash";

import { DialogService } from "primeng/dynamicdialog";
import { first } from "rxjs";
import { FreyaCommonModule } from "src/app/freya-common/freya-common.module";
import { CONFIGS_KEYS, JobCreationFormAction, requiredFieldsValidationMessages } from "src/app/global.constants";
import { FreyaNotificationsService } from "src/app/services/freya-notifications.service";
import { SubSink } from "subsink";

import { brandingFeature } from '../../../state/branding.store';
import { JobChange, jobToolFeature } from "../../job-tool.reducer";
import { JobEditActions } from "../../jobv2-edit/jobv2-edit-state/jobv2-edit.actions";
import { selectJobUpdatingInProgress } from "../../jobv2-edit/jobv2-edit-state/jobv2-edit.selectors";
import { CloseJobV2Component } from "../close-job-componentV2/close-job-componentV2.component";
import { JobCreateActions } from "../jobv2-create-state/jobv2-create.actions";
import { selectFullNameSelected, selectHowHeardSelected, selectJobSavingInProgress, selectRequiredLocationDetailsSelected } from "../jobv2-create-state/jobv2-create.selectors";


@Component({
    selector: 'app-form-action-button',
    standalone: true,
    imports: [
        FreyaCommonModule,
    ],
    templateUrl: './jobv2-form-action-button.html',
    styleUrls: ['./jobv2-form-action-button.scss']
})
export class JobV2FormActionButtonComponent implements OnInit, OnDestroy {

    @Input() action: JobCreationFormAction;
    @Output() buttonClicked = new EventEmitter();

    @HostListener('window:keydown.control.s', ['$event'])
    onControlS(event: KeyboardEvent) {
        event.preventDefault();
        if (!this.isButtonDisabled()) {
            this.create();
        } else {
            this.localNotify.addToast.next({ severity: 'warn', summary: 'Fill out required fields to create job' });
        }
    }

    @HostListener('window:keydown.control.shift.s', ['$event'])
    onControlShiftS(event: KeyboardEvent) {
        event.preventDefault();
        if (!this.isButtonDisabled()) {
            this.createClose();
        } else {
            this.localNotify.addToast.next({
                severity: 'warn',
                summary: 'Fill out required fields to create and close job',
            });
        }
    }

    constructor(
        private store: Store,
        private dialogService: DialogService,
        public localNotify: FreyaNotificationsService,
        public router: Router,
    ) { }

    private subs = new SubSink();

    //observables
    jobFormMode$ = this.store.select(jobToolFeature.selectJobFormMode);
    jobInput$ = this.store.select(jobToolFeature.selectJobInput);
    jobSaving$ = this.store.select(selectJobSavingInProgress);
    jobUpdating$ = this.store.select(selectJobUpdatingInProgress);
    changes$ = this.store.select(jobToolFeature.selectChanges);
    configs$ = this.store.select(brandingFeature.selectConfigs);
    selectFullNameSelected$ = this.store.select(selectFullNameSelected);
    howHeardSelected$ = this.store.select(selectHowHeardSelected);
    validationErrors$ = this.store.select(jobToolFeature.selectValidationErrors);
    requiredLocationDetailsSelected$ = this.store.select(selectRequiredLocationDetailsSelected);

    changes: JobChange[] = [];

    editMode: boolean;

    jobId: string;

    jobSaving = false;
    jobUpdating = false;

    howHeardMandatory = false;
    howHeardSelected = false;
    fullNameSelected = false;
    formIsValid = true;
    requiredFieldsProvided = true;
    savingDisabledMessage = 'A customer name is required to create this job.';

    ngOnInit(): void {
        this.subs.sink = this.jobFormMode$.subscribe((jobFormMode) => {
            this.editMode = jobFormMode === 'edit';
        });

        this.subs.sink = this.jobInput$.subscribe((jobInput) => {
            this.jobId = jobInput.id;
        });

        this.subs.sink = this.configs$.subscribe((configs) => {
            if (!this.editMode) {
                this.howHeardMandatory = configs['jobs.howHeardMandatory'];
                this.savingDisabledMessage = 'A customer name and referral source are required to create this job.'
            }
        });

        this.subs.sink = this.changes$.subscribe((changes) => {
            this.changes = changes;
          });

        this.subs.sink = this.howHeardSelected$.subscribe((howHeardSelected) => {
            this.howHeardSelected = howHeardSelected;
        });

        this.subs.sink = this.requiredLocationDetailsSelected$.subscribe((requiredLocationDetailsSelected) => {

            this.requiredFieldsProvided = !requiredLocationDetailsSelected.formInvalid;

            if (requiredLocationDetailsSelected.bedroomsMandatory
                && !this.savingDisabledMessage.includes(requiredFieldsValidationMessages.bedrooms)) {
                this.savingDisabledMessage += requiredFieldsValidationMessages.bedrooms;
            }

            if (requiredLocationDetailsSelected.dwellingMandatory
                && !this.savingDisabledMessage.includes(requiredFieldsValidationMessages.dwellingType)) {
                this.savingDisabledMessage += requiredFieldsValidationMessages.dwellingType;
            }
        });

        this.subs.sink = this.selectFullNameSelected$.subscribe((selectFullNameSelected) => {
            this.fullNameSelected = selectFullNameSelected;
        });

        this.subs.sink = this.validationErrors$.subscribe((validationErrors) => {
            if (!isEmpty(validationErrors)) {
                this.formIsValid = false;
            } else this.formIsValid = true;
        });

        this.subs.sink = this.jobSaving$.subscribe((jobSaving) => {
            this.jobSaving = jobSaving;
        });

        this.subs.sink = this.jobUpdating$.subscribe((jobUpdating) => {
            this.jobUpdating = jobUpdating;
        });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    isButtonDisabled(): boolean {

        const isHowHeardMissingWhenRequired = this.howHeardMandatory && !this.howHeardSelected;

        return this.jobSaving
        || !this.fullNameSelected
        || isHowHeardMissingWhenRequired
        || !this.formIsValid
        || !this.requiredFieldsProvided;
    }

    create() {
        this.store.dispatch(JobCreateActions.saveJobForm());
        this.buttonClicked.emit();
    }

    createClose() {
        this.buttonClicked.emit();
        return this.dialogService.open(CloseJobV2Component, {
            header: 'Create job and close it right away',
            width: '42rem',
            data: {
                newJob: true,
            }
        });
    }

    updateJob() {
        if (this.changes?.length) {
            this.store.dispatch(JobEditActions.updateFormSaved({ jobId: this.jobId }));
        } else {
            this.router.navigate([`/jobs/${this.jobId}/overview`]);
        }
    }
}
