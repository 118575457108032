import { Component, OnDestroy, OnInit, } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { SubscriptionResult } from 'apollo-angular';
import { Observable, Subscription } from 'rxjs';
import { SubSink } from 'subsink';

import { JobChangedGQL, JobChangedSubscription } from '../../generated/graphql.generated';
import { ColorSchemeService } from '../color-scheme.service';
import { getUniqueSessionID } from '../core/auth/session';
import { PlusAuthenticationService } from '../core/public-api';
import { FreyaCommonModule } from '../freya-common/freya-common.module';

import { JobActivityComponent } from './job-activity/job-activity.component';
import { JobHeaderComponent } from './job-header/job-header.component';
import { JobOverviewComponent } from './job-overview/job-overview.component';
import { JobToolActions } from './job-tool.actions';
import { jobToolFeature } from './job-tool.reducer';
import { KarveTabviewComponent } from "./karve-tabview/karve-tabview.component";
import { ChargesUpdate } from './jobv2-create/jobv2-interfaces';
import { cloneDeep } from 'lodash';
import { WorkOrdersActions } from './job-state/workorders-state/workorders.actions';
import { OnlineStatusService } from '../online-status.service';

@Component({
  selector: 'app-jobsv2',
  standalone: true,
  imports: [
    JobOverviewComponent,
    JobActivityComponent,
    FreyaCommonModule,
    JobHeaderComponent,
    KarveTabviewComponent
  ],
  templateUrl: './jobsv2.component.html',
  styleUrls: ['./jobsv2.component.scss', './colors.scss']
})
export class Jobsv2Component implements OnInit, OnDestroy {

  job$ = this.store.select(jobToolFeature.selectJob);
  tab$ = this.store.select(jobToolFeature.selectTab);
  jobLoading$ = this.store.select(jobToolFeature.isJobLoading);
  jobBeingUpdated$ = this.store.select(jobToolFeature.isJobBeingUpdated);

  jobLoading: boolean;
  isOnline: boolean;

  baseUrl: string;

  private tabs = [
    'overview',
    'work',
    'financials',
    'inventory',
    'files',
  ];
  public tabsWithSlug = [
    {
      name: 'Overview',
      slug: 'overview'
    },
    {
      name: 'Workorders',
      slug: 'work'
    },
    {
      name: 'Financials',
      slug: 'financials'
    },
    {
      name: 'Inventory',
      slug: 'inventory'
    },
    /*{
      name: 'Files',
      slug: 'files'
    }*/
  ];


  get defaultTab() {
    return this.tabs[0];
  }

  private subs = new SubSink();

  private jobChangedObservable: Observable<SubscriptionResult<JobChangedSubscription>>;
  private jobChangedSubscription: Subscription;
  private jobChangedSubscriptionID: string;

  chargesUpdates$ = this.store.select(jobToolFeature.selectChargesUpdates);
  chargesUpdates: ChargesUpdate[] = [];

  constructor(
    public store: Store,
    private route: ActivatedRoute,
    private auth: PlusAuthenticationService,
    private jobChangedGQL: JobChangedGQL,
    public colorScheme: ColorSchemeService,
    public onlineStatusService: OnlineStatusService,
  ) {
    this.isOnline = false;
   }

  ngOnInit(): void {

    this.onlineStatusService.isOnline$.subscribe(status => {
      this.isOnline = status;
    });

    this.subscribeToRoute();

    this.initWebsocketSubscription();

    this.subs.sink = this.chargesUpdates$.subscribe((chargesUpdates) => {
      this.chargesUpdates = cloneDeep(chargesUpdates);
  });
  }

  ngOnDestroy(): void {
    this.destroyWebsocketSubscription();
    this.subs.unsubscribe();
  }

  initWebsocketSubscription() {

    /**
     * I realize that we should probably be using an effect for this
     * but it's quite complicated and this seemed simpler
     */
    this.subs.sink = this.job$.subscribe((job) => {
      if (!job) { return; }
      if (job?.id === this.jobChangedSubscriptionID) {
        // job ID is already set
        return;
      }

      if (this.jobChangedSubscription) {
        this.destroyWebsocketSubscription();
      }

      this.jobChangedSubscriptionID = job.id;
      this.jobChangedObservable = this.jobChangedGQL.subscribe({ jobId: job.id });
      this.jobChangedSubscription = this.jobChangedObservable.subscribe({
        next: (value) => {
          const payload = value.data.job;

          const localAction = payload.sessionId === getUniqueSessionID();
          const actionLocation = localAction ? 'local' : 'remote';
          const action = `[Job Tool Subscription] ${actionLocation} ${payload.action}`;
          console.log(action, value);

          // eslint-disable-next-line @ngrx/prefer-action-creator-in-dispatch
          this.store.dispatch({
            type: action,
            ...payload,
          });
        },
        complete: () => {
          this.destroyWebsocketSubscription();
        },
      });

      this.subs.sink = this.jobChangedSubscription;
    });
  }

  destroyWebsocketSubscription() {
    if (this.jobChangedSubscription) {
      this.jobChangedSubscription.unsubscribe();
    }

    delete this.jobChangedObservable;
    delete this.jobChangedSubscription;
    delete this.jobChangedSubscriptionID;
  }

  subscribeToRoute() {
    this.subs.sink = this.route.params.subscribe((params) => {
      // TODO: Remove this as now we are using <router-outlet> to load the tabs
      let tabName = params.tab || this.defaultTab;
      let tabIndex = this.getIndexOfTab(tabName);
      if (!tabIndex) {
        tabName = this.defaultTab;
        tabIndex = 0;
      }

      this.baseUrl = `/jobs/${params.jobId}`;
      // TODO: Fix: This fires jobsV2 query to backend everytime we change tabs
      this.store.dispatch(JobToolActions.paramsSet({
        jobId: params.jobId,
        user: this.auth.user,
        tab: {
          name: tabName,
          index: tabIndex,
        },
      }));
    });
  }

  getIndexOfTab(name: string) {
    return this.tabs.indexOf(name);
  }
  onTabChange(index: number) {
    const name = this.tabs[index];

    this.store.dispatch(JobToolActions.tabChanged({
      name,
      index,
    }));
  }

  saveModifiedCharges() {
    this.store.dispatch(WorkOrdersActions.saveButtonClicked());
  }

  getSaveTooltipText(): string | null {
    if (!this.isOnline) {
      return 'You are offline. All changes made will be saved locally';
    }
  }
}
